<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :name="vid"
    :rules="rules"
    :vid="vid"
    class="column is-12"
  >
    <b-field
      :label="label"
      :message="errors"
      :type="{
        'is-danger': errors && errors.length > 0,
        'is-success': valid,
      }"
      class="file is-primary column is-12"
    >
      <template v-slot:label>
        <span v-if="required" class="required">{{ $t("ponctuation.star") }}</span>
        <span class="label">{{ label }}</span>
      </template>
      <b-taginput
        v-if="multiplicity === 'MANY'"
        v-model="textValue"
        required
        type="textarea"
        @blur="updateValue"
        @input="updateValue"
      />
      <b-input
        v-else
        v-model="textValue"
        required
        type="textarea"
        @blur="updateValue"
        @input="updateValue"
      />
    </b-field>
  </ValidationProvider>
</template>

<script>
import { extend, ValidationProvider } from "vee-validate";
import { computed, ref, watch } from "vue";

export default {
  name: "OreInputText",
  emits: ["update:value"],
  components: {
    ValidationProvider,
  },
  props: {
    checker: {
      type: Object,
      required: false,
    },
    value: {
      required: true,
    },
    label: {
      type: String,
    },
    vid: {
      type: String,
    },
  },
  computed: {
    required: {
      get() {
        return this.checker && this.checker.required;
      },
    },
    multiplicity: {
      get() {
        return this.checker && this.checker.multiplicity === "MANY";
      },
    },
    rules: {
      get() {
        let rules = [];
        if (this.checker) {
          if (this.checker.name === "BooleanType" && this.checker.expression) {
            if (this.checker.pattern) {
              this.extend("regexp", (value) => {
                return this.validateRegExp(value) || this.$t("rules.regexp", this.checker.params);
              });
              rules.push("regexp");
            }
          }
          if (this.checker.required) {
            this.extend("required", (value) => {
              return this.validateRequired(value) || this.$t("rules.required");
            });
            rules.push("required");
          }
        }
        return rules.join("|");
      },
    },
  },
  methods: {
    extend,
  },
  setup(props, ctx) {
    const val = ref(props.value);
    watch(
      () => props.value,
      (newValue) => {
        if (newValue) {
          val.value = ref(newValue);
        }
      },
      { immediate: true }
    );

    const textValue = computed({
      get: () => {
        return val.value;
      },
      set: (newValue) => {
        val.value = newValue;
        updateValue(newValue);
      },
    });

    function updateValue(event) {
      if (typeof event == "object" && !Array.isArray(event) && event.target) {
        event = event.target.value;
      }
      ctx.emit("update:value", event);
    }

    function regexp(value) {
      return new RegExp("^" + props.checker.pattern + "$", "g").test(value);
    }

    function validateRegExp(value) {
      if (typeof value == "string") {
        return regexp(value);
      } else {
        return value.map((v) => regexp(v)).filter((v) => v === false).length === 0;
      }
    }

    function validateRequired(value) {
      if (typeof value == "string") {
        return !!value;
      } else {
        return value?.length > 0;
      }
    }

    return {
      updateValue,
      regexp,
      validateRegExp,
      validateRequired,
      val,
      textValue,
    };
  },
};
</script>

<style scoped>
.required {
  color: rgb(255, 0, 0);
  padding-right: 5px;
  font-size: 150%;
}
</style>
