import { BinaryFileDataset } from "./BinaryFileDataset";

export class BinaryFileInfos {
  binaryFileDataset; // Correction de la casse ici
  published = false;
  publisheduser;
  publisheddate;
  createuser;
  createdate;
  datatype;
  comment;
  constructor(binaryFileInfos) {
    if (typeof binaryFileInfos == "object") {
      // Copie des propriétés standard
      this.published = binaryFileInfos.published ?? false;
      this.publisheduser = binaryFileInfos.publisheduser ?? null;
      this.publisheddate = binaryFileInfos.publisheddate ?? null;
      this.createuser = binaryFileInfos.createuser ?? null;
      this.createdate = binaryFileInfos.createdate ?? null;
      this.datatype = binaryFileInfos.datatype ?? null;
      this.comment = binaryFileInfos.comment ?? null;

      // Traitement spécial pour binaryFileDataset
      if (binaryFileInfos.binaryFileDataset) {
        this.binaryFileDataset = new BinaryFileDataset(binaryFileInfos.binaryFileDataset);
      } else {
        this.binaryFileDataset = new BinaryFileDataset();
      }
    }
  }
}
