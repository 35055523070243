import { render, staticRenderFns } from "./TitleAndDescription.vue?vue&type=template&id=534fa508&scoped=true"
import script from "./TitleAndDescription.vue?vue&type=script&lang=js"
export * from "./TitleAndDescription.vue?vue&type=script&lang=js"
import style0 from "./TitleAndDescription.vue?vue&type=style&index=0&id=534fa508&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "534fa508",
  null
  
)

export default component.exports