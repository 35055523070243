export class Dataset {
  id;
  periode;
  published = false;
  publishDate;
  publishUser;
  lastUploadDate;
  lastUploadUser;
  datasets = [];

  constructor(file) {
    this.id = file.id;
    this.lastUploadDate = file.params.createdate;
    this.lastUploadUser = file.params.createuser;
    this.published = file.params.published;
    this.publishDate = file.params.publisheddate;
    this.publishUser = file.params.publisheduser;
    this.periode = this.periodeToString(
      file.params.binaryFileDataset.from,
      file.params.binaryFileDataset.to
    );
  }

  addDataset(dataset) {
    this.published = dataset.params.published ? dataset.params.published : this.published;
    this.publishDate = dataset.params.published ? dataset.params.publisheddate : this.publishDate;
    this.publishUser = dataset.params.published ? dataset.params.publisheduser : this.publishUser;
    this.lastUploadDate =
      dataset.params.createdate > this.lastUploadDate
        ? dataset.params.createdate
        : this.lastUploadDate;
    this.lastUploadUser =
      dataset.params.createdate > this.lastUploadDate
        ? dataset.params.createuser
        : this.lastUploadUser;
    this.datasets.push(dataset);
  }

  dateToString(dateString) {
    let today = new Date(dateString);
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    const HH = today.getHours();
    const MM = today.getMinutes();

    today = dd + "/" + mm + "/" + yyyy + " " + HH + ":" + MM;
    return today;
  }

  periodeToString(from, to) {
    if (from && to) {
      return "du " + this.dateToString(from) + " au " + this.dateToString(to);
    }
    if (this?.params?.binaryFileDataset?.from && this?.params?.binaryFileDataset?.to) {
      return this.dateToString(
        this.params.binaryFileDataset.from,
        this.params.binaryFileDataset.to
      );
    }
    return;
  }

  get publication() {
    if (this.published) {
      return `publié le ${this.dateToString(this.publishDate)} par ${this.publishUser?.email}`;
    } else {
      return "";
    }
  }

  findByUUID(uuid) {
    return this.datasets.find((e) => e.id === uuid);
  }
}
