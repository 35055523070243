<template>
  <b-modal :active.sync="isModalActiveComputed" has-modal-card trap-focus :can-cancel="false">
    <div class="modal-body" style="min-height: 300px; max-height: 80vh; overflow-y: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ modalMessageComputed }}</p>
      </header>
      <section class="modal-card-body">
        <b-progress
          :value="uploadProgressComputed"
          :max="100"
          type="is-info"
          v-if="uploadProgressComputed > 0"
        ></b-progress>
      </section>
      <footer class="modal-card-foot">
        <b-button
          v-if="uploadProgressComputed > 0 && uploadProgressComputed < 100"
          @click="cancelUpload"
        >
          Annuler le chargement
        </b-button>
        <b-button v-if="uploadProgressComputed === 100" @click="continueNavigation">
          Continuer la navigation
        </b-button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import { computed } from "vue";

export default {
  name: "FileUploadModal",
  props: {
    isModalActive: {
      type: Boolean,
      required: true,
    },
    modalMessage: {
      type: String,
      required: true,
    },
    uploadProgress: {
      type: Number,
      required: true,
    },
  },
  emits: ["cancel-upload", "continue-navigation"],
  setup(props, { emit }) {
    const isModalActiveComputed = computed(() => props.isModalActive);
    const modalMessageComputed = computed(() => props.modalMessage);
    const uploadProgressComputed = computed(() => props.uploadProgress);

    const cancelUpload = () => {
      emit("cancel-upload");
    };

    const continueNavigation = () => {
      emit("continue-navigation");
    };

    return {
      isModalActiveComputed,
      modalMessageComputed,
      uploadProgressComputed,
      cancelUpload,
      continueNavigation,
    };
  },
};
</script>
