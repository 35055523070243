<template>
  <div>
    <!-- section pour visualisation un MANY-->
    <span v-if="multiplicity">
      <div v-for="val in infoValues" :key="val.id">
        <DatasLink
          v-if="referenceType"
          :application="application"
          :column-id="val.columnName"
          :display-value="
            displayValuesByReferenceAndValue[referenceType + '_' + val] && typeof val !== 'number'
              ? displayValuesByReferenceAndValue[referenceType + '_' + val]
              : val.toString()
          "
          :loaded-references-by-key="{}"
          :reference-type="referenceType"
          :value="val"
        ></DatasLink>
        <p v-else-if="typeof val === 'string' && val.indexOf('date') === 0">
          {{ datePatternLang(val) }}
        </p>
        <p v-else class="column">{{ val }}</p>
      </div>
    </span>
    <!-- modal de dynamique colonne -->
    <b-modal v-model="isCardModalActive" class="modalCardRef" width="70%">
      <div class="card">
        <div class="card-header">
          <div class="title card-header-title">
            <p field="name" style="font-size: 1.5rem">
              {{ columnId }}
            </p>
          </div>
        </div>
        <div class="card-content">
          <div v-for="key in modalArrayObj" :key="key.id" class="columns modalArrayObj">
            <DatasLink
              v-if="key.value"
              :application="application"
              :column-id="key.columnName"
              :loaded-references-by-key="{}"
              :reference-type="referenceType"
              :value="key.value"
            ></DatasLink>
            <DatasDynamicLink
              v-if="!key.value.length && key.value.length !== 0"
              :application="application"
              :column-id="key.value"
              :info="!key.value.length && key.value.length !== 0"
              :info-values="key.value"
              :loaded-references-by-key="{}"
              :reference-type="dynamicColumnReferences(key.value)"
            ></DatasDynamicLink>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import services from "@/composable/services";
import DatasLink from "@/components/datas/DatasLink.vue";
import DatasDynamicLink from "@/components/datas/DatasDynamicLink.vue";
import { datePatternLang } from "@/composable/application/datePattern";
import { computed, inject, onMounted, ref } from "vue";

export default {
  name: "DatasManyLink",
  emits: ["changedRefValues"],
  components: {
    DatasLink,
    DatasDynamicLink,
  },
  props: {
    application: Object,
    referenceType: String,
    value: String,
    infoValues: {},
    referenceValue: {},
    column: {},
    displaysForRow: {},
    loadedReferencesByKey: {
      type: Object,
    },
    multiplicity: Boolean,
    columnId: String,
  },
  beforeCreate() {
    this.$options.components.DatasDynamicLink = require("./DatasDynamicLink.vue").default;
    this.$options.components.DatasLink = require("./DatasLink.vue").default;
  },
  watch: {
    innerOptionChecked(value) {
      return this.$emit("optionChecked", value);
    },
  },
  setup(props) {
    let refValues = { active: false };
    let isCardModalActive = false;
    let modalArrayObj = {};
    const displayValuesByReferenceAndValue = ref({});
    const dataService = services.dataService;
    const { getValueDisplay, getOrLoadDataByNaturalKey } = inject("reference:dataLoader");

    onMounted(async () => {
      const newValues = {};
      for (let value of props.infoValues) {
        if (
          !Object.keys(displayValuesByReferenceAndValue).includes(props.referenceType + "_" + value)
        ) {
          let ref = await getOrLoadDataByNaturalKey(props.application, props.referenceType, value);
          newValues[props.referenceType + "_" + value] = getValueDisplay(
            ref.values,
            props.application
          );
        }
      }
      displayValuesByReferenceAndValue.value = {
        ...displayValuesByReferenceAndValue.value,
        ...newValues,
      };
    });

    const applicationName = computed(() => {
      return props.application.name;
    });
    const columnTitle = computed(() => {
      return services.internationalisationService.localeReferenceName(
        { label: props.referenceType },
        props.application
      );
    });

    async function getDataByalNaturKey(applicationName, referenceType, value) {
      return dataService.getDataByNaturalKey(applicationName, referenceType, value);
    }

    function dynamicColumnReferences(nameId) {
      if (
        props.application.configuration.dataDescription[props.referenceType].componentDescriptions[
          nameId
        ].type === "DynamicComponent"
      ) {
        return props.application.configuration.dataDescription[props.referenceType]
          .componentDescriptions[nameId].reference;
      }
    }

    async function openReferenceDetail() {
      isCardModalActive = false;
      let localRefValues =
        refValues &&
        props.loadedReferencesByKey[props.referenceType] &&
        props.loadedReferencesByKey[props.referenceType][props.value];
      if (!localRefValues) {
        const reference = await getDataByalNaturKey(
          applicationName,
          props.referenceType,
          props.value
        );
        let referenceTypeForReferencingColumns = reference.referenceTypeForReferencingColumns;
        let refValue = reference.referenceValues[0].values;
        if (referenceTypeForReferencingColumns.length) {
          refValues = {
            referenceTypeForReferencingColumns: referenceTypeForReferencingColumns,
            refValue: refValue,
          };
        } else {
          refValues = { refValue: refValue };
        }
      }
      const data = Object.entries(refValues.refValue)
        .filter((entry) => !entry[0].startsWith("__display_"))
        .map((entry) => ({ colonne: entry[0], valeur: entry[1] }))
        .reduce((acc, entry) => {
          acc.push(entry);
          return acc;
        }, []);
      const result = {
        data: data,
        columns: [
          {
            field: "colonne",
            label: "Colonne",
          },
          {
            field: "valeur",
            label: "Valeur",
          },
        ],
        active: true,
        reference: props.referenceType,
      };
      refValues = { ...localRefValues, ...result };
      this.$emit("changedRefValues", {
        key: props.value,
        refType: props.referenceType,
        localRefValues,
      });
      return localRefValues;
    }

    function showModal() {
      isCardModalActive = true;
      modalArrayObj = Object.entries(this.infoValues)
        .filter((a) => a[1])
        .map(function (a) {
          let obj = {};
          obj[a[0]] = a[1];
          return obj;
        });
      for (let j = 0; j < modalArrayObj.length; j++) {
        if (modalArrayObj[j]) {
          let column = Object.keys(modalArrayObj[j])[0];
          let value = Object.values(modalArrayObj[j])[0];
          modalArrayObj[j] = {
            ...modalArrayObj[j],
            column: column,
            value: value,
          };
        }
      }
    }

    return {
      datePatternLang,
      dynamicColumnReferences,
      openReferenceDetail,
      displayValuesByReferenceAndValue,
      showModal,
      refValues,
      dataService,
      isCardModalActive,
      modalArrayObj,
      applicationName,
      columnTitle,
      getValueDisplay,
    };
  },
};
</script>
