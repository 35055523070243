<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <section>
      <ShowErrors :errors-messages="errorsMessages" :errors-messages-length="errorsMessages.length">
      </ShowErrors>
      <TextField
        v-model:value="login"
        :mandatory="true"
        label="login.login"
        name="login.login"
        place-holder="login.login-placeholder"
        rules="min:4"
        @update:value="login = $event"
      />
      <TextField
        v-model:value="password"
        :mandatory="true"
        label="login.pwd"
        name="password"
        place-holder="login.pwd-placeholder"
        type="password"
        @update:value="password = $event"
      />
      <ShowErrors
        :password="password"
        :warning-messages="warningMessages"
        :warning-messages-length="warningMessages.length"
      ></ShowErrors>
      <TextField
        v-if="verificationKeyVisible"
        v-model:value="verificationKey"
        :mandatory="true"
        label="login.verificationKey"
        name="verificationKey"
        place-holder="login.verificationKey-placeholder"
        rules="length:10"
        @update:value="
          verificationKey = $event;
          activateLogin();
        "
      />
    </section>

    <div class="buttons">
      <b-button
        :aria-label="$t('login.aria-btn-login')"
        aria-description="login.signIn"
        icon-left="sign-in-alt"
        type="is-primary"
        @click="handleSubmit(signIn)"
      >
        {{ $t("login.signin") }}
      </b-button>
      <b-button
        :label="$t('login.pwd-forgotten')"
        aria-description="login.pwd-forgotten"
        outlined
        type="is-warning"
        @click="isComponentModalActive = true"
      />

      <b-modal
        v-model="isComponentModalActive"
        :destroy-on-hide="false"
        aria-label="Example Modal"
        aria-role="dialog"
        close-button-aria-label="Close"
        has-modal-card
        trap-focus
      >
        <template>
          <PasswordLostModal :sign-in="signIn" />
        </template>
      </b-modal>
    </div>
  </ValidationObserver>
</template>

<script>
import services from "@/composable/services";
import { HttpStatusCodes } from "@/utils/HttpUtils";
import { inject, provide } from "vue";
import useBoolean from "@/composable/components/boolean";
import useText from "@/composable/components/text";
import useArray from "@/composable/components/array";
import { ValidationObserver } from "vee-validate";
import TextField from "@/components/forms/TextField.vue";
import PasswordLostModal from "@/components/login/PasswordLostModal.vue";
import { i18n } from "@/main";
import ShowErrors from "@/components/application/ShowErrors.vue";

export default {
  name: "SigIn",
  components: { ShowErrors, PasswordLostModal, TextField, ValidationObserver },
  setup() {
    const { refBoolean: verificationKeyVisible } = useBoolean();
    const { refBoolean: isComponentModalActive } = useBoolean();
    const applications = inject("application:applications");
    const applicationName =
      window.location.href.split("/")[window.location.href.split("/").length - 1];

    const login = inject("login");
    const verificationKey = useText().refText;
    const email = inject("email");
    const password = useText().refText;
    const errorsMessages = useArray().shallowRefArray;
    const warningMessages = useArray().shallowRefArray;
    provide("password", password);
    provide("verificationKey", verificationKey);
    const paymentRequired = function (m) {
      warningMessages.value.push(
        services.errorsService.getErrorsLoginMessages(m.message, m.params)
      );
    };

    const signIn = async function () {
      try {
        if (applicationName !== "login") {
          await services.loginService.signIn(login.value, password.value, applicationName);
        } else {
          await services.loginService.signIn(login.value, password.value);
        }
      } catch (errors) {
        if (errors.httpResponseCode === HttpStatusCodes.PAYMENT_REQUIRED) {
          errors.content.then(paymentRequired);
          verificationKeyVisible.value = true;
        } else if (errors.httpResponseCode === HttpStatusCodes.UNAUTHORIZED) {
          services.alertService.toastError(i18n.t("alert.user-unknown"), errors);
        } else if (errors.httpResponseCode === HttpStatusCodes.FORBIDDEN) {
          errors.content.then((m) => {
            services.alertService.toastError(i18n.t("alert.user-unknown"), {
              content: new Promise(() => m),
            });
          });
        } else {
          services.alertService.toastServerError(errors);
        }
      }
    };

    const activateLogin = async function () {
      if (verificationKey.value.length === 10) {
        try {
          await services.loginService.modifAcount({
            login: login.value,
            password: password.value,
            verificationKey: verificationKey.value,
          });
          services.alertService.toastSuccess(i18n.t("alert.user-updated", email.value));
          await signIn();
        } catch (error) {
          services.alertService.toastError(i18n.t("alert.user-unknown"), {});
        }
      }
    };

    function updateForm(event) {
      if (event.login) {
        login.value = event.login;
      }
      if (event.email) {
        email.value = event.email;
      }
      if (event.verificationKey) {
        verificationKey.value = event.verificationKey;
      }
      if (event.password) {
        password.value = event.password;
      }
    }

    return {
      //from '@/composition/components/boolean'
      verificationKeyVisible,
      isComponentModalActive,

      login,
      password,
      email,
      verificationKey,

      errorsMessages,
      warningMessages,

      activateLogin,
      signIn,
      paymentRequired,
      updateForm,
      applications,
      applicationName,
    };
  },
};
</script>
<style lang="scss">
.icon.is-right.has-text-primary.is-clickable {
  color: $primary !important;
  background-color: transparent !important;
}

li {
  list-style-type: none;
}

.step-link {
  background-color: white;
}
</style>
