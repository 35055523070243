<template>
  <PageView class="with-submenu">
    <SubMenu
      :aria-label="$t('menu.aria-sub-menu')"
      :paths="subMenuPaths"
      :root="application?.localName || application?.title"
      role="navigation"
    />
    <TitleAndDescription
      :application="application"
      :localDescription="currentAuthorization.description"
      :localName="application.localName || application.title"
      :local-title="
        $t('dataTypeAuthorizations.title', {
          label: currentUser[0] ? currentUser[0].label : currentUser.login,
        })
      "
    />
    <LoadingAnimate v-if="isLoading" :size="'is-medium'"></LoadingAnimate>
    <ValidationObserver v-else ref="observer" v-slot="{ handleSubmit }">
      <FieldsForm
        :application="application"
        :comment="comment"
        :description="description"
        :fields="fields"
        :format="format"
        :showComment="Object.keys(format).length === 0"
        pathForKey="rightsrequest.fields"
        @update:fields="updateFields"
        @update:comment="updateComment"
      >
      </FieldsForm>
      <b-collapse
        :open="false"
        animation="slide"
        aria-id="tableCurrentAuth"
        class="card"
        style="box-shadow: none"
      >
        <template #trigger="detail">
          <div
            :aria-expanded="!detail.open"
            aria-controls="tableCurrentAuth"
            class="card-header"
            style="box-shadow: none; border-bottom: thick double #009d9d"
          >
            <p class="card-header-title">Demande des droits par références ou datatypes</p>
            <a class="card-header-icon">
              <b-icon
                :icon="detail.open ? 'chevron-up' : 'chevron-down'"
                type="is-primary"
              ></b-icon>
            </a>
          </div>
        </template>
        <div class="card-content">
          <div>
            <AuthorizationTableForDatatype
              :application="application"
              :application-name="applicationName"
              :authorization-id="authorizationId"
              :authorizations="authorizations"
              :current-authorization="currentAuthorization"
              :datatypes="datatypes"
              :has-dependencies="hasDependencies"
              :initialized="initialized"
              :list-column-name="listColumnName"
              :references="references"
              :references-scopes="referencesScopes"
              @update:authorization="updateAuthorization"
            >
            </AuthorizationTableForDatatype>
          </div>
        </div>
      </b-collapse>
      <div class="buttons">
        <b-button
          v-if="canCreateApplication"
          icon-left="plus"
          style="margin-bottom: 10px"
          type="is-dark"
          @click="handleSubmit(confirmGrantAuthorization)"
        >
          {{ $t("dataTypeAuthorizations.grantRequests") }}
        </b-button>
        <b-button
          v-else-if="'new' === authorizationId"
          icon-left="plus"
          style="margin-bottom: 10px"
          type="is-dark"
          @click="handleSubmit(createRequest)"
        >
          {{ $t("dataTypeAuthorizations.showRequests") }}
        </b-button>
        <b-button
          v-else
          icon-left="plus"
          style="margin-bottom: 10px"
          type="is-dark"
          @click="handleSubmit(createRequest)"
        >
          {{ $t("dataTypeAuthorizations.modifyRequests") }}
        </b-button>
      </div>
    </ValidationObserver>
  </PageView>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import SubMenu, { SubMenuPath } from "@/components/common/SubMenu.vue";
import PageView from "../common/PageView.vue";
import { ApplicationResult } from "@/model/ApplicationResult";
import AuthorizationTableForDatatype from "@/components/common/AuthorizationTableForDatatype.vue";
import FieldsForm from "@/components/common/provider/FieldsForm.vue";
import LoadingAnimate from "@/components/common/LoadingAnimate.vue";
import services from "@/composable/services";
import { computed, inject, onMounted, ref, watch } from "vue";
import useObject from "@/composable/components/object";
import app, { i18n } from "@/main";
import useArray from "@/composable/components/array";
import { getListColumnName } from "@/composable/authorization/grantableInfos";
import { User } from "@/model/User";
import TitleAndDescription from "@/components/common/TitleAndDescription.vue";

export default {
  name: "AuthorizationsRightsRequestInfoView",
  components: {
    TitleAndDescription,
    LoadingAnimate,
    AuthorizationTableForDatatype,
    PageView,
    SubMenu,
    ValidationObserver,
    FieldsForm,
  },
  props: {
    applicationName: {
      type: String,
    },
    authorizationId: {
      type: String,
      default: "new",
    },
  },
  setup(props) {
    const loadApplications = inject("application:loadApplications");
    let { reactiveObject: application, doChangeObject: changeApplication } = useObject(
      new ApplicationResult()
    );
    const { shallowRefArray: subMenuPaths, doChangeArray: changeSubMenuPaths } = useArray();
    const isLoading = ref(false);
    const canCreateApplication = false;
    const datatypes = ref({ withScope: {}, withoutScope: {} });
    const references = ref({ withScope: {}, withoutScope: {} });
    const { reactiveObject: datas } = useObject({});
    const { reactiveObject: configuration, doChangeObject: changeConfiguration } = useObject({});
    const { reactiveObject: authorization, doChangeObject: changeAuthorization } = useObject({});
    const { reactiveObject: format, doChangeObject: changeFormat } = useObject({});
    const { reactiveObject: fields, doChangeObject: changeFields } = useObject({});
    const { reactiveObject: authorizations } = useObject({});
    const currentAuthorization = ref({});
    const listColumnName = ref({});
    const initialized = ref(false);
    const referencesScopes = ref({});
    let valid = false;
    const { reactiveObject: period } = useObject();
    let startDate = ref(null);
    let endDate = ref(null);
    const description = ref("");
    const comment = ref("");
    const dependencies = ref({});
    const currentUser = ref({});
    const hasDependencies = computed(() => {
      const result = new Set();
      const authorizations = currentAuthorization.value.authorizations;
      const hierarchicalNodes = configuration.hierarchicalNodes;

      for (const [datatype, auth] of Object.entries(authorizations || {})) {
        if (auth.operationTypes && auth.operationTypes.length > 0) {
          // Trouver le nœud correspondant dans la configuration
          const node = hierarchicalNodes.find((n) => n.nodeName === datatype);
          if (node && node.depends && node.depends.length > 0) {
            // Ajouter toutes les dépendances du datatype
            node.depends.forEach((dep) => result.add(dep));
          }
        }
      }

      return Array.from(result);
    });
    const buildDependencies = (hierarchicalNodes) => {
      const dependenciesObject = {};

      // Initialiser l'objet avec tous les datatypes
      hierarchicalNodes.forEach((node) => {
        dependenciesObject[node.nodeName] = {
          dependencies: [],
          dependents: [],
        };
      });

      // Remplir les dépendances et les dépendants
      hierarchicalNodes.forEach((node) => {
        // Ajouter les dépendances
        if (node.depends && node.depends.length > 0) {
          dependenciesObject[node.nodeName].dependencies = node.depends;

          // Ajouter ce datatype comme dépendant pour chacune de ses dépendances
          node.depends.forEach((dep) => {
            if (dependenciesObject[dep]) {
              dependenciesObject[dep].dependents.push(node.nodeName);
            }
          });
        }

        // Gérer la relation parent-enfant
        if (node.parent) {
          dependenciesObject[node.nodeName].dependencies.push(node.parent);
          if (dependenciesObject[node.parent]) {
            dependenciesObject[node.parent].dependents.push(node.nodeName);
          }
        }

        // Gérer les enfants
        if (node.children && node.children.length > 0) {
          node.children.forEach((childName) => {
            if (dependenciesObject[childName]) {
              dependenciesObject[childName].dependencies.push(node.nodeName);
              dependenciesObject[node.nodeName].dependents.push(childName);
            }
          });
        }
      });

      return dependenciesObject;
    };

    watch(period, () => {
      endDate.value = null;
      startDate.value = null;
    });

    watch(currentAuthorization, () => {
      changeAuthorization(currentAuthorization.value);
    });

    onMounted(async () => {
      await init();
      if (canCreateApplication) {
        changeSubMenuPaths([
          new SubMenuPath(
            i18n.t("menu.accueil").toLowerCase(),
            () => app.$router.push(`/applications/${props.applicationName}`),
            () => app.$router.push(`/applications`)
          ),
          new SubMenuPath(
            i18n.t(`dataTypeAuthorizations.sub-menu-request-authorization`),
            () => {
              app.$router.push(`/applications/${props.applicationName}/authorizationsRequest`);
            },
            () => app.$router.push(`/applications/${props.applicationName}`)
          ),
          new SubMenuPath(
            i18n.t(`dataTypeAuthorizations.sub-menu-new-authorization`),
            () => {},
            () => {
              app.$router.push(`/applications/${props.applicationName}/authorizationsRequest`);
            }
          ),
        ]);
      } else {
        changeSubMenuPaths([
          new SubMenuPath(
            i18n.t("menu.accueil").toLowerCase(),
            () => app.$router.push(`/applications/${props.applicationName}`),
            () => app.$router.push(`/applications/${props.applicationName}`)
          ),
          new SubMenuPath(
            i18n.t(`dataTypeAuthorizations.sub-menu-new-authorization`),
            () => {},
            () => {
              app.$router.push(`/applications/${props.applicationName}`);
            }
          ),
        ]);
      }
      datatypes.value = {
        withScope: Object.keys(application.dataTypes)
          .filter((name) => application.configuration.dataDescription[name].authorization)
          .reduce((acc, dataType) => {
            acc[dataType] = {
              id: dataType,
              name:
                services.internationalisationService.localeReferenceNames(dataType, application) ||
                dataType,
            };
            return acc;
          }, {}),
        withoutScope: Object.keys(application.dataTypes)
          .filter((name) => application.configuration.dataDescription[name].authorization === null)
          .reduce((acc, dataType) => {
            acc[dataType] = {
              id: dataType,
              name:
                services.internationalisationService.localeReferenceNames(dataType, application) ||
                dataType,
            };
            return acc;
          }, {}),
      };
      isLoading.value = false;
    });

    function initApplication(getApplication) {
      changeApplication(
        services.internationalisationService.mergeInternationalization(getApplication)
      );
      changeConfiguration(application.configuration);
      datas.value = Object.keys(application.data).reduce((acc, data) => {
        acc[data] = {
          id: data,
          name:
            services.internationalisationService.localeReferenceNames(data, application) || data,
        };
        return acc;
      }, {});
      for (let data of Object.keys(configuration.dataDescription)) {
        if (
          configuration.dataDescription[data] &&
          configuration.dataDescription[data].authorization
        ) {
          authorizations[data] = configuration.dataDescription[data].authorization;
        }
      }
    }

    async function init() {
      isLoading.value = true;
      try {
        let getApplication = await services.applicationService.getApplication(
          props.applicationName,
          ["DATATYPE", "REFERENCETYPE", "CONFIGURATION", "ADDITIONALFILE"]
        );
        initApplication(getApplication);
        dependencies.value = buildDependencies(configuration.hierarchicalNodes);
        changeFormat(configuration.rightsRequest.formFields || {});
        changeFields(
          (Object.keys(format) || []).reduce((acc, field) => {
            acc[field] = "";
            return acc;
          }, {})
        );
        description.value =
          application?.internationalization.rightsrequest?.fields[
            services.userPreferencesService.getUserPrefLocale()
          ] ||
          i18n.t("dataTypeAuthorizations.field_form_description", {
            applicationName: application?.localName,
          });
        let listAllUsers = await services.authorizationService.getAuthorizationGrantableInfos(
          props.applicationName
        );
        let scopes = listAllUsers.referenceScopes;
        referencesScopes.value = Object.keys(scopes || {}).reduce((acc, dataName) => {
          if (scopes[dataName].length) {
            acc[dataName] = scopes[dataName];
          }
          return acc;
        }, {});
        if (props.authorizationId !== "new") {
          valid = true;
          let request = await services.requestRightsService.getRightsRequests(
            props.applicationName,
            {
              uuids: [props.authorizationId],
            }
          );
          currentUser.value = [
            request.users.find(
              (user) =>
                user?.id ===
                ((request &&
                  request.rightsRequests &&
                  request.rightsRequests[0] &&
                  request.rightsRequests[0].user) ||
                  User.STORED_AUTHENTICATED_USER()?.id)
            ),
          ] || [User.STORED_AUTHENTICATED_USER()];
          let rightsRequest = request.rightsRequests[0];
          comment.value = rightsRequest.comment;
          changeFields(
            (Object.keys(format) || []).reduce((acc, field) => {
              acc[field] = rightsRequest.rightsRequestForm[field];
              return acc;
            }, {})
          );
          if (rightsRequest && Object.keys(rightsRequest.rightsRequest).length !== 0) {
            let authorizations = rightsRequest.rightsRequest;
            currentAuthorization.value = {
              description: description.value,
              name: i18n.t("dataTypeAuthorizations.title", {
                label: User.STORED_AUTHENTICATED_USER()?.login,
              }),
              uuid: null,
              authorizations: {},
            };
            // Copier les autorisations existantes dans l'objet currentAuthorization
            Object.keys(authorizations).forEach((key) => {
              currentAuthorization.value.authorizations[key] = authorizations[key][0];
            });
            changeAuthorization(currentAuthorization.value);
          }
        } else {
          currentUser.value = User.STORED_AUTHENTICATED_USER();
          currentAuthorization.value = {};
        }
        initialized.value = true;
        listColumnName.value = getListColumnName();
        delete listColumnName.value["withoutScope"].delete;
        delete listColumnName.value["withoutScope"].depot;
        delete listColumnName.value["withScope"].delete;
        delete listColumnName.value["withScope"].depot;
        delete listColumnName.value["withScope"].publication;
      } catch (error) {
        services.alertService.toastServerError(error);
      }
    }

    function updateFields(event) {
      let count = 0;
      for (let formatKey in format) {
        if (formatKey === event.key && event.fields) {
          fields[event.key] = event.fields;
        }
        if (fields[formatKey] !== "") {
          count++;
        }
      }
      valid = count === Object.keys(format).length;
    }

    function updateComment(event) {
      comment.value = event.comment;
      valid = event.valid;
    }

    async function grantAuthorization() {
      try {
        await createAuthorization();
      } catch (e) {
        console.log("error", e);
      } finally {
        await createRequest(true);
      }
    }

    function updateAuthorization(event) {
      let current = { ...currentAuthorization.value };
      current.authorizations = event;
      currentAuthorization.value = current;
    }

    const buildAuthorization = () => {
      const authorizationForAll = {};
      const authorizationsWithRestriction = {};
      const authorization = { ...currentAuthorization.value.authorizations };
      for (const [datatype, auth] of Object.entries(authorization)) {
        // Ignorer les datatypes sans opérationTypes ou avec seulement 'extraction' s'ils sont dans hasDependencies
        if (
          !auth.operationTypes ||
          (JSON.stringify(auth.operationTypes) === '["extraction"]' &&
            hasDependencies?.value &&
            hasDependencies.value.includes(datatype))
        ) {
          continue;
        } else {
          auth.operationTypes = [...auth.operationTypes];
        }
        if (!auth.timescope && !auth.requiredAuthorizations) {
          authorizationForAll[datatype] = auth.operationTypes;
          continue;
        }

        // Vérifier si le timeScope est infini
        const isInfiniteTimeScope =
          auth.fromDay !== undefined &&
          auth.fromDay[0] === -999999999 &&
          auth.toDay !== undefined &&
          auth.toDay[0] === 999999999;

        // Vérifier si requiredAuthorizations est vide ou égal à [""]
        const hasNoRequiredAuths =
          !auth.requiredAuthorizations ||
          Object.values(auth.requiredAuthorizations).every(
            (arr) => arr.length === 0 || (arr.length === 1 && arr[0] === "")
          );

        if (isInfiniteTimeScope && hasNoRequiredAuths) {
          if (!auth.requiredAuthorizations) {
            authorizationForAll[datatype] = new Set(auth.operationTypes);
          }
        } else {
          authorizationsWithRestriction[datatype] = {
            operationTypes: auth.operationTypes,
            requiredAuthorizations: auth.requiredAuthorizations,
            timeScope: {},
          };

          // Ajouter fromDay seulement s'il n'est pas infini
          if (auth.fromDay && auth?.fromDay[0] !== -999999999) {
            authorizationsWithRestriction[datatype].timeScope.fromDay = `${
              auth.fromDay[0]
            }-${auth.fromDay[1].toString().padStart(2, "0")}-${auth.fromDay[2]
              .toString()
              .padStart(2, "0")}`;
          }

          // Ajouter toDay seulement s'il n'est pas infini
          if (auth.toDay && auth?.toDay?.[0] !== 999999999) {
            authorizationsWithRestriction[datatype].timeScope.toDay = `${
              auth.toDay[0]
            }-${auth.toDay[1].toString().padStart(2, "0")}-${auth.toDay[2]
              .toString()
              .padStart(2, "0")}`;
          }

          // Si timeScope est vide, le supprimer complètement
          if (Object.keys(authorizationsWithRestriction[datatype].timeScope).length === 0) {
            delete authorizationsWithRestriction[datatype].timeScope;
          }
        }
      }
      let usersId;
      let authenticatedUserId = User.STORED_AUTHENTICATED_USER()?.id;
      if (
        (currentUser.value?.id && currentUser.value?.id !== authenticatedUserId) ||
        (currentUser.value[0] && currentUser.value[0]?.id !== authenticatedUserId)
      ) {
        usersId = currentUser.value?.id
          ? [currentUser.value?.id, authenticatedUserId]
          : [currentUser.value[0]?.id, authenticatedUserId];
      } else {
        usersId = [currentUser.value?.id ? currentUser.value?.id : currentUser.value[0]?.id];
      }
      return {
        uuid: props.authorizationId === "new" ? null : props.authorizationId,
        name: i18n.t("dataTypeAuthorizations.title", {
          label: currentUser.value[0] ? currentUser.value[0].label : currentUser.label,
        }),
        description: description.value ? description.value : "null",
        usersId: usersId,
        authorizationForAll,
        authorizationsWithRestriction,
      };
    };

    async function createRequest(isSetted) {
      if (!valid) {
        return;
      }
      try {
        let authorizationToSend = buildAuthorization();
        if (!(comment.value && comment.value.length)) {
          await new Promise((resolve) => {
            app.$buefy.dialog.prompt({
              message: i18n.t("dataTypeAuthorizations.addComment"),
              inputAttrs: {
                placeholder: i18n.t("dataTypeAuthorizations.commentExample"),
                maxlength: 255,
                minLength: 3,
                canCancel: false,
                confirmText: i18n.t("dataTypeAuthorizations.grantRequestConfirm"),
              },
              trapFocus: true,
              onConfirm: (value) => {
                comment.value = value;
                resolve();
              },
              onCancel: () => {
                resolve();
              },
            });
          });
        }
        await services.requestRightsService.createRequestRights(props.applicationName, {
          id: props.authorizationId === "new" ? null : props.authorizationId,
          fields: fields,
          rightsRequest: authorizationToSend,
          setted: isSetted,
          comment: comment.value,
        });
        if ("new" === props.authorizationId) {
          services.alertService.toastSuccess(i18n.t("alert.create-request"));
          await app.$router.push(`/applications/${props.applicationName}`);
        } else if (isSetted) {
          services.alertService.toastSuccess(i18n.t("alert.valid-request"));
          await app.$router.push(`/applications/${props.applicationName}/authorizations`);
        } else {
          services.alertService.toastSuccess(i18n.t("alert.modified-request"));
          await app.$router.push(`/applications/${props.applicationName}`);
        }
      } catch (error) {
        services.alertService.toastServerError(error);
      }
    }

    async function createAuthorization() {
      try {
        let authorizationForAll = {};
        let authorizationsWithRestriction = {};
        for (let datatype in authorization.authorizations) {
          if (
            authorization.authorizations[datatype].requiredAuthorizations &&
            Object.keys(authorization.authorizations[datatype].requiredAuthorizations).length !== 0
          ) {
            authorizationsWithRestriction[datatype] = {
              operationTypes: authorization.authorizations[datatype].operationTypes,
              requiredAuthorizations: authorization.authorizations[datatype].requiredAuthorizations,
              timeScope: {
                fromDay: authorization.authorizations[datatype].fromDay,
                toDay: authorization.authorizations[datatype].toDay,
              },
            };
          } else {
            authorizationForAll[datatype] = Object.values(
              authorization.authorizations[datatype].operationTypes
            );
          }
        }
        let authorizationToSend = {
          uuid: null,
          name: `request ${props.authorizationId} for user ${
            currentUser.value.label ? currentUser.value.label : currentUser.value[0].label
          }`,
          description: description.value,
          usersId: [currentUser.value[0]?.id, User.STORED_AUTHENTICATED_USER()?.id],
          authorizationForAll,
          authorizationsWithRestriction,
        };
        await services.authorizationService.createAuthorization(
          props.applicationName,
          authorizationToSend
        );
      } catch (error) {
        services.alertService.toastServerError(error);
      } finally {
        services.alertService.toastSuccess(i18n.t("alert.create-authorization"));
      }
    }

    function confirmGrantAuthorization() {
      app.$buefy.dialog.prompt({
        title: i18n.t("dataTypeAuthorizations.confirmGrantRequestsTitle"),
        message: i18n.t("dataTypeAuthorizations.confirmGrantRequests", currentUser.value[0]),
        inputAttrs: {
          placeholder: i18n.t("dataTypeAuthorizations.commentExample"),
          maxlength: 255,
          minLength: 3,
        },
        inputValue: description.value,
        confirmText: i18n.t("dataTypeAuthorizations.grantRequestConfirm"),
        cancelText: i18n.t("dataTypeAuthorizations.grantRequestDismiss"),
        trapFocus: true,
        onConfirm: (value) => {
          if (value && value.trim().length > 0) {
            description.value = value;
            grantAuthorization();
          } else {
            app.$buefy.toast.open({
              message: i18n.t("dataTypeAuthorizations.grantRequestConfirm"),
              type: "is-danger",
            });
          }
        },
      });
      currentAuthorization.value.description = description.value;
    }

    return {
      isLoading,
      subMenuPaths,
      loadApplications,
      description,
      comment,
      canCreateApplication,
      datatypes,
      application,
      currentUser,
      fields,
      format,
      references,
      authorization,
      referencesScopes,
      initialized,
      hasDependencies,
      authorizations,
      listColumnName,
      currentAuthorization,
      updateFields,
      updateComment,
      confirmGrantAuthorization,
      createRequest,
      updateAuthorization,
    };
  },
};
</script>

<style lang="scss">
.DataTypeAuthorizationInfoView-periods-container {
  .field-body .field.has-addons {
    display: flex;
    flex-direction: column;
  }
}

.DataTypeAuthorizationInfoView-radio-field {
  height: 40px;

  &.b-radio {
    .control-label {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
}

.DataTypeAuthorizationInfoView-radio-label {
  width: 200px;
}

.collapse-content .card-content .content .CollapsibleTree-header .CollapsibleTree-buttons {
  visibility: hidden;
  display: none;
}

.leaf label {
  font-weight: lighter;
  font-style: italic;
  color: #2c3e50;
}

.folder label {
  font-weight: bolder;
  color: $dark;
}

.rows .card-content .row.label .columns .column {
  padding: 0 0 0 10px;
  border-bottom: 2px solid;
  border-color: $dark;
  margin-bottom: 12px;
}

ul li.card-content {
  background-color: rgba(0, 0, 0, 0.05);
}

a {
  color: $dark;
}
</style>
