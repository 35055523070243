<script>
export default {
  name: "CharteValidator",
  props: {
    href: {
      type: String,
    },
  },
};
</script>

<template>
  <object :data="href" type="application/pdf" height="600px" width="100%" form="validateForm">
    <param name="”view”" value="”600px”" />
  </object>
</template>
