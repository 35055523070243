import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "@/views/LoginView.vue";
import HelpView from "@/views/documentation/HelpView.vue";
import ApplicationsView from "@/views/application/ApplicationsView.vue";
import ApplicationCreationView from "@/views/application/ApplicationCreationView.vue";

import { ToastProgrammatic as Toast } from "buefy";
import { i18n } from "@/main";
/*import AuthorizationAdditionalFilesManagement from "@/views/authorizations/AuthorizationAdditionalFilesManagement.vue";*/
import AdditionalFilesManagementView from "@/views/additionalfiles/AdditionalFilesManagementView.vue";
import AdditionalFileInfosView from "@/views/additionalfiles/AdditionalFileInfosView.vue";
import AdditionalFilesAuthorizationInfoView from "@/views/authorizations/AdditionalFilesAuthorizationInfoView.vue";
import DataTypeAuthorizationsView from "@/views/authorizations/DataTypeAuthorizationsView.vue";
import DataTypeAuthorizationInfoView from "@/views/authorizations/DataTypeAuthorizationInfoView.vue";
import AuthorizationsManagementForApplicationCreatorView from "@/views/authorizations/AuthorizationsManagementForApplicationCreatorView.vue";
import AuthorizationsManagementForApplicationUsersView from "@/views/authorizations/AuthorizationsManagementForApplicationUsersView.vue";
import AuthorizationsRightsRequestInfoView from "@/views/authorizations/AuthorizationsRightsRequestInfoView.vue";
import RequestAuthorizationManagementView from "@/views/authorizations/RequestAuthorizationManagementView.vue";
import DatasManagementView from "@/views/data/DatasManagementView.vue";
import DataTableView from "@/views/data/DataTableView.vue";
import DataVersioningView from "@/views/data/DataVersioningView.vue";
import UserView from "@/views/users/UserView.vue";
import ApplicationInfoView from "@/views/application/ApplicationInfoView.vue";
import sessionService from "@/services/SessionService";

Vue.use(VueRouter);
let sessionHandlerTimeout = null;

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/help",
    name: "help",
    component: HelpView,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    beforeEnter: (to, from, next) => {
      if (!sessionHandlerTimeout && from.path !== "/" && from.path !== "/login") {
        sessionService.handleExpiredSession();
        console.log("sessionHandlerTimeout", sessionHandlerTimeout);
        Toast.open({
          message: i18n.t("login.deconnected"),
          type: "is-success",
        });

        sessionHandlerTimeout = setTimeout(() => {
          sessionHandlerTimeout = null;
        }, 50);
      }
      next();
    },
  },
  {
    path: "/login/:applicationName",
    name: "Login",
    props: true,
    component: LoginView,
  },
  {
    path: "/users/:userIdOrLogin",
    name: "User edit",
    component: UserView,
    props: true,
  },
  {
    path: "/applications",
    name: "Applications",
    component: ApplicationsView,
  },
  {
    path: "/applications/:applicationName",
    name: "ApplicationInfoView",
    component: ApplicationInfoView,
    props: true,
  },
  {
    path: "/applicationCreation",
    name: "Application creation",
    component: ApplicationCreationView,
    props: (route) => ({ applicationNames: route.query.applicationNames }),
  },
  {
    path: "/authorizationsManagementForApplicationCreator",
    name: "Authorizations management view",
    component: AuthorizationsManagementForApplicationCreatorView,
  },
  {
    path: "/applications/:applicationName/authorizationsManagementForApplicationUsers",
    name: "Authorizations users management view",
    component: AuthorizationsManagementForApplicationUsersView,
    props: true,
  },
  {
    path: "/applicationCreation/:applicationName/:applicationVersion",
    name: "Application update",
    component: ApplicationCreationView,
    props: true,
  },
  {
    path: "/applications/:applicationName/references",
    component: DatasManagementView,
    props: true,
  },
  {
    path: "/applications/:applicationName/dataTypes",
    component: DatasManagementView,
    props: true,
  },
  {
    path: "/applications/:applicationName/dataVersioning/:dataId",
    component: DataVersioningView,
    props: true,
  },
  {
    path: "/applications/:applicationName/references/:dataId",
    component: DataTableView,
    props: true,
  },
  {
    path: "/applications/:applicationName/dataTypes/:dataId",
    component: DataTableView,
    props: true,
  },
  {
    path: "/applications/:applicationName/authorizations",
    component: DataTypeAuthorizationsView,
    props: true,
  },
  {
    path: "/applications/:applicationName/additionalFiles/:additionalFileName",
    name: "Additional files management by Additional file name view",
    component: AdditionalFilesManagementView,
    props: true,
  },
  {
    path: "/applications/:applicationName/additionalFiles",
    name: "Additional files management view",
    component: AdditionalFilesManagementView,
    props: true,
  },
  {
    path: "/applications/:applicationName/additionalFiles/authorizations/:authorizationId",
    component: AdditionalFilesAuthorizationInfoView,
    props: true,
  },
  {
    path: "/applications/:applicationName/additionalFiles/:additionalFileName/:additionalFileId",
    name: "New additional file management view",
    component: AdditionalFileInfosView,
    props: true,
  },
  {
    path: "/applications/:applicationName/authorizations/public/:authorizationId",
    component: DataTypeAuthorizationInfoView,
    props: true,
  },
  {
    path: "/applications/:applicationName/authorizations/:authorizationId",
    component: DataTypeAuthorizationInfoView,
    props: true,
  },
  {
    path: "/applications/:applicationName/authorizationsRequest/:authorizationId",
    component: AuthorizationsRightsRequestInfoView,
    props: true,
  },
  {
    path: "/applications/:applicationName/authorizationsRequest",
    component: RequestAuthorizationManagementView,
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
