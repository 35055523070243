// sessionService.js
import services from "@/composable/services";
import router from "@/router";

const MAX_AGE = 60 * 60 * 1000; // une heure
const TIME_ALERT = 5 * 60 * 1000; //5 minutes

export default {
  // Timestamp du dernier appel réussi au serveur
  lastServerCallTime: Date.now(),

  // Durée de la session en ms (3600 * 1000 = 1 heure)
  sessionDuration: MAX_AGE,

  // Afficher l'alerte quand il reste X ms (par exemple 5 minutes)
  warningThreshold: TIME_ALERT,

  // Référence au timer de vérification
  sessionCheckTimer: null,

  // Référence au timer d'expiration
  expirationTimer: null,

  // État de la modal d'avertissement
  isWarningModalVisible: false,
  sessionExpirationWarning: null,

  // Initialiser le service
  init(sessionExpirationWarning) {
    this.sessionExpirationWarning = sessionExpirationWarning;
    // Démarrer la vérification périodique
    this.startSessionCheck();

    // Mettre à jour le timestamp lors du chargement initial
    this.updateLastCallTime();
  },

  // Démarrer la vérification périodique
  startSessionCheck() {
    if (!this.sessionCheckTimer) {
      this.sessionCheckTimer = setInterval(() => {
        this.checkSessionStatus();
      }, 1000);
    }
  },

  // sessionService.js
  updateLastCallTime() {
    this.lastServerCallTime = Date.now();

    // Réinitialiser le timer d'expiration sans toucher au check principal
    if (this.expirationTimer) {
      clearTimeout(this.expirationTimer);
      this.expirationTimer = null;
    }
    this.checkSessionStatus();
  },

  // Nouvelle méthode pour arrêter la vérification
  stopSessionCheck() {
    this.lastServerCallTime = Date.now();
  },

  // Vérifier le statut de la session
  checkSessionStatus() {
    if (router.currentRoute.path === "/login") return;
    const timeSinceLastCall = Date.now() - this.lastServerCallTime;
    const timeRemaining = this.sessionDuration - timeSinceLastCall;
    if (timeRemaining <= 0) {
      this.handleExpiredSession();
    } else if (timeRemaining <= this.warningThreshold && !this.isWarningModalVisible) {
      // Proche de l'expiration, afficher l'avertissement
      this.showWarningModal(Math.floor(timeRemaining / 1000));
    }
  },

  // Afficher la modal d'avertissement
  showWarningModal(remainingSeconds) {
    this.isWarningModalVisible = true;
    if (this.sessionExpirationWarning) {
      this.sessionExpirationWarning.showWarning(remainingSeconds);
    }
    // Configurer le timer d'expiration finale
    this.expirationTimer = setTimeout(() => {
      this.handleExpiredSession();
    }, remainingSeconds * 1000);
  },

  // Cacher la modal d'avertissement
  hideWarningModal() {
    this.isWarningModalVisible = false;
    if (this.sessionExpirationWarning) {
      this.sessionExpirationWarning.hideWarning();
    }
  },

  // Gérer l'expiration de session
  handleExpiredSession() {
    // Nettoyer les timers
    if (this.sessionCheckTimer) {
      clearInterval(this.sessionCheckTimer);
    }
    if (this.expirationTimer) {
      clearTimeout(this.expirationTimer);
    }
    this.hideWarningModal();
  },

  // Rafraîchir la session
  refreshSession() {
    return services.loginService
      .refreshSession()
      .then((response) => {
        if (response) {
          this.updateLastCallTime();
          this.hideWarningModal();
          this.checkSessionStatus();
          return true;
        }
        return false;
      })
      .catch(() => {
        // En cas d'erreur, considérer la session comme expirée
        this.handleExpiredSession();
        return false;
      });
  },
};
