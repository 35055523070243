<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ valid }">
      <b-message class="mt-4" has-icon size="is-medium" type="is-info">
        <p>
          {{ $t("updateUser.info-email") }} <b>{{ email }}</b>
        </p>
      </b-message>
      <section v-if="!verificationKeyVisible">
        <TextField
          v-model:value="password"
          type="password"
          label="login.pwd"
          name="password"
          place-holder="login.pwd-placeholder"
          :mandatory="true"
          @update:value="password = $event"
        />
        <text-field
          v-model:value="newEmail"
          place-holder="login.email-placeholder"
          :mandatory="true"
          label="updateUser.edit-email"
          name="change_email.new_email"
          rules="email"
          @update:value="newEmail = $event"
        />
        <text-field
          v-model:value="newEmailConfirm"
          place-holder="login.email-placeholder"
          label="updateUser.edit-email-confirm"
          name="change_email.new_email_confirmed"
          rules="email|confirmed:change_email.new_email"
          :mandatory="true"
          @update:value="newEmailConfirm = $event"
        />
        <b-button
          :active="valid"
          v-if="!verificationKeyVisible"
          icon-left="check"
          style="margin-bottom: 15px; float: right"
          type="is-primary"
          @click="testEmail(newEmail, password)"
          >{{ $t("login.finish-step") }}
        </b-button>
      </section>
      <section v-else>
        <b-notification :aria-close-label="$t('message.close')" type="is-info is-light">
          <span v-html="$t('login.info-mail-validate-key', { email })" class="column" />
        </b-notification>
        <TextField
          v-if="verificationKeyVisible"
          rules="length:10"
          v-model:value="verificationKey"
          label="login.verificationKey"
          name="change_email.verificationKey"
          place-holder="login.verificationKey-placeholder"
          :mandatory="true"
          @update:value="verificationKey = $event"
        />
        <b-button
          icon-left="floppy-disk"
          icon-pack="far"
          style="margin-bottom: 15px; float: right"
          type="is-primary"
          @click="validationEmail(newEmail, password, verificationKey)"
          >{{ $t("change_email.finish-step") }}
        </b-button>
      </section>
    </ValidationObserver>
  </div>
</template>

<script>
import useBoolean from "@/composable/components/boolean";
import useText from "@/composable/components/text";
import services from "@/composable/services";
import TextField from "@/components/forms/TextField.vue";
import { ValidationObserver } from "vee-validate";

export default {
  name: "EditEmail",
  components: {
    TextField,
    ValidationObserver,
  },
  props: {
    user: Object,
    email: String,
  },
  emits: ["changed-email"],
  setup(props) {
    const verificationKeyVisible = useBoolean().refBoolean;
    const newEmail = useText().refText;
    const newEmailConfirm = useText().refText;
    const verificationKey = useText().refText;
    const password = useText().refText;

    async function testEmail(newEmail, password) {
      try {
        await services.loginService.modifAcount({
          login: this.user.login,
          password: password,
          email: newEmail,
        });
        verificationKeyVisible.value = true;
      } catch (e) {
        services.alertService.toastServerError(e);
      }
    }

    async function validationEmail(newEmail, password, verificationKey) {
      try {
        let response = await services.loginService.modifAcount({
          login: props.user.login,
          password: password,
          verificationKey: verificationKey,
        });
        this.$emit("changed-email", response);
        resetVariables();

        verificationKeyVisible.value = true;
      } catch (e) {
        services.alertService.toastServerError(e);
      }
    }

    function resetVariables() {
      newEmail.value = "";
      newEmailConfirm.value = "";
      verificationKey.value = "";
      password.value = "";
      verificationKey.value = "";
    }

    return {
      verificationKeyVisible,
      verificationKey,
      newEmail,
      newEmailConfirm,
      password,

      testEmail,
      resetVariables,
      validationEmail,
    };
  },
};
</script>
