<template>
  <div>
    <PageView :application="application" class="with-submenu">
      <SubMenu
        :aria-label="$t('menu.aria-sub-menu')"
        :paths="subMenuPaths"
        :root="application.localName || application.title"
        role="navigation"
      />
      <TitleAndDescription
        :application="application"
        :localDescription="application.localDatatypeDescription"
        :localName="application.localDatatypeName"
        :local-title="
          $t('titles.data-types-repository', {
            dataName: application.localDatatypeName || dataId,
          })
        "
      />
      <div class="columns">
        <div class="column">
          <form class="card">
            <b-collapse animation="slide" aria-id="fileDeposit" class="card">
              <template #trigger="props">
                <div aria-controls="fileDeposit" class="card-header">
                  <h2 class="card-header-title">
                    {{ $t("dataTypesRepository.card-title-upload-file") }}
                  </h2>
                  <a class="card-header-icon" tabindex="0">
                    <b-icon :icon="props.open ? 'chevron-down' : 'chevron-up'"></b-icon>
                  </a>
                </div>
              </template>
              <div class="card-content">
                <div class="content">
                  <div class="columns" v-if="hasAuthorizationScopes">
                    <div
                      class="column"
                      v-for="(referenceScopesByData, index) in referenceScopes[dataId]"
                      :key="index"
                    >
                      <b-field
                        class="column"
                        :label="referenceScopesByData.i18n[locale] || referenceScopesByData.id"
                      >
                        <b-dropdown
                          :ref="referenceScopesByData.id"
                          :id="referenceScopesByData.id"
                          expanded
                          open-on-focus
                          :triggers="['focus']"
                          max-height="500"
                          scrollable
                          width="500"
                        >
                          <template #trigger="{ active }">
                            <b-taginput
                              v-model="requiredAuthorizationsLabels[referenceScopesByData.id]"
                              :aria-placeholder="
                                $t('dataTypesRepository.placeholder-select-scope-depot', {
                                  scope:
                                    referenceScopesByData.i18n[locale] || referenceScopesByData.id,
                                })
                              "
                              :closable="false"
                              :icon-right="active ? 'angle-up' : 'angle-down'"
                              :placeholder="
                                $t('dataTypesRepository.placeholder-select-scope-depot', {
                                  scope:
                                    referenceScopesByData.i18n[locale] || referenceScopesByData.id,
                                })
                              "
                              rounded
                              type="is-dark"
                            >
                            </b-taginput>
                          </template>
                          <CollapseMenuOneSelect
                            v-for="(option, optionKey) in referenceScopesByData.nodes"
                            :id="option.id"
                            :key="optionKey"
                            :option="option"
                            :list-select="requiredAuthorizationsLabels[referenceScopesByData.id]"
                            :id-reference-scope="referenceScopesByData.id"
                            @select-menu-item="selectAuthorization($event.type, $event)"
                          ></CollapseMenuOneSelect>
                        </b-dropdown>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns" v-if="hasTimeScope">
                    <div class="column">
                      <b-field
                        aria-hidden="false"
                        :label="$t('dataTypesRepository.start-date')"
                        class="column"
                        data-cy="dateStart"
                      >
                        <InputDate
                          aria-hidden="false"
                          :format="patternDateTimeScope"
                          :from="startDate"
                          :input-type="determinateInputType()"
                          :is-simple-value="true"
                          name="startDate"
                          @update:dateValue="updateDate($event)"
                        >
                        </InputDate>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field
                        aria-hidden="false"
                        :label="$t('dataTypesRepository.end-date')"
                        class="column"
                        data-cy="dateEnd"
                      >
                        <InputDate
                          aria-hidden="false"
                          :format="patternDateTimeScope"
                          :from="endDate"
                          :input-type="determinateInputType()"
                          :is-simple-value="true"
                          name="endDate"
                          @update:dateValue="updateDate($event)"
                        >
                        </InputDate>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column" style="display: flex; align-items: center">
                      <b-upload
                        aria-hidden="false"
                        v-model="file"
                        class="column file-label"
                        data-cy="changeFileButton"
                        @input="changeFile"
                      >
                        <span class="file-cta">
                          <b-icon class="file-icon" icon="upload"
                                  aria-hidden="false"></b-icon>
                          <span class="file-label">{{
                              $t("dataTypesRepository.choose-file")
                            }}</span>
                        </span>
                        <span v-if="file" class="file-name">
                          {{ file.name }}
                        </span>
                      </b-upload>
                    </div>
                  </div>
                  <div class="columns">
                    <b-field :label="$t('dataTypesRepository.comment')" class="column" expanded>
                      <b-input v-model="comment" maxlength="200" type="textarea"></b-input>
                    </b-field>
                  </div>
                </div>
              </div>
              <footer class="card-footer">
                <div class="column is-10"></div>
                <div class="column is-2" style="float: right">
                  <b-button expanded style="float: right" type="is-dark" @click="upload"
                  >{{ $t("dataTypesRepository.submit") }}
                  </b-button>
                </div>
              </footer>
            </b-collapse>
          </form>
        </div>
      </div>
      <div v-if="submissionListVisible || !hasAuthorizationScopes" class="columns">
        <div class="card column">
          <LoadingAnimate v-if="loading" :size="'is-medium'"></LoadingAnimate>
          <div v-else id="element" class="card-content">
            <table
              v-if="datasets && Object.keys(datasets).length"
              class="table is-striped is-fullwidth numberData"
              style="text-align: center; vertical-align: center"
            >
              <caption>
                {{
                  $t("dataTypesRepository.list-file-data")
                }}
              </caption>
              <caption
                v-if="Object.keys(requiredAuthorizationsLabels || {}).length !== 0"
                class="subtitle"
              >
                {{
                  $t("dataTypesRepository.scope-depot") + ": "
                }}
                <b-tag
                  v-for="scopeLabel in Object.values(requiredAuthorizationsLabels || {})"
                  :key="scopeLabel[0]"
                  type="is-primary"
                >
                  {{ scopeLabel[0] }}
                </b-tag>
              </caption>
              <tr>
                <th align v-if="hasTimeScope">{{ $t("dataTypesRepository.table-file-data-period") }}</th>
                <th align>{{ $t("dataTypesRepository.version-number") }}</th>
                <th align>{{ $t("dataTypesRepository.table-file-data-publication") }}</th>
              </tr>
              <tr
                v-for="(dataset, periode) in datasets"
                :key="dataset.id"
                style="cursor: pointer"
                tabindex="0"
                @click="showDatasets(dataset)"
                @keyup.enter="showDatasets(dataset)"
              >
                <td align v-if="hasTimeScope">{{ periode }}</td>
                <td align>{{ Object.keys(dataset.datasets).length }}</td>
                <td align>{{ dataset.publication }}</td>
              </tr>
            </table>
            <table
              v-if="currentDataset && currentDataset.length"
              class="table is-striped is-fullwidth"
              style="text-align: center; vertical-align: center"
            >
              <caption>
                {{
                  $t("dataTypesRepository.list-file-data-period")
                }}
                {{
                  currentDataset[0].periode
                }}
                <div v-if="errorsMessages.length !== 0" style="margin: 10px">
                  <ShowErrors
                    :errors-messages="errorsMessages"
                    :errors-messages-length="errorsMessages.length"
                    :title="$t('message.data-type-config-error')"
                  ></ShowErrors>
                </div>
              </caption>

              <tr>
                <th align>{{ $t("dataTypesRepository.table-file-data-version") }}</th>
                <th align>{{ $t("dataTypesRepository.table-file-data-id") }}</th>
                <th align>{{ $t("dataTypesRepository.table-file-data-size") }}</th>
                <th align>{{ $t("dataTypesRepository.table-file-data-create") }}</th>
                <th align>{{ $t("dataTypesRepository.table-file-data-create-by") }}</th>
                <th align>{{ $t("dataTypesRepository.table-file-data-publish") }}</th>
                <th align>{{ $t("dataTypesRepository.table-file-data-publish-by") }}</th>
                <th align>{{ $t("dataTypesRepository.table-file-data-publication") }}</th>
                <th align>{{ $t("dataTypesRepository.table-file-data-action") }}</th>
              </tr>
              <tr v-for="(dataset, index) in currentDataset" :key="dataset.id">
                <td align>{{ index + 1 }}</td>
                <td align>
                  <b-tooltip :id="dataset.id" multilined role="tooltip" type="is-dark">
                    <template v-slot:content>
                      <h3>{{ $t("dataTypesRepository.comment") }} {{ $t("ponctuation.colon") }}</h3>
                      <p>{{ UTCToString(dataset.params.binaryFileDataset.comment) }}</p>
                    </template>
                    <a
                      :aria-describedby="dataset.id"
                      tabindex="0"
                      @keyup.enter="changeCss(dataset.id)"
                    >
                      {{ dataset.id.slice(0, 8) }}
                    </a>
                  </b-tooltip>
                </td>
                <td align>
                  {{ dataset.size / 1000 }}
                  {{ $t("dataTypesRepository.table-file-data-size-unit") }}
                </td>
                <td align>{{ formatDate(dataset?.params?.createdate) }}</td>
                <td align>{{ dataset?.params?.createuser?.email }}</td>
                <td v-if="dataset.params.published" align>
                  {{ formatDate(dataset.params.publisheddate) }}
                </td>
                <td v-else align></td>
                <td v-if="dataset.params.published" align>{{ dataset?.params?.publisheduser?.email }}</td>
                <td v-else align></td>
                <td align>
                  <b-field>
                    <b-button
                      :icon-right="dataset.params.published ? 'check-circle' : 'circle'"
                      size="is-medium"
                      style="height: 1.5em; background-color: transparent; font-size: 1.45rem"
                      type="is-primary is-light"
                      @click="publish(dataset, !dataset.params.published)"
                    />
                  </b-field>
                </td>
                <td align>
                  <b-field>
                    <b-tooltip
                      :label="$t('dataTypesRepository.table-file-data-delete')"
                      position="is-left"
                    >
                      <b-button
                        icon-right="times-circle"
                        size="is-medium"
                        style="height: 1.5em; background-color: transparent; font-size: 1.45rem"
                        type="is-danger is-light"
                        @click="remove(dataset, dataset.params.published)"
                      />
                    </b-tooltip>
                    <b-tooltip
                      :label="$t('dataTypesRepository.table-file-data-download')"
                      position="is-left"
                    >
                      <b-button
                        icon-right="download"
                        size="is-medium"
                        style="height: 1.5em; background-color: transparent; font-size: 1.45rem"
                        type="is-primary is-light"
                        @click="downloadDataTypeVersion(dataset)"
                      />
                    </b-tooltip>
                  </b-field>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </PageView>
  </div>
</template>

<script>
import PageView from "@/views/common/PageView.vue";
import { ApplicationResult } from "@/model/ApplicationResult";
import SubMenu, { SubMenuPath } from "@/components/common/SubMenu.vue";
import { BinaryFileDataset } from "@/model/file/BinaryFileDataset";
import { FileOrUUID } from "@/model/file/FileOrUUID";
import { HttpStatusCodes } from "@/utils/HttpUtils";
import LoadingAnimate from "@/components/common/LoadingAnimate.vue";
import useObject from "@/composable/components/object";
import useArray from "@/composable/components/array";
import useBoolean from "@/composable/components/boolean";
import services from "@/composable/services";
import { onMounted, provide, ref, inject } from "vue";
import app, { i18n } from "@/main";
import { datePatternLang } from "@/composable/application/datePattern";
import { BinaryFile } from "@/model/file/BinaryFile";
import { Dataset } from "@/model/file/Dataset";
import InputDate from "@/components/common/provider/InputDate.vue";
import ShowErrors from "@/components/application/ShowErrors.vue";
import { dataLoader } from "@/composable/data/dataLoader";
import { ReferenceScope } from "@/model/authorization/ReferenceScope";
import CollapseMenuOneSelect from "@/components/common/CollapseMenuOneSelect.vue";
import TitleAndDescription from "@/components/common/TitleAndDescription.vue";

export default {
  name: "DataVersioningView",
  emits: ["file-changed"],
  computed: {
    ReferenceScope() {
      return ReferenceScope;
    },
    services() {
      return services;
    }
  },
  components: {
    TitleAndDescription,
    CollapseMenuOneSelect,
    ShowErrors,
    InputDate,
    LoadingAnimate,
    PageView,
    SubMenu
  },
  props: {
    applicationName: {
      type: String
    },
    dataId: {
      type: String
    }
  },
  setup(props, ctx) {
    const { startUpload, onUploadComplete, onUploadProgress, finishUpload } = inject("fileUpload");
    const loader = dataLoader(services);
    provide("reference:dataLoader", loader);
    const locale = services.internationalisationService.getLocale();
    const { getValueDisplay } = loader;
    const { reactiveObject: references, doChangeObject: changeReferences } = useObject();
    const { shallowRefArray: subMenuPaths, doChangeArray: changeSubMenuPaths } = useArray();
    const {
      shallowRefArray: errorsList,
      doChangeArray: changeErrorsList,
      doAddToArray: addErrorsList
    } = useArray();
    const { reactiveObject: application, doChangeObject: changeApplication } = useObject(
      new ApplicationResult()
    );
    const { reactiveObject: configuration, doChangeObject: changeConfiguration } = useObject();
    const { reactiveObject: referenceScopes, doChangeObject: changeReferenceScopes } = useObject();
    const { shallowRefArray: authorizations, doChangeArray: changeAuthorizations } = useArray();
    const { reactiveObject: versioning, doChangeObject: changeVersioning } = useObject();
    const { shallowRefArray: errorsMessages, doChangeArray: changeErrorsMessages } = useArray();
    const { reactiveObject: selected, doChangeObject: changeSelected } = useObject();
    const { refBoolean: loading } = useBoolean();
    const { refBoolean: submissionListVisible } = useBoolean();
    const {
      reactiveObject: requiredAuthorizationsObject,
      doChangeObject: changeRequiredAuthorizationsObject
    } = useObject();
    const {
      reactiveObject: requiredAuthorizationsLabels,
      doChangeObject: changeRequiredAuthorizationsLabels
    } = useObject();
    let getSubmissionReferenceScopePath =
      services.internationalisationService.getSubmissionReferenceScopePath;
    let getDataTypeDisplay = services.internationalisationService.getDataTypeDisplay;
    let datasets = ref({});
    let file = ref(null);
    let startDate = ref(null);
    let endDate = ref(null);
    let comment = ref("");
    let currentDataset = ref(null);
    let componentNameTimeScope = "";
    let loadedReferences = {};
    let patternDateTimeScope = ref("dd/MM/yyyy HH:mm");
    const { reactiveObject: dateTimeFormat } = useObject({
      d: { pattern: "\\d", type: "date" },
      h: { pattern: "\\d", type: "time" },
      m: { pattern: "\\d", type: "time" },
      s: { pattern: "\\d", type: "time" },
      n: { pattern: "\\d", type: "time" },
      a: { pattern: "[AP]M]", type: "time" },
      y: { pattern: "\\d", type: "date" },
      M: { pattern: "\\d", type: "date" },
      Z: { pattern: "[+-]\\d{4}", type: "date" },
      G: { pattern: "[AB]D", type: "date" }
    });
    onMounted(async () => {
      await init();
      changeSubMenuPaths([
        new SubMenuPath(
          application?.localDatatypeName?.toLowerCase() || props?.dataId?.toLowerCase(),
          () => {
          },
          () => app.$router.push(`/applications/${props.applicationName}/dataTypes`)
        )
      ]);
      if (!hasAuthorizationScopes.value) {
        updateDatasets();
      }
    });
    const { refBoolean: hasAuthorizationScopes } = useBoolean();
    const { refBoolean: hasTimeScope } = useBoolean();

    function updateDate({ name, value }) {
      if ("startDate" === name) {
        startDate.value = value;
        console.log("startDate", startDate.value);
      } else if ("endDate" === name) {
        endDate.value = value;
        console.log("endDate", endDate.value);
      }
    }

    function changeFile() {
      console.log("TODO dataversioning::changeFile");
    }

    function changeCss(id) {
      if (document.getElementById(id).querySelector(".tooltip-content").style.display === "block")
        document.getElementById(id).querySelector(".tooltip-content").style.display = "none";
      else document.getElementById(id).querySelector(".tooltip-content").style.display = "block";
    }

    async function init() {
      try {
        changeApplication(
          await services.applicationService.getApplication(props.applicationName, [
            "CONFIGURATION",
            "DATATYPE",
            "REFERENCETYPE"
          ])
        );
        const loadingComponent = app.$buefy.loading.open({
          container: document.getElementById("element")
        });
        changeVersioning(application.configuration.dataDescription[props.dataId].submission);
        hasAuthorizationScopes.value = versioning?.submissionScope?.referenceScopes && versioning?.submissionScope?.referenceScopes?.length !== 0;
        hasTimeScope.value = Object.keys(versioning?.submissionScope?.timescope || {})?.length !== 0;
        componentNameTimeScope = versioning?.submissionScope?.timescope?.component;
        changeApplication({
          ...services.internationalisationService.mergeInternationalization(application),
          localDatatypeName: application.configuration.i18n.data[props.dataId].i18n.title[locale],
          localDatatypeDescription:
            application.configuration.i18n.data[props.dataId].i18n.description[locale]
        });
        changeConfiguration(application.configuration.dataDescription[props.dataId]);
        changeAuthorizations(configuration.submission.submissionScope);
        changeReferences(application.references);
        changeSelected(
          new BinaryFileDataset({
            datatype: props.dataId,
            requiredAuthorizations: {},
            from: "",
            to: "",
            comment: ""
          })
        );
        let refForAuth = {};
        let reference = "";
        for (let auth in authorizations.value) {
          if (!Object.keys(authorizations?.value[auth] || {}).includes("component", "reference")) {
            for (let authRef in authorizations.value[auth]) {
              if (authorizations.value[auth][authRef].reference) {
                reference = authorizations.value[auth][authRef].reference;
              } else if (authorizations.value[auth][authRef].component) {
                let component = authorizations.value[auth][authRef].component;
                if (configuration.componentDescriptions[component].checker.refType) {
                  reference = configuration.componentDescriptions[component].checker.refType;
                } else if (configuration.validations) {
                  // TODO a tester si quand on met le checker références dans la validation c'est bon
                  for (let validationName in configuration.validations) {
                    if (configuration.validations[validationName].columns.contain(component)) {
                      reference = configuration.validations[validationName].checkers.refType;
                    }
                  }
                }
              }
              if (reference !== "") {
                let ref = await services.dataService.getData(props.applicationName, reference);
                loadedReferences[reference] = ref;
                refForAuth[reference] = ref;
                changeReferenceScopes(refForAuth[reference].referenceScopes);
              }
              sortedReferenceScope(referenceScopes);
            }
          }
        }
        // format date en fonction du pattern du checker
        if (configuration?.componentDescriptions?.[componentNameTimeScope]?.checker?.pattern) {
          patternDateTimeScope.value =
            configuration.componentDescriptions[componentNameTimeScope].checker.pattern;
        } else if (configuration.validations) {
          // TODO a tester si quand on met le checker références dans la validation c'est bon
          for (let validationName in configuration.validations) {
            if (configuration.validations[validationName].columns.contain(componentNameTimeScope)) {
              patternDateTimeScope.value =
                configuration.validations[validationName].checkers.pattern;
            }
          }
        }
        for (const [key, value] of Object.entries(refForAuth)) {
          refForAuth[key] = await partitionReferencesValues(value.rows);
        }
        ctx.emit("parseAuth", refForAuth);
        loadingComponent.close();
      } catch (error) {
        console.log("erreur", error);
        services.alertService.toastServerError();
      }
    }

    function sortedReferenceScope(referenceScope) {
      for (let i = 0; i < referenceScope[props.dataId].length; i++) {
        if (Object.keys(referenceScope[props.dataId][i].nodes).length !== 0) {
          referenceScope[props.dataId][i].nodes = Object.fromEntries(
            Object.entries(referenceScope[props.dataId][i].nodes)
              .sort(([, a], [, b]) => a.naturalKey.localeCompare(b.naturalKey))
              .map(([key, value], index) => [index, value, key])
          );
        }
      }
    }

    function nameSelectedTagList(nameTagList) {
      return i18n.t("dataTypesManagement.selected_filter", { key: nameTagList });
    }

    async function getOrLoadReferences(reference) {
      if (loadedReferences[reference]) {
        return loadedReferences[reference];
      }
      let ref = await services.dataService.getData(props.applicationName, reference);
      app.$set(references, reference, ref);
      loadedReferences[reference] = ref;
      return ref;
    }

    function UTCToString(utcString) {
      return utcString && utcString.replace(/(\d{4})-(\d{2})-(\d{2}).*!/, "$3/$2/$1");
    }

    function determinateInputType() {
      let p = patternDateTimeScope.value;
      let t = { date: false, time: false };
      Object.keys(dateTimeFormat).forEach((search) => {
        if (p.match(search)) {
          t[dateTimeFormat[search].type] = true;
          p = p.replaceAll(search, dateTimeFormat[search].pattern);
        }
      });
      if (t.date && t.time) {
        return "datetime";
      } else if (t.date) {
        return "date";
      } else if (t.time) {
        return "time";
      }
    }

    function formatDate(date) {
      return datePatternLang(new Date(date).toISOString(), "dd/MM/yyyy HH:mm");
    }

    async function showDatasets(dataset) {
      if (!dataset) {
        currentDataset.value = null;
      }
      currentDataset.value = dataset?.datasets?.sort(
        (d1, d2) => d1.params.createDate < d2.params.createDate
      );
    }

    async function upload() {
      try {
        startUpload();
        let localStartDate = null;
        let localEndDate = null;
        if (hasTimeScope.value) {
          if (startDate.value) {
            localStartDate = /(.{10})T(.{8}).*/
              .exec(new Date(startDate.value).toISOString())
              .filter((a, i) => i !== 0)
              .join(" ");
          }
          if (endDate.value) {
            localEndDate = /(.{10})T(.{8}).*/
              .exec(new Date(endDate.value).toISOString())
              .filter((a, i) => i !== 0)
              .join(" ");
          }
        }
        if (file.value) {
          const fileOrId = new FileOrUUID(
            null,
            new BinaryFileDataset(
              props.dataId,
              selected.requiredAuthorizations,
              localStartDate,
              localEndDate,
              comment.value
            ),
            false
          );
          const uuid = await services.dataService.addData(
            props.applicationName,
            props.dataId,
            file.value,
            fileOrId,
            onUploadProgress,
            onUploadComplete
          );
          await updateDatasets(uuid);
        }
      } finally {
        finishUpload();
      }
    }

    async function publish(dataset, pusblished) {
      loading.value = true;
      startUpload(false);
      changeErrorsMessages([]);
      dataset.params.published = pusblished;
      let requiredAuthorizations = dataset.params.binaryFileDataset.requiredAuthorizations;
      requiredAuthorizations = Object.keys(requiredAuthorizations).reduce(function(acc, key) {
        acc[key] = acc[key] ? acc[key].sql : "";
        return acc;
      }, requiredAuthorizations);
      dataset.params.binaryFileDataset.requiredAuthorizations = requiredAuthorizations;
      const fileOrId = new FileOrUUID(dataset.id, dataset.params.binaryFileDataset, pusblished);
      try {
        const uuid = await services.dataService.addData(
          props.applicationName,
          props.dataId,
          null,
          fileOrId,
          onUploadProgress,
          onUploadComplete
        );
        await updateDatasets(uuid.id);
        services.alertService.toastSuccess(i18n.t("alert.data-updated"));
      } catch (error) {
        checkMessageErrors(error);
      } finally {
        finishUpload();
      }
      loading.value = false;
    }

    function checkMessageErrors(error) {
      let message = [];
      if (error.httpResponseCode === HttpStatusCodes.BAD_REQUEST) {
        if (error.content != null) {
          changeErrorsList([]);
          error.content.then((value) => {
            for (let i = 0; i < value.length; i++) {
              if (message.length > 0) {
                if (JSON.stringify(value[i]) !== JSON.stringify(value[i - message.length])) {
                  addErrorsList(value[i]);
                }
                for (let j = 0; j < message.length; j++) {
                  if (!message.includes(value[i].validationCheckResult.message)) {
                    message.push(value[i].validationCheckResult.message);
                  }
                }
              } else {
                message.push(value[i].validationCheckResult.message);
                addErrorsList(value[i]);
              }
            }
            if (errorsList.value.length !== 0) {
              changeErrorsMessages(services.errorsService.getCsvErrorsMessages(errorsList.value));
            } else {
              changeErrorsMessages(services.errorsService.getErrorsMessages(error));
            }
          });
        }
      } else {
        services.alertService.toastServerError(error);
      }
    }

    async function selectAuthorization(key, event) {
      selected.requiredAuthorizations[event.type] = [event.naturalKey];
      changeRequiredAuthorizationsObject({
        ...requiredAuthorizationsObject,
        [event.type]: [event.id]
      });
      changeRequiredAuthorizationsLabels({
        ...requiredAuthorizationsLabels,
        [event.type]: [event.localName]
      });
      if (currentDataset.value) {
        datasets.value = currentDataset.value;
      } else {
        datasets.value = {};
      }
      app.$refs?.[key]?.[0].toggle();
      if (isReferenceScopesSelected() || submissionListVisible.value) {
        await updateDatasets();
      }
    }

    function isReferenceScopesSelected() {
      if (!hasAuthorizationScopes.value) {
        return true;
      }
      if (selected && selected.requiredAuthorizations) {
        submissionListVisible.value =
          selected &&
          selected.requiredAuthorizations &&
          Object.keys(selected.requiredAuthorizations).length !== 0 &&
          Object.values(selected.requiredAuthorizations).every((v) => v?.length);
        return submissionListVisible.value;
      }
      return false;
    }

    async function updateDatasets(uuid) {
      if (isReferenceScopesSelected() || submissionListVisible.value) {
        let datasetsList = await services.fileService.getFiles(
          props.applicationName,
          props.dataId,
          selected
        );
        if (!datasetsList || !datasetsList.length) {
          datasets.value = {};
          currentDataset.value = null;
          return;
        }
        getDatasetMap({
          binaryFileList: datasetsList.map((d) => new BinaryFile(d)),
          uuid: uuid
        });
      }
    }

    function getDatasetMap(fileList) {
      const datasetMap = {};
      for (let index in fileList.binaryFileList) {
        const binaryFile = fileList.binaryFileList[index];
        const localCurrentDataset = datasetMap[binaryFile.periodeToString] || new Dataset(binaryFile);
        localCurrentDataset.addDataset(binaryFile);
        datasetMap[binaryFile.periodeToString] = localCurrentDataset;
      }
      datasets.value = datasetMap;
      if (fileList.uuid) {
        const periode =
          fileList.uuid &&
          datasets.value &&
          Object.values(datasets.value).filter((e) => {
            //e.findByUUID(fileList.uuid)
            return e.id === fileList.uuid.id;
          })?.periode;
        currentDataset.value = datasets?.[periode]?.datasets;
      }
      return datasets.value;
    }

    function remove(dataset, isPublished) {
      this.$buefy.dialog.confirm({
        message:
          (isPublished
            ? "<b>La version contient des données publiées.</b><br />La supprimer entraînera la suppression de ces données.<br /><br />?"
            : "") + "Etes vous sûr de vouloir supprimer cette version?",
        onConfirm: () => deleteFile(dataset.id)
      });
    }

    async function downloadDataTypeVersion(event) {
      await services.fileService.download(props.applicationName, event.id);
      return false;
    }

    async function deleteFile(uuid) {
      const deleted = await services.fileService.remove(props.applicationName, uuid);
      await updateDatasets(deleted);
    }

    async function partitionReferencesValues(
      referencesValues,
      currentPath,
      currentCompleteLocalName
    ) {
      let returnValues = {};
      for (const referenceValue of referencesValues) {
        const previousKeySplit = currentPath ? currentPath.split(".") : [];
        const keys = referenceValue.hierarchicalKey.split(".");
        const referencesInPartitionRefVal = referenceValue.hierarchicalKey.split(".");
        let reference;
        if (previousKeySplit.length === keys.length) {
          continue;
        }
        for (let i = 0; i < previousKeySplit.length; i++) {
          keys.shift();
          referencesInPartitionRefVal.shift();
        }
        const key = keys.shift();
        let newCurrentPath = (currentPath ? currentPath + "." : "") + key;
        reference = referencesInPartitionRefVal.shift().split("K").shift();
        let refValues;
        if (application.data[reference]) {
          refValues = await getOrLoadReferences(reference);
        }
        services.internationalisationService.getUserPrefLocale();
        let localName = refValues.rows.find(
          (r) => r.hierarchicalKey === newCurrentPath || r.naturalKey === key.split("K").pop()
        );
        // TODO passé par la constante de l'application pour "__display_" plus tard (en attente de réparation de philippe
        let naturalKey = localName.naturalKey;
        localName = getValueDisplay(localName, application);
        if (!localName) {
          localName = key;
        }
        let completeLocalName =
          typeof currentCompleteLocalName === "undefined" ? "" : currentCompleteLocalName;
        completeLocalName = completeLocalName + (completeLocalName === "" ? "" : ",") + localName;
        let authPartition = returnValues[naturalKey] || {
          key,
          naturalKey,
          reference,
          rows: [],
          localName,
          isLeaf: false,
          currentPath: newCurrentPath,
          completeLocalName
        };
        authPartition.rows.push(referenceValue);
        returnValues[naturalKey] = authPartition;
      }
      for (const returnValuesKey in returnValues) {
        const auth = returnValues[returnValuesKey];
        let referenceValueLeaf = auth.rows?.[0];
        if (auth.rows.length <= 1 && referenceValueLeaf.hierarchicalKey === auth.currentPath) {
          returnValues[returnValuesKey] = {
            ...auth,
            isLeaf: true,
            rows: referenceValueLeaf
          };
        } else {
          let r = await partitionReferencesValues(
            auth.rows,
            auth.currentPath,
            auth.completeLocalName
          );
          returnValues[returnValuesKey] = {
            ...auth,
            isLeaf: false,
            rows: r
          };
        }
      }
      return returnValues;
    }

    function internationalizeRequiredAuthorization(data, datasets) {
      const value =
        datasets?.datasets?.[0]?.params?.binaryFileDataset?.requiredAuthorizations?.[data]?.sql;
      const row = loadedReferences?.[data]?.rows.find((row) => row.naturalKey === value);
      if (row === null) {
        return value;
      }
      return getValueDisplay(row, application);
    }

    return {
      hasAuthorizationScopes,
      hasTimeScope,
      changeCss,
      getSubmissionReferenceScopePath,
      getDataTypeDisplay,
      changeFile,
      formatDate,
      UTCToString,
      remove,
      publish,
      upload,
      showDatasets,
      downloadDataTypeVersion,
      selectAuthorization,
      nameSelectedTagList,
      determinateInputType,
      subMenuPaths,
      application,
      locale,
      referenceScopes,
      datasets,
      versioning,
      errorsMessages,
      loading,
      requiredAuthorizationsObject,
      requiredAuthorizationsLabels,
      patternDateTimeScope,
      file,
      startDate,
      endDate,
      updateDate,
      comment,
      currentDataset,
      internationalizeRequiredAuthorization,
      submissionListVisible,
      startUpload,
      onUploadComplete,
      onUploadProgress,
      finishUpload
    };
  }
};
</script>

<style lang="scss">
.DataTypesRepositoryView-message {
  .media-content {
    width: calc(100% - 3em - 4rem);
    overflow-wrap: break-word;
  }
}

.dropdown-content {
  margin-left: 10px;
  margin-right: -30px;
}

table.datasetsPanel {
  width: 50%;
}

table.datasetsPanel,
table.datasetsPanel th,
table.datasetsPanel td {
  border-collapse: collapse;
  text-align: center;
}

.subtitle {
  font-style: italic;
}

.scroll {
  overflow-y: scroll;
  height: 13.12em;
}

.numberData tr:hover td {
  background-color: $primary;
  color: white;
}

caption {
  color: $dark;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 15px;
}

.b-tooltip {
  .tooltip-trigger a {
  }

  .tooltip-content {
  }
}
</style>
