<template>
  <div>
    <b-collapse
      v-if="
        localeInitialized &&
        ((localeReferences.withoutScope &&
          Object.keys(localeReferences.withoutScope).length !== 0) ||
          (localeReferences.withScope && Object.keys(localeReferences.withScope).length !== 0))
      "
      :open="openReference"
      animation="slide"
      aria-id="collapseReferenceSection"
      class="card"
      style="box-shadow: none"
    >
      <template #trigger="detailReference">
        <div
          :aria-expanded="!detailReference.open"
          aria-controls="collapseReferenceSection"
          class="card-header"
          style="box-shadow: none; border-bottom: thick double #009d9d"
        >
          <p class="card-header-title">
            {{ $t("applications.references") }}
          </p>
          <a
            :tabindex="
              localeInitialized &&
              ((localeReferences.withoutScope &&
                Object.keys(localeReferences.withoutScope).length !== 0) ||
                (localeReferences.withScope &&
                  Object.keys(localeReferences.withScope).length !== 0))
                ? 0
                : -1
            "
            class="card-header-icon"
            @keyup.enter="openReference = !openReference"
            @keyup.space="openReference = !openReference"
          >
            <b-icon
              :icon="detailReference.open ? 'chevron-up' : 'chevron-down'"
              type="is-primary"
            ></b-icon>
          </a>
        </div>
      </template>
      <div class="card-content">
        <div class="content">
          <AuthorizationsForNoAuthorization
            v-if="
              localeInitialized &&
              localeReferences.withoutScope &&
              Object.keys(localeReferences.withoutScope).length !== 0
            "
            :application="localeApplication"
            :authorization="
              localeCurrentAuthorization.authorizations
                ? localeCurrentAuthorization.authorizations
                : localeAuthorizations
            "
            :data-types="localeReferences.withoutScope"
            :has-dependencies="localeHasDependencies"
            :list-column-name="localeListColumnName['withoutScope']"
            @update:authorization="$emit('update:authorization', $event)"
          >
          </AuthorizationsForNoAuthorization>
          <AuthorizationForScope
            v-if="
              localeInitialized &&
              localeReferences.withScope &&
              Object.keys(localeReferences.withScope).length !== 0
            "
            :application="localeApplication"
            :authorization="
              localeCurrentAuthorization.authorizations
                ? localeCurrentAuthorization.authorizations
                : localeAuthorizations
            "
            :authorizations-description="localeAuthorizations"
            :data-types="datatypes.withScope"
            :has-dependencies="localeHasDependencies"
            :list-column-name="localeListColumnName['withScope']"
            :references-scopes="localeReferencesScopes"
            @update:authorization="$emit('update:authorization', $event)"
          >
          </AuthorizationForScope>
        </div>
      </div>
    </b-collapse>
    <b-collapse
      v-if="
        localeInitialized &&
        ((localeDatatypes.withoutScope && Object.keys(localeDatatypes.withoutScope).length !== 0) ||
          (localeDatatypes.withScope && Object.keys(localeDatatypes.withScope).length !== 0))
      "
      :open="openDatatype"
      animation="slide"
      aria-id="collapseDatatypeSection"
      class="card"
      style="box-shadow: none"
    >
      <template #trigger="detailDatatype">
        <div
          :aria-expanded="!detailDatatype.open"
          aria-controls="collapseDatatypeSection"
          class="card-header"
          style="box-shadow: none; border-bottom: thick double #009d9d"
        >
          <p class="card-header-title">
            {{ $t("applications.dataset") }}
          </p>
          <a
            :tabindex="
              localeInitialized &&
              ((localeDatatypes.withoutScope &&
                Object.keys(localeDatatypes.withoutScope).length !== 0) ||
                (localeDatatypes.withScope && Object.keys(localeDatatypes.withScope).length !== 0))
                ? 0
                : -1
            "
            class="card-header-icon"
            @keyup.enter="openDatatype = !openDatatype"
            @keyup.space="openDatatype = !openDatatype"
          >
            <b-icon
              :icon="detailDatatype.open ? 'chevron-up' : 'chevron-down'"
              type="is-primary"
            ></b-icon>
          </a>
        </div>
      </template>
      <div class="card-content">
        <div class="content">
          <AuthorizationsForNoAuthorization
            v-if="
              localeInitialized &&
              localeDatatypes.withoutScope &&
              Object.keys(localeDatatypes.withoutScope).length !== 0
            "
            :application="localeApplication"
            :authorization="
              localeCurrentAuthorization.authorizations
                ? localeCurrentAuthorization.authorizations
                : localeAuthorizations
            "
            :data-types="localeDatatypes.withoutScope"
            :has-dependencies="localeHasDependencies"
            :list-column-name="localeListColumnName['withoutScope']"
            @update:authorization="$emit('update:authorization', $event)"
          >
          </AuthorizationsForNoAuthorization>
          <AuthorizationForScope
            v-if="
              localeInitialized &&
              localeDatatypes.withScope &&
              Object.keys(localeDatatypes.withScope).length !== 0
            "
            :application="localeApplication"
            :authorization="
              localeCurrentAuthorization.authorizations
                ? localeCurrentAuthorization.authorizations
                : localeAuthorizations
            "
            :authorizations-description="localeAuthorizations"
            :data-types="localeDatatypes.withScope"
            :has-dependencies="localeHasDependencies"
            :list-column-name="localeListColumnName['withScope']"
            :references-scopes="localeReferencesScopes"
            @update:authorization="$emit('update:authorization', $event)"
          >
          </AuthorizationForScope>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import AuthorizationsForNoAuthorization from "@/components/common/authorization/AuthorizationsForNoAuthorization.vue";
import AuthorizationForScope from "@/components/common/authorization/AuthorizationForScope.vue";
import { ref, watchEffect } from "vue";
import useBoolean from "@/composable/components/boolean";

export default {
  name: "AuthorizationTableForDatatype",
  emits: ["update:authorization"],
  components: {
    AuthorizationsForNoAuthorization,
    AuthorizationForScope,
  },
  props: {
    applicationName: String,
    authorizationId: {
      type: String,
      default: "new",
    },
    datatypes: {
      type: Object,
      required: true,
    },
    listColumnName: {
      type: Object,
      required: true,
    },
    hasDependencies: {
      type: Array,
      default: () => [],
    },
    initialized: Boolean,
    references: {
      type: Object,
      required: true,
    },
    application: {
      type: Object,
      required: true,
    },
    currentAuthorization: {
      type: Object,
      required: true,
    },
    authorizations: {
      type: Object,
    },
    referencesScopes: {
      type: Object,
    },
  },
  setup(props) {
    const localeApplicationName = ref(props.applicationName || "");
    const localeAuthorizationId = ref(props.authorizationId || "");
    const localeDatatypes = ref(props.datatypes || {});
    const localeListColumnName = ref(props.listColumnName || {});
    const localeHasDependencies = ref(props.hasDependencies || []);
    const localeInitialized = ref(props.initialized || false);
    const localeReferences = ref(props.references || {});
    const localeApplication = ref(props.application || {});
    const localeCurrentAuthorization = ref(props.currentAuthorization || {});
    const localeAuthorizations = ref(props.authorizations || {});
    const localeReferencesScopes = ref(props.referencesScopes || {});
    const { refBoolean: openReference } = useBoolean(false);
    const { refBoolean: openDatatype } = useBoolean(false);

    watchEffect(() => {
      localeApplicationName.value = props.applicationName || "";
      localeAuthorizationId.value = props.authorizationId || "";
      localeDatatypes.value = props.datatypes;
      localeListColumnName.value = props.listColumnName;
      localeHasDependencies.value = props.hasDependencies || [];
      localeInitialized.value = props.initialized || false;
      localeReferences.value = props.references;
      localeApplication.value = props.application || {};
      localeCurrentAuthorization.value = props.currentAuthorization || {};
      localeAuthorizations.value = props.authorizations || {};
      localeReferencesScopes.value = props.referencesScopes || {};
    });

    return {
      localeApplicationName,
      localeAuthorizationId,
      localeDatatypes,
      localeListColumnName,
      localeHasDependencies,
      localeInitialized,
      localeReferences,
      localeApplication,
      localeCurrentAuthorization,
      localeAuthorizations,
      localeReferencesScopes,
      openDatatype,
      openReference,
    };
  },
};
</script>

<style lang="scss" scoped>
a {
  color: $dark;
  font-weight: bold;
  text-decoration: underline;
}

a:hover {
  color: $primary;
  text-decoration: none;
}

p {
  font-weight: bold;
}

::marker {
  color: transparent;
}

.column {
  padding: 6px;
}

.show-check-details {
  margin-left: 0.6em;
}

.show-detail-for-selected {
  height: 60px;
}
</style>
