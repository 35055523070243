import { BinaryFileInfos } from "./BinaryFileInfos";
import { datePatternLang } from "@/composable/application/datePattern";
const patternDateTimeScope = "dd/MM/yyyy HH:mm";
export class BinaryFile {
  id;
  params;
  application;
  name;
  size;
  data;
  constructor(params) {
    // Initialisation des propriétés par défaut
    this.id = null;
    this.application = null;
    this.name = null;
    this.size = null;
    this.data = null;
    this.params = null;

    // Si params est un objet, copier les propriétés
    if (params && typeof params === "object") {
      this.id = params.id || null;
      this.application = params.application || null;
      this.name = params.name || null;
      this.size = params.size || null;
      this.data = params.data || null;

      // Copier params avant de le transformer
      const paramsData = params.params || {};

      // Créer une nouvelle instance de BinaryFileInfos
      this.params = new BinaryFileInfos(paramsData);
    }
  }

  get createuser() {
    return this?.params?.createuser?.length && this.params.createuser.slice(0, 8);
  }
  get publisheduser() {
    return this?.params?.publisheduser?.length && this.params.publisheduser.slice(0, 8);
  }
  get periodeToString() {
    let binaryFiledataset = this?.params?.binaryFileDataset;
    if (binaryFiledataset && binaryFiledataset.from && binaryFiledataset.to) {
      return (
        "du " +
        datePatternLang(new Date(binaryFiledataset.from).toISOString(), patternDateTimeScope) +
        " au " +
        datePatternLang(new Date(binaryFiledataset.to).toISOString(), patternDateTimeScope)
      );
    }
    return "";
  }
  get datasetKey() {
    return this?.params?.binaryFileDataset?.datasetKey;
  }
}
