<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :name="vid"
    :rules="rules"
    :vid="vid"
    class="column is-12"
  >
    <b-field
      :label="label"
      :message="errors"
      :type="{
        'is-danger': errors && errors.length > 0,
        'is-success': valid,
      }"
      class="file is-primary column is-12"
      grouped
    >
      <template v-slot:label>
        <span v-if="required" class="required">{{ $t("ponctuation.star") }}</span>
        <span class="label">{{ label }}</span>
      </template>
      <b-taginput
        v-if="multiplicity === 'MANY'"
        :placeholder="checker.name === 'IntegerType' ? '4' : 4.35"
        v-model="numberValue[vid]"
        :required="required"
        type="text"
        @blur="updateValue"
        @input="updateValue"
      />
      <InputNumber
        v-else
        v-model="numberValue[vid]"
        :format="checker.type === 'FloatChecker' ? 'float' : 'integer'"
        :from="numberValue[vid]"
        :input-type="checker.type === 'FloatChecker' ? 'float' : 'integer'"
        :is-simple-value="true"
        :max="max"
        :min="min"
        @update:numberValue="updateValue"
      />
    </b-field>
  </ValidationProvider>
</template>

<script>
import { extend, ValidationProvider } from "vee-validate";
import { reactive, ref, watch } from "vue";
import InputNumber from "@/components/common/provider/InputNumber.vue";
import useNumber from "@/composable/components/number";

export default {
  name: "OreInputNumber",
  emits: ["update:value"],
  components: {
    InputNumber,
    ValidationProvider,
  },
  props: {
    checker: {
      type: Object,
      required: false,
    },
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    vid: {
      type: String,
      required: false,
    },
  },
  setup(props, ctx) {
    const val = ref(props.value);
    watch(
      () => props.value,
      (newValue) => {
        val.value = newValue;
      },
      { immediate: true }
    );

    const numberValue = reactive({});
    watch(
      () => [props.vid, val.value],
      ([vid, value]) => {
        if (typeof value === "string") {
          numberValue[vid] = parseFloat(value) || 0;
        } else {
          numberValue[vid] = value;
        }
      },
      { immediate: true }
    );

    const { refNumber: min } = useNumber(
      props.checker.min && props.checker.min !== "-Infinity" ? props.checker.min : Number(-Infinity)
    );
    const { refNumber: max } = useNumber(
      props.checker.max && props.checker.max !== "Infinity" ? props.checker.max : Number(Infinity)
    );
    function updateValue(event) {
      if (typeof event == "object") {
        event = (event.value.toString() || "").replace(",", ".");
      }
      ctx.emit("update:value", "" + (event || "").replace(",", "."));
    }
    function validateRegExp(value, type) {
      if (Array.isArray(value)) {
        return value.map((v) => this.regexp(v)).filter((v) => v === false).length === 0;
      } else {
        return type === "integer" ? regexpInteger(value) : regexpFloat(value);
      }
    }
    function validateRequired(value) {
      if (typeof value === "string") {
        return !!value;
      } else {
        return value.length > 0;
      }
    }
    function regexpInteger(value) {
      return new RegExp("^[-+]?\\d+$", "g").test(value);
    }
    function regexpFloat(value) {
      let b = new RegExp("^[+-]?([0-9]*[.,])?[0-9]+$", "g").test(value);

      return b;
    }
    return {
      val,
      numberValue,
      min,
      max,
      updateValue,
      validateRegExp,
      validateRequired,
      regexpInteger,
      regexpFloat,
    };
  },
  methods: {
    extend,
  },
  computed: {
    required: {
      get() {
        return this.checker && this.checker.required;
      },
    },
    multiplicity: {
      get() {
        return this.checker && this.checker.multiplicity === "MANY";
      },
    },
    rules: {
      get() {
        let rules = [];
        if (this.checker) {
          if (this.checker.name === "IntegerType") {
            this.extend("integer", (value) => {
              return this.validateRegExp(value, "integer") || this.$t("rules.integer");
            });
            rules.push("integer");
          }
          if (this.checker.name === "FloatType") {
            this.extend("float", (value) => {
              return this.validateRegExp(value, "float") || this.$t("rules.float");
            });
            rules.push("float");
          }
          if (this.checker.required) {
            this.extend("required", (value) => {
              return this.validateRequired(value) || this.$t("rules.required");
            });
            rules.push("required");
          }
        }
        return rules.join("|");
      },
    },
  },
};
</script>

<style scoped>
.required {
  color: rgb(255, 0, 0);
  padding-right: 5px;
  font-size: 150%;
}
</style>
