import Vue from "vue";
import App from "@/App.vue";
import router from "./router";

import Buefy from "buefy";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faBars,
  faCalendar,
  faCalendarDay,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClock,
  faDownload,
  faDraftingCompass,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkSquareAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFilter,
  faGlobe,
  faInfo,
  faInfoCircle,
  faKey,
  faLink,
  faMinus,
  faMinusSquare,
  faPaperPlane,
  faPenSquare,
  faPlus,
  faPoll,
  faQuestion,
  faQuestionCircle,
  faRedo,
  faSearch,
  faSearchPlus,
  faShare,
  faSignInAlt,
  faSignOutAlt,
  faSortAmountDown,
  faSortDown,
  faSortUp,
  faSpinner,
  faSquare,
  faStream,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUnlock,
  faUpload,
  faUserAstronaut,
  faUserEdit,
  faUserPlus,
  faUsersCog,
  faVial,
  faWrench,
  faHome,
  faToolbox,
  faCog,
  faArrowRight,
  faExchangeAlt,
  faUserShield,
  faCity,
  faArrowsAltV,
  faArrowsAltH,
  faSlidersH,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCalendar as farCalendar,
  faCalendarDays as farCalendarDays,
  faCheckSquare as farCheckSquare,
  faFloppyDisk as farFloppyDisk,
  faMinusSquare as farMinusSquare,
  faSquare as farSquare,
  faTimesCircle as farTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "@/style/global.scss";

// Translation
import { UserPreferencesService } from "./services/UserPreferencesService";
import VueI18n from "vue-i18n";
import i18n_en from "@/locales/en.json";
import i18n_fr from "@/locales/fr.json";
// Validation
import "vee-validate";
import { confirmed, email, length, max, min, required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import Register from "@/components/login/Register.vue";

library.add(
  farFloppyDisk,
  farCalendarDays,
  farCalendar,
  farSquare,
  farMinusSquare,
  farCheckSquare,
  farTimesCircle
);
library.add(
  faArrowsAltV,
  faArrowsAltH,
  faUsers,
  faSlidersH,
  faUsersCog,
  faUserShield,
  faLink,
  faCircle,
  faEye,
  faEyeSlash,
  faFile,
  faPlus,
  faMinus,
  faExclamationCircle,
  faQuestionCircle,
  faCheck,
  faCheckCircle,
  faPenSquare,
  faSquare,
  faMinusSquare,
  faCheckSquare,
  faSignOutAlt,
  faGlobe,
  faUpload,
  faFilter,
  faSearch,
  faSearchPlus,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faWrench,
  faPoll,
  faDraftingCompass,
  faCaretUp,
  faCaretDown,
  faTimes,
  faTrashAlt,
  faDownload,
  faVial,
  faCaretRight,
  faSignInAlt,
  faUserPlus,
  faUserAstronaut,
  faUsersCog,
  faUserEdit,
  faClock,
  faEnvelope,
  faUnlock,
  faInfoCircle,
  faKey,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCalendarDay,
  faPaperPlane,
  faArrowLeft,
  faExternalLinkSquareAlt,
  faCalendar,
  faRedo,
  faExchangeAlt,
  faStream,
  faSortAmountDown,
  faSortDown,
  faSortUp,
  faArchive,
  faTimesCircle,
  faEdit,
  faInfo,
  faQuestion,
  faEllipsisH,
  faSpinner,
  faExclamationTriangle,
  faShare,
  faBars,
  faHome,
  faToolbox,
  faCog,
  faCity
);
Vue.component("vue-fontawesome", FontAwesomeIcon);

Vue.use(VueI18n);
const userPreferencesService = UserPreferencesService.INSTANCE;
export const i18n = new VueI18n({
  locale: userPreferencesService.getUserPrefLocale(),
  messages: {
    en: i18n_en,
    fr: i18n_fr,
  },
});

// Ici on surcharge les messages d'erreur de vee-validate.
// Pour plus de règles :  https://logaretm.github.io/vee-validate/guide/rules.html

extend("min", {
  ...min,
  message: (field, { length }) => {
    field = i18n.t(field);
    return i18n.tc("validation.invalid-min", length, { field, length: length });
  },
});
extend("max", {
  ...max,
  message: (field, { length }) => {
    field = i18n.t(field);
    return i18n.tc("validation.invalid-max", length, { field, length: length });
  },
});
extend("length", {
  ...length,
  message: (field, { length }) => {
    field = i18n.t(field);
    return i18n.tc("validation.invalid-length", length, { field, length: length });
  },
});
extend("email", {
  ...email,
  message: (field, { _value_: email }) => {
    field = i18n.t(field);
    return i18n.t("validation.invalid-email", { field, email });
  },
});

extend("required", {
  ...required,
  message: i18n.t("validation.invalid-required"),
});

extend("confirmed", {
  ...confirmed,
  message: (field, { target: confirmedField }) => {
    field = i18n.t(field);
    confirmedField = i18n.t(confirmedField);
    return i18n.t("validation.invalid-confirmed", { field, confirmedField }).toString();
  },
});

extend("validApplicationName", {
  message: i18n.t("validation.invalid-application-name"),
  validate: (value) => {
    return value?.match("^[a-z]*$") != null;
  },
});

String.prototype.pathStartsWith = function (innerPath) {
  innerPath = innerPath || "";
  return this === innerPath || this.startsWith(innerPath + ".");
};

extend("validApplicationNameLength", {
  message: i18n.t("validation.invalid-application-name-length"),
  validate: (value) => {
    return value && value.length >= 3 && value.length <= 20;
  },
});

// extend("dateIsAfter", {
//   message: i18n.t("validation.date-not-after").toString(),
//   validate: (value, { min }: Record<string, any>) => {
//     return isAfter(value, new Date(min))
//   },
//   params: ["min"],
// })

// Buefy
Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas",
});

Vue.config.productionTip = false;
Vue.component("Register", Register);
const app = new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

export default app;
