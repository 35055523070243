<!-- SessionExpirationWarning.vue -->
<template>
  <div v-if="visible" class="session-expiration-modal">
    <div class="modal-content">
      <h3>Votre session va expirer</h3>
      <p>Votre session expirera dans {{ formattedTimeRemaining }}.</p>
      <p>Souhaitez-vous rester connecté ?</p>
      <div class="modal-actions">
        <button @click="refreshSession" class="btn-primary">Rester connecté</button>
        <button @click="logout" class="btn-secondary">Se déconnecter</button>
      </div>
      <div class="progress-bar">
        <div class="progress" :style="{ width: progressWidth + '%' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onUnmounted } from "vue";
import sessionService from "@/services/SessionService";
import services from "@/composable/services";

export default {
  setup() {
    const visible = ref(false);
    const remainingSeconds = ref(0);
    const countdownInterval = ref(null);

    const formattedTimeRemaining = computed(() => {
      const minutes = Math.floor(remainingSeconds.value / 60);
      const seconds = remainingSeconds.value % 60;
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    });

    const progressWidth = computed(() => {
      // Calculer le pourcentage de temps restant par rapport au seuil d'avertissement
      const warningThreshold = sessionService.warningThreshold / 1000;
      return (remainingSeconds.value / warningThreshold) * 100;
    });

    onUnmounted(() => {
      // Arrêter le compte à rebours
      if (countdownInterval.value) {
        clearInterval(countdownInterval.value);
      }
    });
    function showWarning(remainingSecondsValue) {
      // Réinitialiser complètement l'état précédent
      if (countdownInterval.value) {
        clearInterval(countdownInterval.value);
        countdownInterval.value = null;
      }

      visible.value = true;
      remainingSeconds.value = remainingSecondsValue;

      // Redémarrer le compteur avec les nouvelles valeurs
      countdownInterval.value = setInterval(() => {
        remainingSeconds.value = Math.max(0, remainingSeconds.value - 1);

        if (remainingSeconds.value <= 0) {
          clearInterval(countdownInterval.value);
          logout();
        }
      }, 1000);
    }

    function hideWarning() {
      visible.value = false;
      if (countdownInterval.value) {
        clearInterval(countdownInterval.value);
      }
    }

    function refreshSession() {
      sessionService.refreshSession();
    }

    function logout() {
      services.loginService.logout().finally(() => {
        visible.value = false; // Fermer la modale explicitement
        remainingSeconds.value = 0;
      });
    }

    return {
      visible,
      remainingSeconds,
      countdownInterval,
      formattedTimeRemaining,
      progressWidth,
      showWarning,
      hideWarning,
      refreshSession,
      logout,
    };
  },
};
</script>

<style scoped>
.session-expiration-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.progress-bar {
  height: 4px;
  background-color: #eee;
  margin-top: 20px;
  border-radius: 2px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #4caf50;
  transition: width 1s linear;
}

button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #4caf50;
  color: white;
}

.btn-secondary {
  background-color: #f44336;
  color: white;
}
</style>
