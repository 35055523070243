<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :name="vid"
    :rules="rules"
    :vid="vid"
    class="column is-12"
  >
    <b-field
      :label="label"
      :message="errors"
      :type="{
        'is-danger': errors && errors.length > 0,
        'is-success': valid,
      }"
      class="file is-primary column is-12"
    >
      <b-select
        v-if="references"
        :multiple="multiplicity"
        :required="required"
        :value="val"
        @blur="updateValue"
        @input="updateValue"
      >
        <option
          v-for="option in references"
          :key="option.naturalKey"
          :label="getFullName(option.naturalKey)"
          :value="option.naturalKey"
        ></option>
      </b-select>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { extend, ValidationProvider } from "vee-validate";
import { LOCAL_STORAGE_LANG } from "@/services/Fetcher";
import { provide, ref, watch } from "vue";
import { dataLoader } from "@/composable/data/dataLoader";
import services from "@/composable/services";

const defaultLanguage = localStorage.getItem(LOCAL_STORAGE_LANG);
export default {
  setup(props, ctx) {
    const val = ref(props.value);
    const loader = dataLoader(services);
    provide("reference:dataLoader", loader);
    const { getValueDisplay } = loader;
    watch(
      () => props.value,
      () => {
        val.value = ref(props.value);
      },
      { immediate: true }
    );

    function getFullName(naturalKey) {
      let currentNames = [];
      return (
        naturalKey
          .split("__")
          .map((key) => {
            currentNames.push(key);
            let currentName = currentNames.join("__");
            return props.references.find((reference) => reference.naturalKey === currentName);
          })
          .map((reference) => getValueDisplay(reference, props.references))
          .join("/") || naturalKey
      );
    }

    function updateValue(event) {
      if (typeof event == "object") {
        event = event.target.value;
      }
      ctx.emit("update:value", event);
    }

    return {
      getValueDisplay,
      getFullName,
      updateValue,
      val,
    };
  },
  name: "OreInputReference",
  emits: ["update:value"],
  components: {
    ValidationProvider,
  },
  props: {
    references: {
      type: Array,
      required: false,
    },
    checker: {
      type: Object,
      required: false,
    },
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    vid: {
      type: String,
      required: false,
    },
  },
  data: () => {
    return {
      defaultLanguage,
    };
  },
  methods: {
    extend,
  },
  computed: {
    required: {
      get() {
        return this.checker && this.checker.required;
      },
    },
    multiplicity: {
      get() {
        return this.checker && this.checker.multiplicity === "MANY";
      },
    },
    rules: {
      get() {
        let rules = [];
        if (this.checker) {
          if (this.checker.required) {
            this.extend("selected", (value) => {
              return Object.keys(value).length > 0 || this.$t("rules.required");
            });
            rules.push("selected");
          }
        }
        return rules.join("|");
      },
    },
  },
};
</script>
