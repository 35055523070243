<template>
  <ValidationObserver ref="observer-change-password" v-slot="{ valid }">
    <TextField
      v-model:value="password"
      type="password"
      label="login.pwd"
      name="change_password.password"
      place-holder="login.pwd-placeholder"
      :mandatory="true"
      @update:value="password = $event"
    />
    <text-field
      v-model:value="newPassword"
      place-holder="login.pwd-placeholder"
      :mandatory="true"
      label="login.lost-pwd.new-pwd"
      name="change_password.new_password"
      @update:value="newPassword = $event"
    />
    <text-field
      v-model:value="newPasswordConfirm"
      place-holder="login.pwd-placeholder"
      label="login.lost-pwd.new-pwd-confirm"
      name="change_password.confirm_new_password"
      rules="confirmed:change_password.new_password"
      :mandatory="true"
      @update:value="newPasswordConfirm = $event"
    />
    <b-button
      :active="valid"
      :aria-label="$t('login.aria-btn-login')"
      style="margin-bottom: 15px; float: right"
      icon-left="floppy-disk"
      icon-pack="far"
      type="is-primary"
      @click="validationPwd(password, newPassword, newPasswordConfirm)"
    >
      {{ $t("login.finish-step") }}
    </b-button>
  </ValidationObserver>
</template>

<script>
import useText from "@/composable/components/text";
import services from "@/composable/services";
import TextField from "@/components/forms/TextField.vue";
import { ValidationObserver } from "vee-validate";

export default {
  name: "EditPwd",
  components: { TextField, ValidationObserver },
  emits: ["changed-password"],
  props: {
    user: Object,
    email: String,
  },
  setup(props) {
    const password = useText().refText;
    const newPassword = useText().refText;
    const newPasswordConfirm = useText().refText;

    async function validationPwd(password, newPassword, newPasswordConfirm) {
      try {
        let response = await services.loginService.modifAcount({
          login: props.user.login,
          newPassword,
          newPasswordConfirm,
          password,
        });
        this.$emit("changed-password", response);
        resetVariables();
      } catch (e) {
        services.alertService.toastServerError(e);
      }
    }

    function resetVariables() {
      newPassword.value = "";
      newPasswordConfirm.value = "";
      password.value = "";
    }

    return {
      password,
      newPasswordConfirm,
      newPassword,
      validationPwd,
      resetVariables,
    };
  },
};
</script>

<style scoped></style>
