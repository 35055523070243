export class BinaryFileDataset {
  datatype;
  requiredAuthorizations = {};
  from;
  to;
  comment;

  constructor(datatypeOrBinaryDataset, requiredAuthorizations, from, to, comment) {
    if (datatypeOrBinaryDataset === undefined || datatypeOrBinaryDataset === null) {
      return;
    }
    if (typeof datatypeOrBinaryDataset === "string") {
      this.datatype = datatypeOrBinaryDataset;
      this.requiredAuthorizations = requiredAuthorizations || {};
      this.from = from || null;
      this.to = to || null;
      this.comment = comment || null;
    } else if (typeof datatypeOrBinaryDataset === "object") {
      // Copie des propriétés de l'objet
      this.datatype = datatypeOrBinaryDataset.datatype || null;
      this.requiredAuthorizations = datatypeOrBinaryDataset.requiredAuthorizations || {};
      this.from = datatypeOrBinaryDataset.from || null;
      this.to = datatypeOrBinaryDataset.to || null;
      this.comment = datatypeOrBinaryDataset.comment || null;
    }
  }

  get datasetKey() {
    // Création d'un objet contenant uniquement les éléments significatifs
    const keyElements = {
      // On peut inclure datatype si nécessaire dans certains contextes
      // datatype: this.datatype,
      from: this.from,
      to: this.to,
      requiredAuthorizations: Object.keys(this.requiredAuthorizations || {})
        .sort()
        .reduce((acc, scope) => {
          acc[scope] =
            this.requiredAuthorizations[scope][this.requiredAuthorizations[scope].length - 1];
          return acc;
        }, {}),
    };

    // Conversion en chaîne JSON triée pour garantir la cohérence
    return JSON.stringify(keyElements, Object.keys(keyElements).sort());
  }
}
