export function datePatternLang(date, pattern, regex) {
  if (!regex) {
    regex =
      /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]+)?([Zz]|([/+-])([01]\d|2[0-3]):?([0-5]\d)?)?/i;
  }
  let dateExec = regex.exec(date) ? new Date(regex.exec(date)[0]) : date;
  if (pattern != null) {
    if (pattern.includes("mm") || pattern.includes("HH") || pattern.includes("ss")) {
      let hour = dateExec.toLocaleString("en-US", { hour: "2-digit", hour12: false });
      let minute = (dateExec.getMinutes() < 10 ? "0" : "") + dateExec.getMinutes();
      let second = (dateExec.getSeconds() < 10 ? "0" : "") + dateExec.getSeconds();
      pattern = pattern.replace("HH", hour);
      pattern = pattern.replace("mm", minute);
      pattern = pattern.replace("ss", second);
    }
    let year = dateExec.toLocaleString("default", { year: "numeric" });
    pattern = pattern.replace("yyyy", year);
    if (pattern.includes("MM") || pattern.includes("dd")) {
      let month = dateExec.toLocaleString("default", { month: "2-digit" });
      pattern = pattern.replace("MM", month);
    } else {
      let month = dateExec.toLocaleString("default", { month: "numeric" });
      pattern = pattern.replace("M", month);
    }
    if (pattern.includes("dd")) {
      let day = dateExec.toLocaleString("default", { day: "2-digit" });
      pattern = pattern.replace("dd", day);
    } else {
      let day = dateExec.toLocaleString("default", { day: "numeric" });
      pattern = pattern.replace("d", day);
    }
    return pattern;
  } else {
    return dateExec.toLocaleDateString(localStorage.getItem("lang"));
  }
}

export function datePatternFormated(date, pattern) {
  if (pattern != null && date != null) {
    if (pattern.includes("mm") || pattern.includes("HH") || pattern.includes("ss")) {
      let hour = date.toLocaleString("en-US", { hour: "2-digit", hour12: false });
      let minute = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
      let second = (date.getSeconds() < 10 ? "0" : "") + date.getSeconds();
      pattern = pattern.replace("HH", hour);
      pattern = pattern.replace("mm", minute);
      pattern = pattern.replace("ss", second);
    }
    let year = date.toLocaleString("default", { year: "numeric" });
    pattern = pattern.replace("yyyy", year);
    if (pattern.includes("MM") || pattern.includes("dd")) {
      let month = date.toLocaleString("default", { month: "2-digit" });
      pattern = pattern.replace("MM", month);
    } else {
      let month = date.toLocaleString("default", { month: "numeric" });
      pattern = pattern.replace("M", month);
    }
    if (pattern.includes("dd")) {
      let day = date.toLocaleString("default", { day: "2-digit" });
      pattern = pattern.replace("dd", day);
    } else {
      let day = date.toLocaleString("default", { day: "numeric" });
      pattern = pattern.replace("d", day);
    }
    return pattern;
  } else {
    return new Date(date).toLocaleDateString(localStorage.getItem("lang"));
  }
}

export function formatedDateArray(arrayDate) {
  if (arrayDate === undefined || arrayDate === null || arrayDate.length === 0) {
    return {};
  }
  if (arrayDate && arrayDate.length !== 0) {
    let localDate = new Date(
      arrayDate[0],
      arrayDate[1] - 1,
      arrayDate[2],
      arrayDate[3],
      arrayDate[4],
      arrayDate[5]
    );
    if (localStorage.getItem("lang")) {
      return new Intl.DateTimeFormat(localStorage.getItem("lang"), {
        dateStyle: "medium",
        timeStyle: "short",
      }).format(localDate);
    } else if (navigator.language) {
      return new Intl.DateTimeFormat(navigator.language, {
        dateStyle: "medium",
        timeStyle: "short",
      }).format(localDate);
    }
  }
}

export function patternCheckerDateRef(application, columnName, refId) {
  if (
    application.configuration.references &&
    Object.keys(application.configuration.dataDescription[refId].validations).length !== 0
  ) {
    let validationsKeys = Object.keys(application.configuration.dataDescription[refId].validations);
    for (const item of validationsKeys) {
      let columns = application.configuration.references[refId].validations[item]?.columns;
      for (const column in columns) {
        if (columns[column] === columnName) {
          if (
            application.configuration.references[refId].validations[item].checker?.params?.pattern
          ) {
            return application.configuration.references[refId].validations[item].checker.params
              .pattern;
          }
        }
      }
    }
  } else if (
    application.configuration.dataDescription?.[refId].componentDescriptions[columnName]?.checker
      ?.pattern
  ) {
    return application.configuration.dataDescription[refId].componentDescriptions[columnName]
      .checker.pattern;
  }
}

export function parseDate(input, format) {
  format = format || "dd/MM/yyyy"; // default format
  let parts = input.match(/(\d+)/g),
    i = 0,
    fmt = {};
  format.replace(/(yyyy|dd|MM|hh|mm|ss)/g, function (part) {
    fmt[part] = i++;
  });
  if (format.includes("hh") && format.includes("mm") && format.includes("ss")) {
    return new Date(
      parts[fmt["yyyy"]],
      parts[fmt["MM"]] - 1,
      parts[fmt["dd"]],
      parts[fmt["hh"]],
      parts[fmt["mm"]],
      parts[fmt["ss"]]
    );
  } else if (format.includes("hh") && format.includes("mm")) {
    return new Date(
      parts[fmt["yyyy"]],
      parts[fmt["MM"]] - 1,
      parts[fmt["dd"]],
      parts[fmt["hh"]],
      parts[fmt["mm"]],
      0
    );
  } else if (format.includes("hh")) {
    return new Date(
      parts[fmt["yyyy"]],
      parts[fmt["MM"]] - 1,
      parts[fmt["dd"]],
      parts[fmt["hh"]],
      0,
      0
    );
  } else {
    return new Date(parts[fmt["yyyy"]], parts[fmt["MM"]] - 1, parts[fmt["dd"]], 0, 0, 0);
  }
}
