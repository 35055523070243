<script>
import { computed } from "vue";
import SummaryTableOfAuthorizationByData from "@/components/common/authorization/SummaryTableOfAuthorizationByData.vue";
import app from "@/main";
import LoadingAnimate from "@/components/common/LoadingAnimate.vue";

export default {
  name: "DetailAuthorizationModalCard",
  components: { LoadingAnimate, SummaryTableOfAuthorizationByData },
  emits: ["setValidatedCharte"],
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    authorization: {
      type: Object,
      required: true,
    },
    application: {
      type: Object,
      required: true,
    },
    listColumnName: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    let authorizationName = computed(() => {
      return props.authorization?.name || "";
    });
    let authorizationDescription = computed(() => {
      return props.authorization?.description || "";
    });
    let authorizationListAuthorizations = computed(() => {
      return props.authorization?.authorizations || [];
    });
    let authorizationId = computed(() => {
      return props.authorization?.uuid || [];
    });

    function modifyAuthorization(id) {
      app.$router.push(`/applications/${props.application.name}/authorizations/${id}`);
    }
    return {
      authorizationName,
      authorizationDescription,
      authorizationListAuthorizations,
      authorizationId,
      modifyAuthorization,
    };
  },
};
</script>

<template>
  <div class="modal-card" style="width: auto">
    <LoadingAnimate v-if="isLoading" :size="'is-large'"></LoadingAnimate>
    <div v-else>
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ authorizationName }}
        </p>
      </header>
      <div class="modal-card-body">
        <p>{{ authorizationDescription }}</p>
        <SummaryTableOfAuthorizationByData
          :application="application"
          :authorization="authorizationListAuthorizations"
          :lang="lang"
          :list-column-name="listColumnName"
          @show-more="modifyAuthorization(authorizationId)"
        >
        </SummaryTableOfAuthorizationByData>
      </div>
    </div>
  </div>
</template>
