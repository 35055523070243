export class Authorization {
  requiredAuthorizations = {};
  from = null;
  to = null;

  constructor(authorization) {
    this.from = authorization.from;
    this.to = authorization.to;
    this.requiredAuthorizations = authorization.requiredAuthorizations;
  }

  getKey() {
    return Object.keys(this.requiredAuthorizations || {})
      .sort((a, b) => a - b)
      .map((scope) => this.requiredAuthorizations[scope])
      .join("KKK");
  }

  toAuthorizationRest(auth) {
    return {
      intervalDates: {
        fromDay: auth.fromDay,
        toDay: auth.toDay,
      },
      requiredAuthorizations: auth.requiredAuthorizations,
    };
  }

  getPath(scopeId) {
    let path = [];
    for (const scopeIdKey in scopeId) {
      if (this.requiredAuthorizations[scopeId[scopeIdKey]]) {
        path.push(this.requiredAuthorizations[scopeId[scopeIdKey]]);
      }
    }
    path = path.join(".");
    return path;
  }

  getPath2(scopeId) {
    let path = [];
    for (const scopeIdKey in scopeId) {
      if (this.requiredAuthorizations[scopeId[scopeIdKey]]) {
        path.push(this.requiredAuthorizations[scopeId[scopeIdKey]].sql);
      }
    }
    path = path.reverse().join(".");
    return path;
  }

  equals(auth, scopes) {
    for (const scope in scopes) {
      if (
        this.requiredAuthorizations[scopes[scope]] !== auth.requiredAuthorizations[scopes[scope]]
      ) {
        return false;
      }
    }
    return true;
  }

  parse() {
    return {
      requiredAuthorizations: this.requiredAuthorizations,
      intervalDates: {
        fromDay: this.parseDate(this.from),
        toDay: this.parseDate(this.to),
      },
    };
  }

  parseDate(date) {
    let parsedDate = null;
    if (date) {
      parsedDate = [date.getFullYear(), date.getMonth() + 1, date.getDate()];
    }
    return parsedDate;
  }
}
