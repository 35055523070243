<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <section v-if="showEmail">
      <b-notification :aria-close-label="$t('message.close')" type="is-info is-light">
        <span v-html="$t('login.info-mail-validate-key', { email })" class="column" />
      </b-notification>
      <TextField
        rules="length:10"
        v-model:value="verificationKey"
        label="login.lost-pwd.temporary-pwd"
        name="register.certifieEmail.temporary-pwd"
        place-holder="login.verificationKey-placeholder"
        :mandatory="true"
        @update:value="verificationKey = $event"
      />
    </section>
    <section v-else>
      <TextField
        v-model:value="login"
        rules="min:4"
        label="login.login"
        name="register.login"
        place-holder="login.login-placeholder"
        :mandatory="true"
        @update:value="login = $event"
      />
      <TextField
        type="password"
        v-model:value="password"
        label="login.pwd"
        name="register.password"
        place-holder="login.pwd-placeholder"
        :mandatory="true"
        @update:value="password = $event"
      />
      <TextField
        type="password"
        rules="confirmed:register.password"
        v-model:value="confirmedPwd"
        label="login.confirm-pwd"
        name="register.confirm_password"
        place-holder="login.pwd-placeholder"
        :mandatory="true"
        @update:value="confirmedPwd = $event"
      />
      <TextField
        type="email"
        rules="email"
        v-model:value="email"
        label="login.email"
        name="register.email"
        place-holder="login.email-placeholder"
        :mandatory="true"
        @update:value="email = $event"
      />
    </section>

    <div class="buttons">
      <b-button
        aria-description="login.register"
        :aria-label="$t('login.aria-btn-signup')"
        icon-left="user-plus"
        type="is-primary"
        @click="handleSubmit(register)"
      >
        {{ $t("login.register") }}
      </b-button>
    </div>
    <b-dialog v-if="validatedAccount">
      {{ $t("register.account-active") }}
    </b-dialog>
  </ValidationObserver>
</template>

<script>
import { email as emailRules } from "vee-validate/dist/rules";
import services from "@/composable/services";
import useText from "@/composable/components/text.js";
import useBoolean from "@/composable/components/boolean";
import { onMounted, inject } from "vue";
import { ValidationObserver, extend } from "vee-validate";
import TextField from "@/components/forms/TextField.vue";
import { i18n } from "@/main";

export default {
  name: "Register",
  emits: ["user:registered"],
  components: { TextField, ValidationObserver },
  setup() {
    const login = inject("login");
    const password = useText().refText;
    const confirmedPwd = useText().refText;
    const email = inject("email");
    const verificationKey = useText().refText;
    const showEmail = useBoolean().refBoolean;
    const changeTabToSignIn = inject("changeTabToSignIn");
    const { refBoolean: validatedAccount, doChangeBoolean: changevalidatedAccount } = useBoolean();
    onMounted(() => {
      extend("email", emailRules);
    });

    async function register() {
      try {
        if (!showEmail.value) {
          await services.loginService.register(login.value, password.value, email.value);
          services.alertService.toastSuccess(i18n.t("alert.registered-user"));
          showEmail.value = true;
        } else {
          await services.loginService.modifAcount({
            login: login.value,
            password: password.value,
            verificationKey: verificationKey.value,
          });
          resetVariables();
          changevalidatedAccount(true);
          changeTabToSignIn();
        }
      } catch (error) {
        services.alertService.toastServerError(error);
      }
    }

    function resetVariables() {
      login.value = "";
      password.value = "";
      confirmedPwd.value = "";
      email.value = "";
    }

    return {
      login,
      password,
      confirmedPwd,
      email,
      verificationKey,
      register,
      resetVariables,
      showEmail,
      validatedAccount,
      changeTabToSignIn,
    };
  },
};
</script>
