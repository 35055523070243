<template>
  <div v-if="Object.keys(dataTypes).length !== 0">
    <table class="table is-bordered is-striped is-narrow is-hoverable">
      <thead>
        <tr>
          <th>{{ $t("dataTypeAuthorizations.name-data") }}</th>
          <th v-for="(column, key) in listColumnName" :key="key">
            {{ column.internationalizationName.fr }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="dataType in dataTypes" :key="dataType.id">
          <td>{{ dataType.name }}</td>
          <td v-for="(column, key) in listColumnName" :key="`${dataType.id}-${key}`">
            <b-checkbox
              :disabled="isCheckboxDisabled(dataType.id, key)"
              :title="getCheckboxTitle(dataType.id, key)"
              :value="isCheckboxChecked(dataType.id, key)"
              @input="modifyOperationType($event, dataType.id, key)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "AuthorizationsForNoAuthorization",
  emits: ["update:authorization"],
  props: {
    authorization: {
      type: Object,
      required: true,
    },
    dataTypes: {
      type: Object,
      required: true,
    },
    listColumnName: {
      type: Object,
      required: true,
    },
    hasDependencies: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const operationtypes = computed(() =>
      Object.keys(props.dataTypes).reduce((acc, datatype) => {
        acc[datatype] = {};
        const auth = props.authorization?.[datatype] || {};
        const operationTypes = auth.operationTypes || [];
        operationTypes.forEach((operationType) => {
          acc[datatype][operationType] = true;
        });
        return acc;
      }, {})
    );

    const modifyOperationType = (event, datatype, name) => {
      const authorization = { ...props.authorization };
      const localOperationtypes = new Set(authorization[datatype]?.operationTypes || []);
      if (event) {
        if (name === "delete") {
          localOperationtypes.add("depot");
          localOperationtypes.add("extraction");
        }
        localOperationtypes.add(name);
      } else {
        if (name === "delete") {
          localOperationtypes.delete("depot");
          localOperationtypes.delete("extraction");
        }
        localOperationtypes.delete(name);
      }
      authorization[datatype] = { operationTypes: Array.from(localOperationtypes) };
      emit("update:authorization", authorization);
    };

    const isCheckboxChecked = (datatypeId, key) =>
      key === "extraction" && props.hasDependencies.includes(datatypeId)
        ? true
        : operationtypes.value?.[datatypeId]?.[key];

    const isCheckboxDisabled = (datatypeId, key) =>
      key === "extraction" && props.hasDependencies.includes(datatypeId);

    const getCheckboxTitle = (datatypeId, key) =>
      isCheckboxDisabled(datatypeId, key)
        ? "Cette autorisation est induite par des dépendances et ne peut être modifiée directement."
        : "";

    return {
      operationtypes,
      modifyOperationType,
      isCheckboxChecked,
      isCheckboxDisabled,
      getCheckboxTitle,
    };
  },
};
</script>
