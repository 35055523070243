import { Fetcher } from "./Fetcher";
import { ref } from "vue";
import { Tag } from "@/model/application/Tag";

export class TagService extends Fetcher {
  static INSTANCE = new TagService();

  orderDatasTobeShownWithTag(tags, datas) {
    if (!tags || !Object.keys(tags || {}).length) {
      return datas.filter((data) => {
        data = { ...data, isInterval: true, intervalValues: {}, simpleValue: "" };
        if (data && Object.keys(data.tags).length !== 0) {
          return data.tags.some((tag) => {
            return (
              (tag.localName && tag.localName !== Tag.HIDDEN_TAG) ||
              (tag.tagDefinition && tag.tagDefinition !== Tag.HIDDEN_TAG)
            );
          });
        }
      });
    }
    let selectedTags = Object.values(tags).filter((tag) => {
      if (tag.tagDefinition === Tag.NO_TAG) {
        tag = Tag.NO_TAG_INSTANCE();
      }
      return (tag.tagDefinition === Tag.NO_TAG || tag.type === Tag.DOMAIN_TAG) && tag.selected;
    });
    if (!Object.keys(tags).length) {
      return datas;
    }
    return datas.filter((data) => {
      data = { ...data, isInterval: true, intervalValues: {}, simpleValue: "" };
      if (data) {
        return data.tags.some((tag) => {
          if (
            tag.selected !== false ||
            tag.localName !== Tag.HIDDEN_TAG ||
            tag.tagDefinition !== Tag.HIDDEN_TAG ||
            tag.type !== Tag.HIDDEN_TAG
          ) {
            if (
              data.tags.length === 1 &&
              (tag.tagDefinition === Tag.ORDER_TAG || tag.type === Tag.ORDER_TAG)
            ) {
              return true;
            } else if (
              data.tags.length === 1 &&
              (tag.tagDefinition === Tag.NO_TAG || tag.type === Tag.NO_TAG)
            ) {
              return true;
            } else {
              return selectedTags.some((selectedTag) => selectedTag.tagName === tag.tagName);
            }
          }
        });
      }
    });
  }

  toBeShown(tags, datas) {
    datas = Object.values(datas || {}).sort((a, b) => {
      if (!a.order) return 1;
      if (a.order && b.order) {
        if (a.order === b.order) return 0;
        if (a.order > b.order) return 1;
        return -1;
      }
      return -1;
    });
    if (!tags || !Object.keys(tags || {}).length) {
      return datas;
    }
    return this.orderDatasTobeShownWithTag(tags, datas);
  }
  toBeShownDataColumns(tags, datas) {
    datas = Object.values(datas || {}).sort((a, b) => {
      if (!a.order) return -1;
      if (a.order && b.order) {
        if (a.order === b.order) return 0;
        if (a.order > b.order) return -1;
        return 1;
      }
      return 1;
    });
    return this.orderDatasTobeShownWithTag(tags, datas);
  }

  currentTags(tags, currentTags) {
    for (const tagName of currentTags) {
      if (tagName !== Tag.HIDDEN_TAG) {
        if (tags[tagName]) {
          continue;
        }
        tags[tagName] = ref({});
        tags[tagName].selected = true;
      }
    }
    return tags;
  }

  sizeCollapseTags(tags) {
    const tagCollapse = document.getElementById("tagsCollapse");
    if (!tagCollapse) {
      return;
    }
    if (Object.keys(tags).length <= 4) {
      document.getElementById("tagsCollapse").className = "column is-offset-one-third is-one-third";
    } else if (Object.keys(tags).length <= 8) {
      document.getElementById("tagsCollapse").className =
        "column is-offset-one-fifth is-three-fifths";
    } else {
      document.getElementById("tagsCollapse").className = "column";
    }
  }
}
