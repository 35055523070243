<script>
import useDate from "@/composable/components/date";
import { watch } from "vue";

export default {
  name: "InputDateInterval",
  components: {},
  emits: ["update:date-value-interval", "update:sizeHeight"],
  props: {
    inputType: String,
    format: String,
    from: {
      type: Date,
      default: () => null,
    },
    to: {
      type: Date,
      default: () => null,
    },
    modelValue: Object,
    hasExtraction: Boolean,
  },
  setup(props, ctx) {
    const { refDate: fromValueDate } = useDate(props.from);
    const { refDate: toValueDate } = useDate(props.to);
    watch(
      () => props.from,
      (value) => {
        fromValueDate.value = value;
      }
    );
    watch(
      () => props.to,
      (value) => {
        toValueDate.value = value;
      }
    );
    const local = getLocal();

    function getLocal() {
      if (props.format === "yyyy-MM-dd") {
        return "fr-CA";
      } else if (props.format === "MM/dd/yyyy") {
        return "en-US";
      } else if (props.format === "yyyy/MM/dd") {
        return "ja-JP";
      } else if (props.format === "dd.MM.yyyy") {
        return "ru-RU";
      } else {
        return "fr-FR";
      }
    }

    function dateToArray(date) {
      return [
        date.getFullYear(),
        date.getMonth() + 1, // Les mois commencent à 0 en JavaScript
        date.getDate() /*,
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()*/,
      ];
    }

    function updateValueDate(event) {
      if (typeof event === "number") {
        ctx.emit("update:sizeHeight", event);
      }
      const isInfiniteOrNaN = (value) =>
        value === -999999999 || value === 999999999 || isNaN(value) || value === null;

      const isInfiniteFrom = isInfiniteOrNaN(fromValueDate.value);
      const isInfiniteTo = isInfiniteOrNaN(toValueDate.value);

      if (!isInfiniteFrom || !isInfiniteTo) {
        const interval = {};
        const intervalAsDatesArray = {};

        if (!isInfiniteFrom) {
          const fromDate = new Date(fromValueDate.value);
          if (!isNaN(fromDate.getTime())) {
            interval.from = fromDate.getTime();
            intervalAsDatesArray.from = dateToArray(fromDate);
          }
        }

        if (!isInfiniteTo) {
          const toDate = new Date(toValueDate.value);
          if (!isNaN(toDate.getTime())) {
            interval.to = toDate.getTime();
            intervalAsDatesArray.to = dateToArray(toDate);
          }
        }

        // Vérifier si les deux dates sont définies et valides, et si 'from' est avant 'to'
        if (
          !isInfiniteFrom &&
          !isInfiniteTo &&
          interval.from &&
          interval.to &&
          interval.from > interval.to
        ) {
          console.warn("La date 'from' est après la date 'to'. L'intervalle n'est pas valide.");
          return; // Ou gérer cette situation d'une autre manière appropriée
        }

        ctx.emit("update:date-value-interval", {
          interval,
          intervalAsDatesArray,
          locale: local,
        });
      }
    }
    return {
      fromValueDate,
      toValueDate,
      updateValueDate,
      getLocal,
      local,
    };
  },
};
</script>

<template>
  <div class="rows">
    <div class="row">
      <span class="label">{{ $t("dataTypeAuthorizations.from") }}</span>
    </div>
    <div v-if="inputType === 'datetime'" class="row">
      <b-datetimepicker
        v-model="fromValueDate"
        :locale="local"
        :placeholder="format"
        editable
        expanded
        icon="calendar-day"
        @blur="updateValueDate(fromValueDate)"
      >
      </b-datetimepicker>
    </div>
    <div v-else-if="inputType === 'date'" class="row">
      <b-datepicker
        v-model="fromValueDate"
        :locale="local"
        :placeholder="format"
        editable
        expanded
        icon="calendar-day"
        @blur="updateValueDate(fromValueDate)"
      >
      </b-datepicker>
    </div>
    <div v-else-if="inputType === 'time'" class="row">
      <b-timepicker
        v-model="fromValueDate"
        :date-formatter="format"
        :locale="local"
        :placeholder="format"
        editable
        expanded
        icon="clock"
        @blur="updateValueDate"
      >
      </b-timepicker>
    </div>
    <div class="row">
      <span class="label">{{ $t("dataTypeAuthorizations.to") }}</span>
    </div>
    <div v-if="inputType === 'datetime'" class="row">
      <b-datetimepicker
        v-model="toValueDate"
        :locale="local"
        :placeholder="format"
        editable
        expanded
        icon="calendar-day"
        @blur="updateValueDate(toValueDate)"
      >
      </b-datetimepicker>
    </div>
    <div v-else-if="inputType === 'date'" class="row">
      <b-datepicker
        v-model="toValueDate"
        :locale="local"
        :placeholder="format"
        editable
        expanded
        icon="calendar-day"
        @blur="updateValueDate(toValueDate)"
      >
      </b-datepicker>
    </div>
    <div v-else-if="inputType === 'time'" class="row">
      <b-timepicker
        v-model="toValueDate"
        :date-formatter="format"
        :locale="local"
        :placeholder="format"
        editable
        expanded
        icon="clock"
        @blur="updateValueDate(toValueDate)"
      >
      </b-timepicker>
    </div>
  </div>
</template>
