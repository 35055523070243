import { Fetcher } from "../Fetcher";
import { InternationalisationService } from "@/services/InternationalisationService";
import app from "@/main";

export class ApplicationService extends Fetcher {
  static INSTANCE = new ApplicationService();

  constructor() {
    super();
  }

  async validateConfiguration(method, applicationConfig) {
    return this.postChunck(method, "validate-configuration", {
      file: applicationConfig.file,
    });
  }

  async createApplication(method, applicationConfig, comment) {
    return this.postChunck(method, "applications/" + applicationConfig.name, {
      file: applicationConfig.file,
      comment: comment,
    });
  }

  async getApplications(method, filter) {
    this.getChunck(method, "applications", { filter });
  }

  log(k) {
    console.log(k);
  }

  async getApplication(name, filter) {
    try {
      const application = await this.get("applications/" + name, { filter });
      return InternationalisationService.INSTANCE.mergeInternationalization(application);
    } catch (e) {
      console.log("getApplication", e);
      app.$router.push("/applications");
    }
  }
  async changeConfiguration(method, applicationConfig, comment) {
    return this.postChunck(method, "applications/" + applicationConfig.name + "/configuration", {
      file: applicationConfig.file,
      comment: comment,
    });
  }

  async getValidateConfiguration() {
    return this.post("validate-configuration");
  }

  async downloadApplicationUploadBundle(applicationName, withData) {
    return this.downloadFile(
      `applications/${applicationName}/upload-bundle?withData=${withData}&locale=${this.getUserPrefLocale()}`
    );
  }
}
