<template>
  <PageView class="with-submenu">
    <SubMenu
      :aria-label="$t('menu.aria-sub-menu')"
      :paths="subMenuPaths"
      :root="currentUser.login"
      role="navigation"
    />
    <h1 class="title main-title">
      {{ $t("updateUser.titles", { userName: currentUser.login }) }}
    </h1>
    <div class="card UserView-card">
      <b-tabs v-model="selectedTab" :animated="false" type="is-boxed">
        <b-tab-item :label="currentUser.login" icon="user-astronaut" tabindex="0">
          <div>
            <b-table :data="user.data">
              <b-table-column
                v-for="column in user.columns"
                :key="column.id"
                v-slot="props"
                :label="column.label"
              >
                <span>
                  <p v-if="props.row[column.field] === 'id'">{{ $t("updateUser.columns.id") }}</p>
                  <p v-else-if="props.row[column.field] === 'login'">
                    {{ $t("updateUser.columns.login") }}
                  </p>
                  <p v-else-if="props.row[column.field] === 'email'">
                    {{ $t("updateUser.columns.email") }}
                  </p>
                  <p v-else-if="props.row[column.field] === 'state'">
                    {{ $t("updateUser.columns.state") }}
                  </p>
                  <p v-else-if="props.row[column.field] === 'authorizedForApplicationCreation'">
                    {{ $t("updateUser.columns.authorizedForApplicationCreation") }}
                  </p>
                  <p v-else-if="props.row[column.field] === 'openAdomAdmin'">
                    {{ $t("updateUser.columns.openAdomAdmin") }}
                  </p>
                  <p v-else-if="props.row[column.field] === 'authorizations'">
                    {{ $t("updateUser.columns.authorizations") }}
                  </p>
                  <p v-else>{{ props.row[column.field] }}</p>
                </span>
              </b-table-column>
            </b-table>
          </div>
        </b-tab-item>
        <b-tab-item :label="$t('updateUser.update-email')" icon="envelope" tabindex="0">
          <EditEmail :email="currentUser.email" :user="currentUser" @changed-email="emailChanged">
          </EditEmail>
        </b-tab-item>
        <b-tab-item :label="$t('updateUser.update-pwd')" icon="unlock" tabindex="0">
          <EditPwd :user="currentUser" @changed-password="passwordChanged"> </EditPwd>
        </b-tab-item>
      </b-tabs>
    </div>
  </PageView>
</template>

<script>
import PageView from "@/views/common/PageView.vue";
import SubMenu, { SubMenuPath } from "@/components/common/SubMenu.vue";
import EditEmail from "@/components/common/users/EditEmail.vue";
import EditPwd from "@/components/common/users/EditPwd.vue";
import services from "@/composable/services";
import useArray from "@/composable/components/array";
import useObject from "@/composable/components/object";
import { onMounted } from "vue";
import { i18n } from "@/main";
import useNumber from "@/composable/components/number";

export default {
  components: { EditPwd, EditEmail, PageView, SubMenu },
  props: {
    userIdOrLogin: {
      type: String,
      required: true,
    },
  },
  setup(_, ctx) {
    const selectedTab = useNumber().refNumber;
    const { shallowRefArray: subMenuPaths, doChangeArray: changesubMenuPath } = useArray();
    const currentUser = JSON.parse(localStorage.getItem("authenticatedUser"));
    const { reactiveObject: user, doChangeObject: createUser } = useObject();

    function changeTabToSignIn() {
      selectedTab.value = 0;
    }
    function emailChanged(event) {
      changeTabToSignIn();
      currentUser.email = event.email;
      currentUser.state = event.accountState;
      services.loginService.setAuthenticateduser(event);
      init();
      services.alertService.toastSuccess(i18n.t("alert.user-email-updated"));
    }
    function passwordChanged() {
      changeTabToSignIn();
      services.alertService.toastSuccess(i18n.t("alert.user-pwd-updated"));
    }

    onMounted(async () => {
      changesubMenuPath([
        new SubMenuPath(
          i18n.t("login.profil").toLowerCase(),
          () => {},
          () => ctx.$router.push(`/applications`)
        ),
      ]);
      await init();
    });

    async function init() {
      try {
        const data = Object.entries(currentUser)
          .map((entry) => ({ colonne: entry[0], valeur: entry[1] }))
          .reduce((acc, entry) => {
            acc.push(entry);
            return acc;
          }, []);
        const result = {
          data: data,
          columns: [
            {
              field: "colonne",
              label: "Colonne",
            },
            {
              field: "valeur",
              label: "Valeur",
            },
          ],
        };
        createUser(result);
      } catch (error) {
        changeTabToSignIn();
        services.alertService.toastServerError(error);
      }
    }

    return {
      selectedTab,
      subMenuPaths,
      user,
      currentUser,

      changeTabToSignIn,
      emailChanged,
      passwordChanged,
    };
  },
};
</script>
