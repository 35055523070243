var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.localeInitialized &&
      ((_vm.localeReferences.withoutScope &&
        Object.keys(_vm.localeReferences.withoutScope).length !== 0) ||
        (_vm.localeReferences.withScope && Object.keys(_vm.localeReferences.withScope).length !== 0))
    )?_c('b-collapse',{staticClass:"card",staticStyle:{"box-shadow":"none"},attrs:{"open":_vm.openReference,"animation":"slide","aria-id":"collapseReferenceSection"},scopedSlots:_vm._u([{key:"trigger",fn:function(detailReference){return [_c('div',{staticClass:"card-header",staticStyle:{"box-shadow":"none","border-bottom":"thick double #009d9d"},attrs:{"aria-expanded":!detailReference.open,"aria-controls":"collapseReferenceSection"}},[_c('p',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t("applications.references"))+" ")]),_c('a',{staticClass:"card-header-icon",attrs:{"tabindex":_vm.localeInitialized &&
            ((_vm.localeReferences.withoutScope &&
              Object.keys(_vm.localeReferences.withoutScope).length !== 0) ||
              (_vm.localeReferences.withScope &&
                Object.keys(_vm.localeReferences.withScope).length !== 0))
              ? 0
              : -1},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.openReference = !_vm.openReference},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;_vm.openReference = !_vm.openReference}]}},[_c('b-icon',{attrs:{"icon":detailReference.open ? 'chevron-up' : 'chevron-down',"type":"is-primary"}})],1)])]}}],null,false,144908143)},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[(
            _vm.localeInitialized &&
            _vm.localeReferences.withoutScope &&
            Object.keys(_vm.localeReferences.withoutScope).length !== 0
          )?_c('AuthorizationsForNoAuthorization',{attrs:{"application":_vm.localeApplication,"authorization":_vm.localeCurrentAuthorization.authorizations
              ? _vm.localeCurrentAuthorization.authorizations
              : _vm.localeAuthorizations,"data-types":_vm.localeReferences.withoutScope,"has-dependencies":_vm.localeHasDependencies,"list-column-name":_vm.localeListColumnName['withoutScope']},on:{"update:authorization":function($event){return _vm.$emit('update:authorization', $event)}}}):_vm._e(),(
            _vm.localeInitialized &&
            _vm.localeReferences.withScope &&
            Object.keys(_vm.localeReferences.withScope).length !== 0
          )?_c('AuthorizationForScope',{attrs:{"application":_vm.localeApplication,"authorization":_vm.localeCurrentAuthorization.authorizations
              ? _vm.localeCurrentAuthorization.authorizations
              : _vm.localeAuthorizations,"authorizations-description":_vm.localeAuthorizations,"data-types":_vm.datatypes.withScope,"has-dependencies":_vm.localeHasDependencies,"list-column-name":_vm.localeListColumnName['withScope'],"references-scopes":_vm.localeReferencesScopes},on:{"update:authorization":function($event){return _vm.$emit('update:authorization', $event)}}}):_vm._e()],1)])]):_vm._e(),(
      _vm.localeInitialized &&
      ((_vm.localeDatatypes.withoutScope && Object.keys(_vm.localeDatatypes.withoutScope).length !== 0) ||
        (_vm.localeDatatypes.withScope && Object.keys(_vm.localeDatatypes.withScope).length !== 0))
    )?_c('b-collapse',{staticClass:"card",staticStyle:{"box-shadow":"none"},attrs:{"open":_vm.openDatatype,"animation":"slide","aria-id":"collapseDatatypeSection"},scopedSlots:_vm._u([{key:"trigger",fn:function(detailDatatype){return [_c('div',{staticClass:"card-header",staticStyle:{"box-shadow":"none","border-bottom":"thick double #009d9d"},attrs:{"aria-expanded":!detailDatatype.open,"aria-controls":"collapseDatatypeSection"}},[_c('p',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t("applications.dataset"))+" ")]),_c('a',{staticClass:"card-header-icon",attrs:{"tabindex":_vm.localeInitialized &&
            ((_vm.localeDatatypes.withoutScope &&
              Object.keys(_vm.localeDatatypes.withoutScope).length !== 0) ||
              (_vm.localeDatatypes.withScope && Object.keys(_vm.localeDatatypes.withScope).length !== 0))
              ? 0
              : -1},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.openDatatype = !_vm.openDatatype},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;_vm.openDatatype = !_vm.openDatatype}]}},[_c('b-icon',{attrs:{"icon":detailDatatype.open ? 'chevron-up' : 'chevron-down',"type":"is-primary"}})],1)])]}}],null,false,2042599951)},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[(
            _vm.localeInitialized &&
            _vm.localeDatatypes.withoutScope &&
            Object.keys(_vm.localeDatatypes.withoutScope).length !== 0
          )?_c('AuthorizationsForNoAuthorization',{attrs:{"application":_vm.localeApplication,"authorization":_vm.localeCurrentAuthorization.authorizations
              ? _vm.localeCurrentAuthorization.authorizations
              : _vm.localeAuthorizations,"data-types":_vm.localeDatatypes.withoutScope,"has-dependencies":_vm.localeHasDependencies,"list-column-name":_vm.localeListColumnName['withoutScope']},on:{"update:authorization":function($event){return _vm.$emit('update:authorization', $event)}}}):_vm._e(),(
            _vm.localeInitialized &&
            _vm.localeDatatypes.withScope &&
            Object.keys(_vm.localeDatatypes.withScope).length !== 0
          )?_c('AuthorizationForScope',{attrs:{"application":_vm.localeApplication,"authorization":_vm.localeCurrentAuthorization.authorizations
              ? _vm.localeCurrentAuthorization.authorizations
              : _vm.localeAuthorizations,"authorizations-description":_vm.localeAuthorizations,"data-types":_vm.localeDatatypes.withScope,"has-dependencies":_vm.localeHasDependencies,"list-column-name":_vm.localeListColumnName['withScope'],"references-scopes":_vm.localeReferencesScopes},on:{"update:authorization":function($event){return _vm.$emit('update:authorization', $event)}}}):_vm._e()],1)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }