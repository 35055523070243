<script>
import { computed, onUpdated, ref } from "vue";
import InputDateInterval from "@/components/common/provider/InputDateInterval.vue";
import CollapseMenuMultiselect from "@/components/common/CollapseMenuMultiselect.vue";
import services from "@/composable/services";
import { ReferenceScope } from "@/model/authorization/ReferenceScope";

export default {
  name: "AuthorizationTableForOneDataType",
  computed: {
    ReferenceScope() {
      return ReferenceScope;
    },
  },
  emits: [
    "update:propagate-changes",
    "update:date-value-interval",
    "update:operation-type",
    "update:has-extraction",
  ],
  components: { CollapseMenuMultiselect, InputDateInterval },
  props: {
    authorization: Object,
    listColumnName: Object,
    data: Object,
    dataName: String,
    application: Object,
    applicationName: String,
    withTimeScope: Boolean,
    typeTimeScope: String,
    referenceScope: Array,
    hasDependencies: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const dropdownStates = ref([]);
    let localOperationtypes = new Set([]);
    const operationtypes = computed(
      () => props.authorization?.operationTypes || Array.from(localOperationtypes)
    );
    const selectedValuesReference = computed(() =>
      props.authorization?.requiredAuthorizations ? props.authorization?.requiredAuthorizations : {}
    );
    const locale = services.internationalisationService.getLocale();

    const isCheckboxChecked = (datatypeId, key) => {
      if ("extraction" && props.hasDependencies.includes(datatypeId)) {
        key = true;
      } else if (operationtypes.value) {
        key = operationtypes.value.includes(key) || Array.from(localOperationtypes).includes(key);
      } else {
        key = false;
      }
      return key;
    };

    const isCheckboxDisabled = (datatypeId, key) =>
      key === "extraction" && props.hasDependencies.includes(datatypeId);

    const dateFromArray = (arrayDate) => {
      if (arrayDate && (arrayDate[0] === -999999999 || arrayDate[0] === 999999999)) {
        return null;
      }
      if (arrayDate) {
        let date = new Date(arrayDate[0], arrayDate[1] - 1, arrayDate[2]);
        return date;
      }
    };
    const getFromDate = computed(() => dateFromArray(props.authorization?.fromDay));
    const getToDate = computed(() => dateFromArray(props.authorization?.toDay));
    const hasExtraction = computed(
      () =>
        props.authorization?.operationTypes &&
        ((props.authorization?.operationTypes.has &&
          props.authorization?.operationTypes.has("extraction")) ||
          (props.authorization?.operationTypes.includes &&
            props.authorization?.operationTypes.includes("extraction")))
    );
    const localHasExtraction = ref(false);
    const modifyOperationType = (event, name) => {
      localOperationtypes = new Set(operationtypes.value || []);
      if (event) {
        if (name === "delete") {
          localOperationtypes.add("depot");
          localOperationtypes.add("publication");
          localOperationtypes.add("extraction");
        } else if (name === "publication") {
          localOperationtypes.add("depot");
          localOperationtypes.add("delete");
          localOperationtypes.add("extraction");
        }
        localOperationtypes.add(name);
      } else {
        if (name === "delete") {
          localOperationtypes.delete("depot");
          localOperationtypes.delete("publication");
          localOperationtypes.delete("extraction");
        } else if (name === "publication") {
          localOperationtypes.delete("depot");
          localOperationtypes.delete("delete");
          localOperationtypes.delete("extraction");
        }
        localOperationtypes.delete(name);
      }
      context.emit("update:operation-type", Array.from(localOperationtypes));
    };

    onUpdated(() => {
      if (props.authorization && props.authorization.operationTypes) {
        localHasExtraction.value =
          (Array.isArray(props.authorization.operationTypes)
            ? props.authorization.operationTypes.includes("extraction")
            : props.authorization.operationTypes.has("extraction")) &&
          Object.keys(props.authorization).includes("timeScope");
        context.emit("update:has-extraction", localHasExtraction.value);
        localOperationtypes = props.authorization.operationTypes || Array.from(localOperationtypes);
      }
    });

    const displayNodeTagHierarchical = ref({});

    function displayNodeTag(referenceScopeNode, hierarchicalTagName) {
      if (referenceScopeNode.id === hierarchicalTagName) {
        if (referenceScopeNode.i18n) {
          displayNodeTagHierarchical.value[hierarchicalTagName] = referenceScopeNode.i18n[locale];
          return referenceScopeNode.i18n[locale];
        } else {
          displayNodeTagHierarchical.value[hierarchicalTagName] = referenceScopeNode.naturalKey;
          return referenceScopeNode.naturalKey;
        }
      } else {
        for (let i = 0; referenceScopeNode.children.length > i; i++) {
          displayNodeTag(referenceScopeNode.children[i], hierarchicalTagName);
        }
      }
    }

    function rootReferenceScopeSelected(hierarchicalTagName, referenceScopesByDataId) {
      const reference = props.referenceScope.find(
        (ref) => ref.datatype === props.dataName && ref.id === referenceScopesByDataId
      );

      if (reference) {
        const node = reference.nodes.find((n) => n.id === hierarchicalTagName);

        if (node) {
          return node.i18n ? node.i18n[locale] : node.naturalKey;
        }

        // Si aucun nœud ne correspond, on affiche les autres nœuds
        reference.nodes.forEach((n) => displayNodeTag(n, hierarchicalTagName));
      }

      return (
        displayNodeTagHierarchical.value[hierarchicalTagName] ||
        hierarchicalTagName.split("K").pop()
      );
    }

    return {
      selectedValuesReference,
      locale,
      getFromDate,
      getToDate,
      modifyOperationType,
      hasExtraction,
      localHasExtraction,
      rootReferenceScopeSelected,
      displayNodeTagHierarchical,
      isCheckboxChecked,
      isCheckboxDisabled,
      dropdownStates,
    };
  },
};
</script>

<template>
  <div>
    <div class="columns">
      <table class="table is-bordered is-striped is-narrow is-hoverable">
        <thead>
          <tr>
            <th
              v-for="(column, key) in listColumnName"
              :key="dataName + '___' + key"
              :label="column.internationalizationName.fr"
            >
              {{ column.internationalizationName.fr }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(column, key) in listColumnName" :key="dataName + '___' + key">
              <b-checkbox
                :disabled="isCheckboxDisabled(data.id, key)"
                :value="isCheckboxChecked(data.id, key)"
                @input="modifyOperationType($event, key)"
              >
              </b-checkbox>
            </td>
          </tr>
        </tbody>
      </table>
      <InputDateInterval
        v-if="withTimeScope && (hasExtraction || localHasExtraction)"
        :format="'dd/MM/yyyy HH:mm:ss'"
        :from="getFromDate"
        :has-extraction="hasExtraction || localHasExtraction"
        :input-type="typeTimeScope"
        :to="getToDate"
        class="column is-3"
        @update:date-value-interval="
          $emit('update:date-value-interval', $event.intervalAsDatesArray)
        "
      >
      </InputDateInterval>
    </div>
    <div class="columns">
      <b-field
        v-for="referenceScopesByData in referenceScope"
        :key="referenceScopesByData.id"
        :label="referenceScopesByData.i18n[locale] || referenceScopesByData.id"
        class="column is-3"
      >
        <b-dropdown
          :ref="referenceScopesByData.id"
          :triggers="['focus']"
          expanded
          max-height="500"
          open-on-focus
          scrollable
          width="500"
        >
          <template #trigger="{ active }">
            <b-taginput
              v-model="selectedValuesReference[referenceScopesByData.id]"
              :icon-right="active ? 'angle-up' : 'angle-down'"
              placeholder="Select some tags"
            >
              <template #selected="props">
                <b-tag
                  v-for="(tag, index) in props.tags"
                  :id="referenceScopesByData.id + '-_-' + tag"
                  :key="index"
                  rounded
                  type="is-dark"
                >
                  {{ rootReferenceScopeSelected(tag, referenceScopesByData.id) }}
                </b-tag>
              </template>
            </b-taginput>
          </template>
          <CollapseMenuMultiselect
            v-for="option in referenceScopesByData.nodes"
            :id="option.id"
            :key="option.id"
            :datatype="data.id"
            :is-checked="true"
            :option="option"
            :reference-scope="
              new ReferenceScope(
                referenceScopesByData,
                authorization?.requiredAuthorizations?.[referenceScopesByData.id]
              )
            "
            :required-authorizations="
              authorization?.requiredAuthorizations?.[referenceScopesByData.id]
            "
            :scope="referenceScopesByData.id"
            @select-menu-item="
              $emit('update:info-auth-ref-scope', { ...$event, datatype: data.id })
            "
            @propagate-changes="
              $emit('update:propagate-changes', {
                changes: $event,
                datatype: data.id,
                scope: referenceScopesByData.id,
              })
            "
          ></CollapseMenuMultiselect>
        </b-dropdown>
      </b-field>
    </div>
  </div>
</template>
