<script>
import { useRedirections } from "@/composable/applications/useFunction";
import useText from "@/composable/components/text";
import { computed, /*inject,*/ onMounted, ref } from "vue";
import services from "@/composable/services";
import PageView from "@/views/common/PageView.vue";
import SubMenu, { SubMenuPath } from "@/components/common/SubMenu.vue";
import useArray from "@/composable/components/array";
import app, { i18n } from "@/main";
import useObject from "@/composable/components/object";
import useBoolean from "@/composable/components/boolean";
import DetailApplicationModalCard from "@/components/application/DetailApplicationModalCard.vue";
import { formatedDateArray } from "../../composable/application/datePattern";
import { ApplicationResult } from "@/model/ApplicationResult";
import { User } from "@/model/User";

export default {
  name: "ApplicationInfoView",
  methods: { formatedDateArray },
  components: { DetailApplicationModalCard, SubMenu, PageView },
  emits: ["setValidatedCharte"],
  props: {
    applicationName: {
      type: String,
    },
  },
  setup(props, ctx) {
    const isCardModalActive = useBoolean().refBoolean;
    const url = useText().refText;
    const redirection = ref(() => {
      app.$router.push(`/applications/${props.applicationName}`);
    });
    const showCharte = function (objectShowCharte) {
      if (!objectShowCharte) {
        return;
      } else {
        isCardModalActive.value = true;
        url.value = objectShowCharte.url;
        redirection.value = objectShowCharte.redirection;
      }
    };
    const validated = computed({
      get() {
        let chartestimeStamp = User.STORED_AUTHENTICATED_USER()?.chartes?.[props.application.id];
        if (chartestimeStamp) {
          return new Date().getTime() > chartestimeStamp;
        }
        return false;
      },
      set(bool) {
        bool &&
          services.loginService.modifAcount({
            login: User.STORED_AUTHENTICATED_USER()?.login,
            email: User.STORED_AUTHENTICATED_USER()?.email,
            charte: props.application.id,
          });
        bool &&
          ctx.emit("setValidatedCharte", {
            applicationId: application.id,
            validated: bool,
          });
      },
    });
    const { reactiveObject: application, doChangeObject: changeApplication } = useObject(
      new ApplicationResult()
    );
    const { refBoolean: canCreateApplication, doChangeBoolean: changeCanCreateApplication } =
      useBoolean(false);
    const { refBoolean: canManagerApplication, doChangeBoolean: changeCanManagerApplication } =
      useBoolean(false);
    const { refBoolean: canManagerUser, doChangeBoolean: changeCanManagerUser } = useBoolean(false);
    const { shallowRefArray: subMenuPaths, doChangeArray: changeSubMenuPaths } = useArray();
    const {
      createApplication,
      updateApplication,
      downloadApplicationUploadBundle,
      downloadApplicationUploadBundleWithData,
      downloadYamlApplication,
      downloadCharteApplication,
      uploadCharteApplication,
      tmpCharte,
      displayReferencesManagement,
      showReferenceRights,
      displayDataSetManagement,
      showDataRights,
      displayAdditionalFilesManagement,
      showAdditionalFilesManagementRights,
      requestRights,
      authorizationsManagementForApplicationUsers,
      showRequestRights,
    } = useRedirections(application);
    const canReadReferences = computed(() =>
      Object.keys(application.references).filter(
        (reference) => application?.authorizations?.[reference]?.READ
      )
    );
    const canReadDataTypes = computed(() =>
      Object.keys(application.dataTypes).filter(
        (dataType) => application?.authorizations?.[dataType]?.READ
      )
    );

    onMounted(async () => {
      await init();
      changeSubMenuPaths([
        new SubMenuPath(
          "Accueil",
          () => app.$router.push(`/applications/${props.applicationName}`),
          () => {}
        ),
      ]);
    });

    async function init() {
      try {
        changeApplication(
          new ApplicationResult(
            await services.applicationService.getApplication(props.applicationName, [
              "DATATYPE",
              "CONFIGURATION",
              "REFERENCETYPE",
              "ADDITIONALFILE",
            ])
          )
        );
        changeApplication({
          ...services.internationalisationService.mergeInternationalization(application),
          localRefName: services.internationalisationService.localeReferenceNames(
            props.applicationName,
            application
          ),
        });
      } catch (e) {
        services.alertService.toastError(
          i18n.t("alert.server-error-appli-no-exist", {
            name: props.applicationName,
          })
        );
      }
      try {
        changeCanCreateApplication(
          application?.currentApplicationUserRolesResult?.isApplicationCreator
            ? application?.currentApplicationUserRolesResult?.isApplicationCreator
            : application?.currentApplicationUserRolesResult?.isOpenAdomAdmin
        );
        changeCanManagerApplication(
          application?.currentApplicationUserRolesResult?.applicationRoles?.includes(
            "applicationManager"
          )
        );
        changeCanManagerUser(
          application?.currentApplicationUserRolesResult?.applicationRoles?.includes("userManager")
        );
      } catch (error) {
        console.log("missing admin application rights", error);
      }
    }

    return {
      canReadReferences,
      canReadDataTypes,
      createApplication,
      updateApplication,
      downloadApplicationUploadBundle,
      downloadApplicationUploadBundleWithData,
      downloadYamlApplication,
      downloadCharteApplication,
      uploadCharteApplication,
      tmpCharte,
      validated,
      application,
      displayReferencesManagement,
      showReferenceRights,
      displayDataSetManagement,
      showDataRights,
      requestRights,
      authorizationsManagementForApplicationUsers,
      showRequestRights,
      displayAdditionalFilesManagement,
      showAdditionalFilesManagementRights,
      subMenuPaths,
      canCreateApplication,
      showCharte,
      isCardModalActive,
      canManagerUser,
      canManagerApplication,
      url,
      redirection,
    };
  },
};
</script>

<template>
  <PageView :application="application" class="with-submenu">
    <SubMenu
      :aria-label="$t('menu.aria-sub-menu')"
      :paths="subMenuPaths"
      :is-accueil="true"
      :root="application.localName || applicationName"
      role="navigation"
    />
    <h1 class="title main-title">{{ application.localName || applicationName }}</h1>
    <b-modal
      v-if="isCardModalActive"
      v-model="isCardModalActive"
      class="modalByAgrégation"
      full-screen
    >
      <DetailApplicationModalCard
        :id="application.name"
        v-model="isCardModalActive"
        :application="application"
        :open="isCardModalActive"
        :url="url"
        :redirection="redirection"
        :close-cb="redirection"
      >
      </DetailApplicationModalCard>
    </b-modal>
    <div v-else>
      <div class="infos">
        <p
          v-html="
            $t('applications.version', {
              applicationName: application.localName,
              version: application?.configuration?.applicationDescription?.version?.version,
            })
          "
        />
      </div>
      <div v-if="application.creationDate" class="infos">
        <p field="creationDate">
          {{ $t("applications.creation-date") }}: {{ formatedDateArray(application.creationDate) }}
        </p>
        <p
          v-if="
            application.creationDate &&
            application.updateDate &&
            formatedDateArray(application.creationDate) !==
              formatedDateArray(application.updateDate)
          "
          field="updateDate"
        >
          {{ $t("applications.update-date") }}: {{ formatedDateArray(application.updateDate) }}
        </p>
      </div>
      <div class="infos">
        <p>
          <span :class="application.comment ? 'has-text-primary' : 'has-text-warning'">
            {{ application.comment ? $t("applications.comment") : $t("applications.no-comment") }}
          </span>
          <span class="comment">{{ application.comment }}</span>
        </p>
        <p v-if="application.localDescription">
          <span class="has-text-primary">
            {{ $t("applications.description", { localDescription: application.localDescription }) }}
          </span>
        </p>
      </div>
      <div v-if="canCreateApplication || canManagerApplication || canManagerUser">
        <div class="buttonPanel columns" style="display: contents">
          <b-tabs class="buttonRubriquePanel">
            <b-tab-item
              v-if="application.data && Object.keys(application.data).length !== 0"
              aria-description="data"
            >
              <template #header>
                <span class="rubriqueTitle">{{ $t("applications.functions.datas-manage") }}</span>
              </template>
              <div class="columns">
                <div
                  v-if="application.references && Object.keys(application.references).length !== 0"
                  class="buttonWarper"
                >
                  <b-button
                    class="column"
                    icon-left="drafting-compass"
                    type="is-primary"
                    @click="showCharte(displayReferencesManagement(application))"
                    >{{ $t("applications.references") }}
                  </b-button>
                </div>
                <div
                  v-if="application.dataTypes && Object.keys(application.dataTypes).length !== 0"
                  class="buttonWarper"
                >
                  <b-button
                    class="column"
                    icon-left="poll"
                    type="is-primary"
                    @click="showCharte(displayDataSetManagement(application))"
                    >{{ $t("applications.dataset") }}
                  </b-button>
                </div>
              </div>
              <div class="buttonWarper">
                <b-button
                  icon-left="key"
                  outlined
                  type="is-warning"
                  @click="showDataRights(application)"
                  >{{ $t("applications.functions.datas-rights") }}
                </b-button>
              </div>
            </b-tab-item>
            <b-tab-item
              v-if="
                application.additionalFiles && Object.keys(application.additionalFiles).length !== 0
              "
              aria-description="additional files"
              disabled
            >
              <template #header>
                <span class="rubriqueTitle">{{
                  $t("applications.functions.additional-files-manage")
                }}</span>
              </template>
              <div class="buttonWarper">
                <b-button
                  icon-left="file"
                  @click="showCharte(displayAdditionalFilesManagement(application))"
                >
                  {{ $t("applications.additionalFile") }}
                </b-button>
              </div>
              <div class="buttonWarper">
                <b-button
                  v-if="canCreateApplication || canManagerApplication"
                  icon-left="file"
                  @click="showAdditionalFilesManagementRights(application)"
                >
                  {{ $t("applications.functions.additional-files-rights") }}
                </b-button>
              </div>
            </b-tab-item>
            <b-tab-item
              v-if="canCreateApplication || canManagerApplication"
              aria-description="application"
            >
              <template #header>
                <span class="rubriqueTitle">{{
                  $t("applications.functions.application-manage")
                }}</span>
              </template>
              <div class="buttonWarper columns">
                <b-button
                  class="column"
                  icon-left="download"
                  type="is-primary"
                  @click="downloadYamlApplication(application)"
                >
                  {{ $t("referencesManagement.download") }}
                </b-button>
                <b-button
                  v-if="canCreateApplication && canManagerApplication"
                  class="column"
                  icon-left="pen-square"
                  outlined
                  type="is-warning"
                  @click="
                    updateApplication(
                      application.name,
                      application?.configuration?.applicationDescription?.version?.version
                    )
                  "
                >
                  {{ $t("applications.change") }}
                </b-button>
                <b-button
                  class="column"
                  icon-left="download"
                  @click="downloadApplicationUploadBundle(application)"
                >
                  {{ $t("applications.downloadBundle") }}
                </b-button>
                <b-button
                  class="column"
                  icon-left="download"
                  @click="downloadApplicationUploadBundleWithData(application)"
                >
                  {{ $t("applications.downloadBundleWithData") }}
                </b-button>
              </div>
              <div class="buttonWarper columns">
                <div class="column">
                  <b-button
                    disabled
                    icon-left="download"
                    @click="showCharte(downloadCharteApplication(application.id))"
                  >
                    {{ $t("applications.functions.charte-consult") }}
                  </b-button>
                </div>
                <!-- TODO débloquer quand les fichiers additionnel seront fait -->
                <div v-if="!canCreateApplication" class="column">
                  <b-upload
                    v-model="tmpCharte"
                    :validationMessage="$t('applications.functions.file-select')"
                    class="file-label"
                    required
                    @input="uploadCharteApplication(application)"
                  >
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label">{{ $t("applications.functions.upload") }}</span>
                    </span>
                  </b-upload>
                </div>
              </div>
            </b-tab-item>
            <b-tab-item aria-description="authorization">
              <template #header>
                <span class="rubriqueTitle">{{
                  $t("applications.functions.right-requests-manage")
                }}</span>
              </template>
              <div class="buttonWarper">
                <b-button
                  icon-left="users-cog"
                  type="is-primary"
                  @click="requestRights(application.name)"
                >
                  {{ $t("dataTypeAuthorizations.request") }}
                </b-button>
                <b-button
                  icon-left="toolbox"
                  outlined
                  type="is-primary"
                  @click="authorizationsManagementForApplicationUsers(application.name)"
                >
                  {{ $t("dataTypeAuthorizations.delegation-management-right") }}
                </b-button>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
      <div v-else>
        <div class="columns">
          <div
            v-if="application.references && Object.keys(application.references).length !== 0"
            class="buttonWarper"
          >
            <b-button
              :disabled="!canReadReferences.length"
              class="column"
              icon-left="drafting-compass"
              type="is-primary"
              @click="showCharte(displayReferencesManagement(application))"
              >{{ $t("applications.references") }}
            </b-button>
          </div>
          <div
            v-if="application.dataTypes && Object.keys(application.dataTypes).length !== 0"
            class="buttonWarper"
          >
            <b-button
              :disabled="!canReadDataTypes.length"
              class="column"
              icon-left="poll"
              type="is-primary"
              @click="showCharte(displayDataSetManagement(application))"
              >{{ $t("applications.dataset") }}
            </b-button>
          </div>
          <div
            v-if="
              application.additionalFiles && Object.keys(application.additionalFiles).length !== 0
            "
            class="buttonWarper"
          >
            <b-button
              disabled
              icon-left="file"
              @click="showCharte(displayAdditionalFilesManagement(application))"
            >
              {{ $t("applications.additionalFile") }}
            </b-button>
          </div>
          <div
            v-if="
              application?.configuration &&
              application?.configuration.rightsRequest &&
              Object.keys(application?.configuration.rightsRequest).length !== 0
            "
            class="buttonWarper"
          >
            <b-button
              icon-left="users-cog"
              type="is-primary"
              @click="showCharte(showRequestRights(application))"
            >
              {{ $t("dataTypeAuthorizations.showRequests") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </PageView>
</template>

<style lang="scss" scoped>
.infos {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
}

.modal-card {
  width: auto;
  height: auto;
}

.card {
  width: 100%;
  padding: 2em;
}

div.buttonPanel {
  display: flex;
  flex: fit-content;
  flex-direction: row;
  align-content: space-evenly;
  justify-content: space-evenly;
}

div.buttonRubriquePanel {
  margin-top: 1.25em;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  align-content: start;
  justify-content: start;
}

div.buttonRubriquePanel .rubriqueTitle {
  width: 100%;
  text-align: center;
  font-size: 150%;
}

div.buttonWarper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
  padding: 1em;

  button {
    display: flex;
    align-content: center;
    width: 100%;
    margin-inline: 0.5em;
  }
}

.animation-content.modal-content {
  max-width: 87.5em;
}

.validatesCheckbox {
  padding: 0.3em;
  color: #007f7f;
  font-size: 2rem;
}

.validatesCheckbox:hover {
  background-color: white;
}

.card-header-title {
  color: white;
  font-size: 200%;
  padding: 1em;
}
</style>
