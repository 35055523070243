import services from "@/composable/services";

export function allDataName(node, nodeNames) {
  if (node.nodeName) {
    nodeNames.push(node.nodeName);
  }
  if (node.children && Array.isArray(node.children) && Array.isArray(node.children).length !== 0) {
    for (let localChild of node.children) {
      allDataName(localChild, nodeNames);
    }
  }
  return nodeNames;
}

export function createListData(listDataNodeNames, application) {
  return {
    withScope: listDataNodeNames
      .filter((name) => application.configuration.dataDescription[name].authorization)
      .reduce((acc, data) => {
        acc[data] = {
          id: data,
          name:
            services.internationalisationService.localeReferenceNames(data, application) || data,
        };
        return acc;
      }, {}),
    withoutScope: listDataNodeNames
      .filter((name) => application.configuration.dataDescription[name].authorization === null)
      .reduce((acc, data) => {
        acc[data] = {
          id: data,
          name:
            services.internationalisationService.localeReferenceNames(data, application) || data,
        };
        return acc;
      }, {}),
  };
}
