<script>
import app from "@/main";

export default {
  name: "TitleAndDescription",
  props: {
    application: Object,
    localName: String,
    localDescription: String,
    localTitle: String,
    applicationName: {
      type: String,
      required: false,
    },
    withAuth: {
      type: Boolean,
      required: false,
    },
    canCreateApplication: {
      type: Boolean,
      required: false,
    },
    canManagerApplication: {
      type: Boolean,
      required: false,
    },
    canManagerUser: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    function consultAuthorization() {
      app.$router.push(`/applications/${props.applicationName}/authorizations`);
    }

    return {
      consultAuthorization,
    };
  },
};
</script>

<template>
  <h1 class="title main-title">
    <slot name="label" v-bind:option="application">
      {{ localTitle || localName }}
    </slot>
    <slot v-if="localDescription" name="description" v-bind:option="application">
      <sup style="font-size: 1.5em">
        <b-tooltip
          :label="$t('applications.description', { localDescription: localDescription })"
          position="is-top"
        >
          <b-icon icon="info-circle" type="is-dark"></b-icon>
        </b-tooltip>
      </sup>
    </slot>
    <slot
      v-if="canCreateApplication || canManagerApplication || canManagerUser"
      class="btn_auth_tooltip"
    >
      <b-tooltip :label="$t('dataTypesManagement.consult-authorization')" position="is-top">
        <b-button
          icon-left="key"
          outlined
          rounded
          type="is-warning"
          @click="consultAuthorization"
        />
      </b-tooltip>
    </slot>
  </h1>
</template>

<style lang="scss" scoped>
.icon {
  vertical-align: text-top;
}
</style>
