<template>
  <div id="app">
    <FileUploadModal
      v-if="fileUploadState"
      :isModalActive="fileUploadState.isModalActive"
      :modalMessage="fileUploadState.modalMessage"
      :uploadProgress="fileUploadState.uploadProgress"
      @cancel-upload="cancelUpload"
      @continue-navigation="continueNavigation"
    />
    <router-view />
    <SessionExpirationWarning ref="sessionExpirationWarning" />
  </div>
</template>

<script>
import loadApplications from "@/composable/applications/applications";
import SessionExpirationWarning from "@/views/SessionExpirationWarning.vue";
import { onMounted, provide, ref } from "vue";
import sessionService from "@/services/SessionService";
import { Fetcher } from "@/services/Fetcher";
import { useFileUpload } from "./composable/data/uploadFile";
import FileUploadModal from "./components/datas/FileUploadModal.vue";

export default {
  name: "app",
  components: {
    SessionExpirationWarning,
    FileUploadModal,
  },
  setup() {
    const {
      state: fileUploadState,
      startUpload,
      onUploadProgress,
      onUploadComplete,
      cancelUpload,
      continueNavigation,
      finishUpload,
    } = useFileUpload();

    provide("fileUpload", {
      startUpload,
      onUploadProgress,
      onUploadComplete,
      finishUpload,
    });
    const sessionExpirationWarning = ref(null);
    provide("application:progress", loadApplications().progress);
    provide("application:applications", loadApplications().applications);
    provide("application:ownApplications", loadApplications().ownApplications);
    provide("application:hasOwnApplications", loadApplications().hasOwnApplications);
    provide("application:showOwnApplications", loadApplications().showOwnApplications);
    provide("application:otherApplications", loadApplications().otherApplications);
    provide("application:loadApplications", loadApplications().loadApplications);
    onMounted(() => {
      sessionService.init(sessionExpirationWarning.value);
      Fetcher.initSessionService(sessionService);
    });
    return {
      sessionExpirationWarning,
      fileUploadState,
      cancelUpload,
      continueNavigation,
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-default-color;
  display: flex;
  flex-direction: column;
  height: 100%;
}

div.media-content span {
  white-space: pre-line;
  word-break: break-all;
}
</style>
