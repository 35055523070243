import app from "@/main";
import { User } from "@/model/User";
import { Fetcher, LOCAL_STORAGE_AUTHENTICATED_USER } from "@/services/Fetcher.js";
import useObject from "@/composable/components/object";
import router from "@/router";

export class LoginService extends Fetcher {
  static INSTANCE = new LoginService();
  #useUser = useObject(new User());

  constructor() {
    super();
  }

  getAuthenticatedUser(applicationName) {
    let useUser = this.#useUser;
    let user = localStorage.getItem("authenticatedUser");
    user = user ? JSON.parse(user) : {};
    if (!User.states[user.state] && router.currentRoute.path !== "/login") {
      this.logout(applicationName);
    }
    if (useUser.reactiveObject.login === "") {
      if (router.currentRoute.path !== "/login") {
        this.logout(applicationName);
      }
    }
    if (!useUser.reactiveObject.login) {
      const user = new User();
      if (!user) {
        if (router.currentRoute.path !== "/login") {
          this.logout(applicationName);
        }
      } else {
        this.setAuthenticateduser(user);
      }
    }
    return useUser.reactiveObject;
  }

  setAuthenticateduser(user = this.getAuthenticatedUser()) {
    // Créer une nouvelle instance de User avec les données fournies
    const newUser = new User({
      id: user.id,
      login: user.login,
      email: user.email,
      state: user.state,
      authorizedForApplicationCreation: user.authorizedForApplicationCreation,
      openAdomAdmin: user.openAdomAdmin,
      authorizations: user.authorizations,
      chartes: user.chartes,
      currentUserRoles: user.currentUserRoles,
      lang: user.lang,
    });

    // Stocker l'utilisateur dans le localStorage
    localStorage.setItem(LOCAL_STORAGE_AUTHENTICATED_USER, JSON.stringify(newUser));

    // Mettre à jour l'état de l'utilisateur
    this.#useUser.doChangeObject(newUser);
  }

  async signIn(login, pwd, applicationName) {
    let response = await this.post("login", {
      login: login,
      password: pwd,
    });
    this.setAuthenticateduser(response);
    if (applicationName) {
      app.$router.push("/applications/" + applicationName);
    } else {
      app.$router.push("/applications");
    }
    return Promise.resolve(response);
  }

  async register(login, pwd, email) {
    return this.post("users", {
      login: login,
      password: pwd,
      email: email,
    });
  }

  /**
   *
   * @param params
   * params = {
   *   "email": "string",
   *   "login": "string",
   *   "newPassword": "string",
   *   "newPasswordConfirm": "string",
   *   "password": "string",
   *   "verificationKey": "string"
   * }
   * login + password + verificationKey => activation du compte utilisateur
   * login + password => {
   *     si le compte est actif => update du compte (ex: email, newPassword + newPasswordConfirm)
   *     sinon => active le compte
   * }
   * email + login => récupération de verificationKey (envoie du email)
   * email + login + verificationKey => mise à jour mot de passe perdu
   * @returns {Promise<*|undefined>}
   */
  async modifAcount(params) {
    let response = await this.put("users", params, false);
    this.setAuthenticateduser(response);
    return response;
  }

  async logout(applicationName) {
    await this.delete("logout");
    this.notifyCrendentialsLost(applicationName);
  }

  async refreshSession() {
    return await this.get("status");
  }

  async getByIdOrLogin(userLoginOrId) {
    return this.get(`users/${userLoginOrId}`);
  }
}
