<template>
  <PageView class="with-submenu">
    <SubMenu
      :aria-label="$t('menu.aria-sub-menu')"
      :paths="subMenuPaths"
      :root="application.localName || application.title"
      role="navigation"
    />

    <h1 class="title main-title">
      <span>{{
        $t(
          authorizationId === "new"
            ? `additionalFilesAuthorizations.sub-menu-new-authorization`
            : "additionalFilesAuthorizations.sub-menu-modify-authorization",
          { authorizationId }
        )
      }}</span>
    </h1>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <div class="columns">
        <ValidationProvider
          v-slot="{ errors, valid }"
          class="column is-half"
          name="users"
          rules="required"
          vid="users"
        >
          <b-field
            :label="$t('additionalFilesAuthorizations.users')"
            :message="errors[0]"
            :type="{
              'is-danger': errors && errors.length > 0,
              'is-success': valid,
            }"
            class="column mb-4"
          >
            <b-taginput
              v-model="selectedlabels"
              :data="userLabels"
              :open-on-focus="openOnFocus"
              :placeholder="$t('additionalFilesAuthorizations.users-placeholder')"
              :value="userLabels"
              autocomplete
              expanded
              type="is-dark"
              @typing="getFilteredTags"
            >
            </b-taginput>
            <b-tooltip :label="$t('additionalFilesAuthorizations.closeUser')" position="is-bottom">
              <b-button v-model="openOnFocus" icon-left="times-circle"></b-button>
            </b-tooltip>
          </b-field>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors, valid }"
          class="column is-half"
          name="users"
          rules="required"
          vid="users"
        >
          <b-field
            :label="$t('additionalFilesAuthorizations.name')"
            :message="errors[0]"
            :type="{
              'is-danger': errors && errors.length > 0,
              'is-success': valid,
            }"
            class="column mb-4"
          >
            <b-input v-model="name" />
          </b-field>
        </ValidationProvider>
      </div>
      <LoadingAnimate v-if="isLoading" :size="'is-large'"></LoadingAnimate>
      <b-table :data="Object.values(additionalFiles || {})">
        <b-table-column v-slot="props" :label="$t('additionalFilesManagement.additionalFiles')">
          {{ props.row.dataNameLocal || props.row.label }}
        </b-table-column>
        <b-table-column v-slot="props" :label="$t('additionalFilesManagement.admin')">
          <b-checkbox-button v-model="props.row.isAdmin" type="is-light">
            <b-icon
              :icon="props.row.isAdmin ? 'square-check' : 'square'"
              class="clickable"
              pack="far"
              size="is-medium"
              type="is-primary"
            />
          </b-checkbox-button>
        </b-table-column>
        <b-table-column v-slot="props" :label="$t('additionalFilesManagement.depot')">
          <b-checkbox-button v-model="props.row.isDepot">
            <b-icon
              :icon="props.row.isDepot ? 'square-check' : 'square'"
              class="clickable"
              pack="far"
              size="is-medium"
              type="is-primary"
            />
          </b-checkbox-button>
        </b-table-column>
        <!--        <b-table-column v-slot="props" :label="$t('additionalFilesManagement.consult')">
          <b-checkbox-button v-model="props.row.isDepot">
            <b-icon
                :icon="props.row.isManage ? 'square-check' : 'square'"
                class="clickable"
                pack="far"
                size="is-medium"
                type="is-primary"
            />
          </b-checkbox-button>
        </b-table-column>-->
        <b-table-column v-slot="props" :label="$t('additionalFilesManagement.download')">
          <b-checkbox-button v-model="props.row.isExtraction">
            <b-icon
              :icon="props.row.isExtraction ? 'square-check' : 'square'"
              class="clickable"
              pack="far"
              size="is-medium"
              type="is-primary"
            />
          </b-checkbox-button>
        </b-table-column>
        <b-table-column v-slot="props" :label="$t('additionalFilesManagement.delete')">
          <b-checkbox-button v-model="props.row.isDelete">
            <b-icon
              :icon="props.row.isDelete ? 'square-check' : 'square'"
              class="clickable"
              pack="far"
              size="is-medium"
              type="is-primary"
            />
          </b-checkbox-button>
        </b-table-column>
      </b-table>

      <div class="buttons">
        <b-button
          icon-left="plus"
          style="margin-bottom: 10px; margin-top: 10px"
          type="is-dark"
          @click="handleSubmit(createOrUpdateAuthorization)"
        >
          {{
            authorization
              ? $t("additionalFilesAuthorizations.modify")
              : $t("additionalFilesAuthorizations.create")
          }}
        </b-button>
      </div>
    </ValidationObserver>
  </PageView>
</template>

<script>
import CollapsibleTree from "@/components/common/CollapsibleTree.vue";
import SubMenu, { SubMenuPath } from "@/components/common/SubMenu.vue";
import { AlertService } from "@/services/AlertService";
import { ApplicationService } from "@/services/rest/ApplicationService";
import { AuthorizationService } from "@/services/rest/AuthorizationService";
import { UserPreferencesService } from "@/services/UserPreferencesService";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import PageView from "../common/PageView.vue";
import { InternationalisationService } from "@/services/InternationalisationService";
import { ApplicationResult } from "@/model/ApplicationResult";
import { AdditionalFileService } from "@/services/rest/AdditionalFileService";
import LoadingAnimate from "@/components/common/LoadingAnimate.vue";

@Component({
  components: {
    LoadingAnimate,
    PageView,
    SubMenu,
    CollapsibleTree,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class AdditionalFilesAuthorizationInfoView extends Vue {
  @Prop() dataTypeId;
  @Prop() applicationName;
  @Prop({ default: "new" }) authorizationId;
  @Prop([]) authorizations;

  __DEFAULT__ = "__DEFAULT__";
  additionalFileService = AdditionalFileService.INSTANCE;
  openOnFocus = true;
  authorizationService = AuthorizationService.INSTANCE;
  internationalisationService = InternationalisationService.INSTANCE;
  alertService = AlertService.INSTANCE;
  applicationService = ApplicationService.INSTANCE;
  userPreferencesService = UserPreferencesService.INSTANCE;
  authorization = {};
  publicAuthorizations = [];
  ownAuthorizations = [];
  ownAuthorizationsColumnsByPath = {};
  users = [];
  name = null;
  dataGroups = [];
  authorizationScopes = [];
  application = new ApplicationResult();
  selectedlabels = [];
  userLabels = [];
  authAdditionalFiles = {};
  subMenuPaths = [];
  selectedUsers = [];
  filteredTags = [];
  additionalFiles = [];
  isLoading = false;

  getColumnTitle(column) {
    if (column.display) {
      return (
        (column.internationalizationName && column.internationalizationName[this.$i18n.locale]) ||
        column.title
      );
    }
  }

  async created() {
    await this.init();
    this.chosenLocale = this.userPreferencesService.getUserPrefLocale();
    this.subMenuPaths = [
      new SubMenuPath(
        this.$t("additionalFilesManagement.additionalFiles").toLowerCase(),
        () => this.$router.push(`/applications/${this.applicationName}/dataTypes`),
        () => this.$router.push("/applications")
      ),
      new SubMenuPath(
        this.$t(`additionalFilesAuthorizations.sub-menu-additional-file-authorizations`),
        () => {
          this.$router.push(`/applications/${this.applicationName}/additionalFiles/authorizations`);
        },
        () => this.$router.push(`/applications/${this.applicationName}/additionalFiles`)
      ),
      new SubMenuPath(
        this.$t(
          this.authorizationId === "new"
            ? `additionalFilesAuthorizations.sub-menu-new-authorization`
            : "additionalFilesAuthorizations.sub-menu-modify-authorization",
          { authorizationId: this.authorizationId }
        ),
        () => {},
        () => {
          this.$router.push(`/applications/${this.applicationName}/additionalFiles/authorizations`);
        }
      ),
    ];
  }

  mounted() {}

  showDetail(parent) {
    for (const child in parent) {
      if (parent[child].children.length !== 0) {
        parent[child] = { ...parent[child], showDetailIcon: true };
      }
      parent[child] = { ...parent[child], showDetailIcon: false };
    }
  }

  async init() {
    this.isLoading = true;
    try {
      this.application = await this.applicationService.getApplication(this.applicationName, [
        "CONFIGURATION",
        "ADDITIONALFILE",
      ]);
      let params = {
        userId: null,
      };
      if ("new" !== this.authorizationId) {
        params = { ...params, authorizationId: this.authorizationId };
      } else {
        params = { ...params, limit: 0 };
      }
      let authorizations = await this.authorizationService.getadditionalFilesAuthorizations(
        this.applicationName,
        params
      );
      let authorizationForUser = authorizations.authorizationsForUser;
      this.users = authorizations.users;
      this.authorizations = authorizations;
      this.authorization = authorizations.authorizationResults?.[0];
      this.name = this.authorization?.name;
      this.additionalFiles = Object.keys(this.application.additionalFiles || {}).reduce(
        (acc, af) => {
          let label = af;
          let refNameLocal = this.internationalisationService.getLocaleforPath(
            this.application,
            "additionalFiles." + af + ".internationalizationName",
            af
          );
          let isAdmin, isDepot, isDelete, isExtraction;
          if (
            authorizationForUser.isAdministrator ||
            authorizationForUser.authorizationResults?.admin?.includes(af)
          ) {
            isAdmin =
              this.authorization && (this.authorization?.authorizations?.admin || []).includes(af);
            isDepot =
              this.authorization && (this.authorization?.authorizations?.depot || []).includes(af);
            isDelete =
              this.authorization && (this.authorization?.authorizations?.delete || []).includes(af);
            isExtraction =
              this.authorization &&
              (this.authorization?.authorizations?.extraction || []).includes(af);
          }
          acc[af] = {
            label,
            refNameLocal,
            isAdmin,
            isDelete,
            isDepot,
            isExtraction,
          };
          return acc;
        },
        {}
      );

      this.application = {
        ...this.internationalisationService.mergeInternationalization(this.application),
        localadditionalFilesNames: Object.values(
          this.internationalisationService.treeReferenceName(this.application)
        ),
      };
      let currentAuthorizationUsers = (this.authorization && this.authorization.users) || [];
      this.selectedUsers = this.users
        .filter((user) => {
          return currentAuthorizationUsers.find((u) => {
            return u.id === user.id;
          });
        })
        .map((user) => user.id);
      for (let i = 0; i < this.selectedUsers.length; i++) {
        for (let j = 0; j < this.users.length; j++) {
          if (this.selectedUsers[i] === this.users[j].id) {
            this.selectedlabels.push(this.users[j].label);
          }
        }
      }
      for (let i = 0; i < this.users.length; i++) {
        if (!this.selectedlabels.includes(this.users[i].label))
          this.userLabels.push(this.users[i].label);
      }
      this.userLabels.sort();
    } catch (error) {
      this.alertService.toastServerError(error);
    }
    this.isLoading = false;
  }

  getFilteredTags(text) {
    this.userLabels = [];
    this.filteredTags = this.users.filter((option) => {
      return option.label.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0;
    });
    for (let i = 0; i < this.filteredTags.length; i++) {
      if (!this.selectedlabels.includes(this.filteredTags[i].label)) {
        this.userLabels.push(this.filteredTags[i].label);
      }
    }
    this.userLabels.sort();
  }

  async createOrUpdateAuthorization() {
    try {
      let users = this.selectedlabels
        .reduce((acc, label) => {
          acc.push(this.users.find((u) => u.label === label));
          return acc;
        }, [])
        .map((u) => u.id);
      let additionalFiles = Object.values(this.additionalFiles).reduce((acc, ref) => {
        if (ref.isAdmin) {
          let isAdmin = acc.admin || [];
          isAdmin.push(ref.label);
          acc.admin = isAdmin;
        }
        if (ref.isDepot) {
          let isDepot = acc.depot || [];
          isDepot.push(ref.label);
          acc.depot = isDepot;
        }
        if (ref.isExtraction) {
          let isExtraction = acc.extraction || [];
          isExtraction.push(ref.label);
          acc.extraction = isExtraction;
        }
        if (ref.isDelete) {
          let isDelete = acc.delete || [];
          isDelete.push(ref.label);
          acc.delete = isDelete;
        }
        return acc;
      }, {});
      let authorization = {
        usersId: users,
        applicationNameOrId: this.applicationName,
        uuid: "new" === this.authorizationId ? null : this.authorizationId,
        name: this.name,
        additionalFiles: additionalFiles,
      };
      await this.authorizationService.createOrUpdateAdditionalFilesAuthorization(authorization);
      await this.$router.push(
        `/applications/${this.applicationName}/additionalFiles/authorizations`
      );
    } catch (error) {
      this.alertService.toastServerError(error);
    }
  }
}
</script>

<style lang="scss">
.DataTypeAuthorizationInfoView-periods-container {
  .field-body .field.has-addons {
    display: flex;
    flex-direction: column;
  }
}

.DataTypeAuthorizationInfoView-radio-field {
  height: 40px;

  &.b-radio {
    .control-label {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
}

.DataTypeAuthorizationInfoView-radio-label {
  width: 200px;
}

.collapse-content .card-content .content .CollapsibleTree-header .CollapsibleTree-buttons {
  visibility: hidden;
  display: none;
}

.leaf label {
  font-weight: lighter;
  font-style: italic;
  color: #2c3e50;
}

.folder label {
  font-weight: bolder;
  color: $dark;
}

.rows .card-content .row.label .columns .column {
  padding: 0 0 0 10px;
  border-bottom: 2px solid;
  border-color: $dark;
  margin-bottom: 12px;
}

ul li.card-content {
  background-color: rgba(0, 0, 0, 0.05);
}

a {
  color: $dark;
}

.b-checkbox.checkbox.button,
.b-checkbox.checkbox.button:hover,
.b-checkbox.checkbox.button:active,
.b-checkbox.checkbox.button:focus {
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
}
</style>
