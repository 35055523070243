import { Tag } from "@/model/application/Tag";
export class Node {
  id;
  label;
  dataNameLocal;
  dataDescriptionLocal;
  parent;
  children = [];
  isRecursive = false;
  componenttKey;
  order = 999;
  depends = [];
  tags;

  constructor(node, getLocalName, getLocalDescription, getTags, internationalize) {
    this.id = node.nodeName;
    this.label = node.nodeName;
    this.dataNameLocal = getLocalName ? getLocalName(node.nodeName) : node.nodeName;
    this.dataDescriptionLocal = getLocalName
      ? getLocalDescription(node.nodeName)
      : node.description;
    this.parent = node.parent;
    this.isRecursive = node.isRecursive;
    this.componenttKey = node.componenttKey;
    this.order = node.order;
    this.depends = node.depends;

    if (node?.children.length) {
      this.children = (node.children || []).map(
        (n) => new Node(n, getLocalName, getLocalDescription)
      );
    }
    this.tags = (getTags ? getTags(node.nodeName) : []).map((tag) =>
      Tag.buildTag(tag, internationalize)
    );
  }
}
