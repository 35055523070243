import { reactive } from "vue";
import { i18n } from "@/main";

export function useFileUpload() {
  const state = reactive({
    isModalActive: false,
    modalMessage: "",
    uploadProgress: 0,
  });

  const onUploadProgress = (progress) => {
    state.uploadProgress = progress.percent * 100;
  };

  const onUploadComplete = () => {
    state.modalMessage = i18n.t("fileUpload.treatemntContinuing");
    state.uploadProgress = 100;
  };

  const startUpload = (withUpload = false, message = i18n.t("fileUpload.fileUploading")) => {
    state.isModalActive = true;
    state.modalMessage = message;
    state.uploadProgress = 0;
    if(!withUpload){
      onUploadComplete();
    }
  };

  const finishUpload = () => {
    state.isModalActive = false;
    state.uploadProgress = 0;
  };

  const cancelUpload = () => {
    // Logique pour annuler le chargement côté back-end
    finishUpload();
  };

  const continueNavigation = () => {
    finishUpload();
  };

  return {
    state,
    onUploadProgress,
    onUploadComplete,
    startUpload,
    finishUpload,
    cancelUpload,
    continueNavigation,
  };
}
