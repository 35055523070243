<template>
  <div v-if="!option.children.length" :id="option.id" style="padding: 5px 16px">
    <a
      tabindex="0"
      @click="select(option, option.i18n[locale] || option.naturalKey, option.id)"
      @keyup.enter="select(option, option.i18n[locale] || option.naturalKey, option.id)"
      @keyup.space="select(option, option.i18n[locale] || option.naturalKey, option.id)"
    >
      {{ option.i18n[locale] || option.naturalKey }}</a
    >
  </div>
  <b-collapse v-else :open="isOpen" animation="slide" class="card">
    <template #trigger="props">
      <div :aria-expanded="props.open" class="card-header">
        <p class="card-header-title">
          {{ option.i18n[locale] || option.naturalKey }}
        </p>
        <a
          class="card-header-icon"
          tabindex="0"
          @keyup.enter="isOpen = !isOpen"
          @keyup.space="isOpen = !isOpen"
        >
          <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
        </a>
      </div>
    </template>
    <div class="card-content">
      <div class="content">
        <CollapseMenuOneSelect
          v-for="child in option.children"
          :key="child.id"
          :id-reference-scope="idReferenceScope"
          :option="child"
          @select-menu-item="select"
        >
        </CollapseMenuOneSelect>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import { ref, computed } from "vue";
import services from "@/composable/services";

export default {
  name: "CollapseMenuOneSelect",
  props: {
    option: {
      type: Object,
      required: true,
    },
    listSelect: Array,
    idReferenceScope: String,
  },
  emits: ["select-menu-item", "change-style-if-selected"],
  setup(props, { emit }) {
    const isOpen = ref(false);
    const locale = services.internationalisationService.getLocale();
    const displayText = computed(() => props.option.i18n[locale] || props.option.naturalKey);

    function changeStyleIfSelected(localID) {
      const localElement = document.getElementById(localID);
      if (localElement) {
        console.log("selected", localID, props.idReferenceScope);
        Array.from(document.querySelectorAll("div.colorSelected"))
          .filter(
            (el) =>
              el.id && el.id.includes(props.idReferenceScope + "K") && !el.id.includes(localID)
          )
          .forEach((element) => {
            element.classList.remove("colorSelected");
          });
        localElement.classList.add("colorSelected");
      }
    }

    function select(option, localName, localID) {
      changeStyleIfSelected(localID);
      option.localName = option.localName || localName;
      emit("select-menu-item", option || { ...this.option, localName });
    }

    return {
      select,
      isOpen,
      locale,
      displayText,
    };
  },
};
</script>

<style lang="scss" scoped>
a {
  color: black;
}

.colorSelected {
  background-color: rgba(0, 100, 100, 0.4);
}

.card {
  box-shadow: none;

  .card-header {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;

    .card-header-title {
      color: $dark-slightly-transparent-8;
      margin: 0;
    }
  }

  .card-content {
    padding: 5px 0px 5px 20px;
    border-radius: 0px;

    .content {
      border-left: 0.5px solid $primary;
    }

    .collapse {
      padding: 0;

      .card-header {
        .card-header-title {
          color: $dark-slightly-transparent-6;
          margin: 0;
        }
      }
    }
  }
}
</style>
