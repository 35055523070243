<template>
  <b-collapse :open="open" animation="slide" aria-id="collapseForTags" class="card">
    <template #trigger="props">
      <div :aria-expanded="props.open" aria-controls="collapseForTags" class="card-header">
        <p class="card-header-title" :aria-label="$t('tags.tag')">
          {{ $t("tags.tag") }}
        </p>
        <a
          class="card-header-icon"
          tabindex="0"
          @keyup.enter="open = !open"
          @keyup.space="open = !open"
        >
          <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
        </a>
      </div>
    </template>
    <div class="card-content">
      <div class="content columns" style="display: flex; flex-wrap: wrap">
        <b-switch
          class="column is-narrow"
          v-model="allTags"
          passive-type="is-light"
          type="is-warning"
          @input="changeAllTags(allTags, $event)"
        >
          {{ $t("tags.allTags") }}
        </b-switch>
      </div>
      <div class="content columns" style="display: flex; flex-wrap: wrap">
        <b-field v-for="(tag, key) in tags" :key="key" class="column is-narrow">
          <b-switch
            v-model="tag.selected"
            passive-type="is-light"
            type="is-dark"
            @input="changeTag(key, $event)"
          >
            {{ key === "no_tag" ? $t("tags.no-tag") : tag.localName }}
          </b-switch>
        </b-field>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import useBoolean from "@/composable/components/boolean";
export default {
  name: "TagsCollapse",
  emits: ["change:tag"],
  props: {
    tags: {
      default: {},
    },
  },

  setup(props) {
    const { refBoolean: open } = useBoolean();
    const { refBoolean: allTags } = useBoolean(true);
    function changeTag() {
      this.$emit("change:tag", props.tags);
    }
    function changeAllTags(allTags) {
      this.$emit("change:allTags", allTags);
    }

    return {
      changeTag,
      changeAllTags,
      open,
      allTags,
    };
  },
};
</script>
