import services from "@/composable/services";
import useArray from "@/composable/components/array";
import { Application } from "@/model/application/Application";
import useNumber from "@/composable/components/number";
import useBoolean from "@/composable/components/boolean";

const { shallowRefArray: applications } = useArray();
const { shallowRefArray: ownApplications } = useArray();
const { refBoolean: hasOwnApplications } = useBoolean();
const { refNumber: showOwnApplications } = useNumber();
const { shallowRefArray: otherApplications } = useArray();
const { refNumber: progress } = useNumber(0);

function addApplication(result) {
  if (result.type === "REACTIVE_PROGRESS") {
    progress.value = result.result;
  } else if (result.type === "REACTIVE_RESULT") {
    let application = Application.of(result.result);
    const authenticatedUser = services.loginService.getAuthenticatedUser(application.name);
    authenticatedUser.invalidateCharte(application.id);
    application = services.internationalisationService.mergeInternationalization(application);
    let id = applications.value
      .map((a, i) => (a.name === application.name ? i : -1))
      .find((i) => i >= 0);
    let localApplication = applications.value;
    if (id >= 0) {
      localApplication[id] = application;
    } else {
      localApplication.push(application);
    }
    ownApplications.value = localApplication.filter((app) => app.isApplicationUser());
    otherApplications.value = localApplication.filter((app) => !app.isApplicationUser());
    hasOwnApplications.value = ownApplications.value.length > 0;
    showOwnApplications.value = hasOwnApplications.value ? 0 : 1;
    applications.value = localApplication;
  }
}

async function loadApplications(filter) {
  await services.applicationService.getApplications(addApplication, filter);
}

function useApplication() {
  return {
    progress,
    applications,
    ownApplications,
    otherApplications,
    loadApplications,
    hasOwnApplications,
    showOwnApplications,
  };
}

export default useApplication;
