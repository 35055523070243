import config from "@/config";
import app from "@/main";
import { HttpStatusCodes } from "@/utils/HttpUtils";
import { Locales } from "@/utils/LocaleUtils";
import { User } from "@/model/User";

export const LOCAL_STORAGE_LANG = "lang";
export const LOCAL_STORAGE_AUTHENTICATED_USER = "authenticatedUser";

export class Fetcher {
  static #_sessionService = null; // Propriété statique privée (ES2022)

  static initSessionService(service) {
    Fetcher.#_sessionService = service;
  }

  // Getter protégé (convention _ préfixe)
  get _sessionService() {
    if (!Fetcher.#_sessionService) {
      throw new Error("SessionService non initialisé");
    }
    return Fetcher.#_sessionService;
  }

  updateLastCallTime = (response) => {
    if (response.ok) {
      this._sessionService.updateLastCallTime();
    }
    return response;
  };

  xhrPost(url, data, withFormData, additionalHeaders = {}, onUploadProgress, onUploadComplete) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const fullUrl = `${config.API_URL}${url}`;

      // Construction du corps et des headers
      let body;
      const headers = {
        "Accept-Language": this.getUserPrefLocale(),
        ...additionalHeaders
      };

      if (withFormData) {
        body = this.convertToFormData(data);
        // Le navigateur définira automatique le Content-Type multipart
        delete headers["Content-Type"];
      } else {
        body = JSON.stringify(data);
        headers["Content-Type"] = "application/json;charset=UTF-8";
      }

      // Configuration XHR
      xhr.open("POST", fullUrl);
      xhr.withCredentials = true; // Équivalent à credentials: "include"

      // Application des headers
      Object.entries(headers).forEach(([key, value]) => {
        xhr.setRequestHeader(key, value);
      });

      // Gestion de la progression
      xhr.upload.onprogress = (e) => {
        try {
          if (e.lengthComputable && e.loaded === e.total && onUploadComplete) {
            onUploadComplete({
              fileName: data.file?.name || "file",
              size: e.total,
              mimeType: data.file?.type
            });
          } else if (onUploadProgress) {
            onUploadProgress({
              loaded: e.loaded,
              total: e.total,
              percent: e.lengthComputable ? e.loaded / e.total : 0
            });
          } else {
            console.error("vous devez définir onUploadProgress et onUploadComplete");
          }
        } catch (callbackError) {
          console.error("Erreur dans les callbacks:", callbackError);
        }
      };

      // Gestion réponse
      xhr.onload = () => {
        const response = new Response(xhr.responseText, {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: this._parseXHRHeaders(xhr)
        });
        resolve(response);
      };

      xhr.onerror = () => reject(new Error("Erreur réseau"));

      /*if (body instanceof FormData) {
        xhr.setSendMonkeyPatched(); // Solution maison pour keepalive
      }*/
      xhr.send(body);
    });
  }

  // Méthode utilitaire pour convertir les headers XHR
  _parseXHRHeaders(xhr) {
    const headers = new Headers();
    const headersStr = xhr.getAllResponseHeaders();
    const lines = headersStr.trim().split(/[\r\n]+/);

    lines.forEach((line) => {
      const [name, value] = line.split(": ");
      headers.append(name, value);
    });

    return headers;
  }

  getPostFetch(
    url,
    data,
    withFormData = true,
    additionalHeaders = {},
    onUploadProgress,
    onUploadComplete
  ) {
    additionalHeaders['X-Request-ID']=  crypto.randomUUID();
    withFormData = withFormData == null || withFormData;
    let body = JSON.stringify(data);
    if (withFormData) {
      body = this.convertToFormData(data);
    }
    const headers = withFormData
      ? { ...additionalHeaders, "Accept-Language": this.getUserPrefLocale() }
      : {
        ...additionalHeaders,
        "Accept-Language": this.getUserPrefLocale(),
        "Content-Type": "application/json;charset=UTF-8;multipart/form-data"
      };
    const hasFile = withFormData && data.file instanceof File;
    if (hasFile) {
      return this.xhrPost(
        url,
        data,
        withFormData,
        additionalHeaders,
        onUploadProgress,
        onUploadComplete
      ); // Avec suivi XHR
    } else {
      return fetch(`${config.API_URL}${url}`, {
        method: "POST",
        keepalive: true,
        mode: "cors",
        credentials: "include",
        body: body,
        headers: headers
      }).then(this.updateLastCallTime);
    }
  }

  async post(
    url,
    data,
    withFormData = true,
    additionalHeaders,
    onUploadProgress,
    onUploadComplete
  ) {
    const response = await this.getPostFetch(
      url,
      data,
      withFormData,
      additionalHeaders,
      onUploadProgress,
      onUploadComplete
    );

    return this._handleResponse(response);
  }

  async postChunck(method, url, data, withFormData = true) {
    this.dochuck("POST", method, url, data, withFormData);
  }

  dochuck(httpMethod, method, url, data, withFormData) {
    let fetch;
    if ("POST" === httpMethod) {
      fetch = this.getPostFetch(url, data, withFormData, { Accept: "application/x-ndjson" });
    } else if ("GET" === httpMethod) {
      fetch = this.getGetFetch(url, data, {
        Accept: "application/x-ndjson, application/json, */*"
      });
    } else if ("PUT" === httpMethod) {
      fetch = this.getPutFetch(url, data, withFormData, { Accept: "application/x-ndjson" });
    }
    fetch
      .then(async (response) => {
        if (!response.ok) {
          let errorBody = {};
          // Récupérer le corps de l'erreur
          try {
            errorBody = await response.json();
            console.log(errorBody);
          } catch (e) {
            // Si le corps n'est pas du JSON valide
            console.error(`Erreur ${response.status}: Impossible de lire le corps de la réponse`);
          }

          // Gérer spécifiquement l'erreur 401
          if (response.status === 401) {
            console.error("Utilisateur non connecté");
            app.$router.push("/login");
            return;
          }

          // Arrêter le traitement du flux NDJSON
          return;
        }
        // response.body is a ReadableStream
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let result = await reader.read();
        let buffer = "";
        while (!result.done) {
          buffer += decoder.decode(result.value);

          let idx = buffer.indexOf("\n");
          while (idx !== -1) {
            const text = buffer.substring(0, idx);
            try {
              const message = JSON.parse(text);
              method(message);
            } catch (error) {
              console.warn(error, text);
            }
            buffer = buffer.substring(idx + 1);
            idx = buffer.indexOf("\n");
          }

          result = await reader.read();
        }
      })
      .catch((error) => {
        throw error;
      });
  }

  getChunck(method, url = "json/flux", params) {
    this.dochuck("GET", method, url, params);
  }

  readChunks(reader) {
    return {
      async* [Symbol.asyncIterator]() {
        let readResult = await reader.read();
        while (!readResult.done) {
          yield readResult.value;
          readResult = await reader.read();
        }
      }
    };
  }

  async put(url, data, withFormData = true) {
    const response = await this.getPutFetch(data, withFormData, url);

    return this._handleResponse(response);
  }

  getPutFetch(data, withFormData, url, additionalHeaders = {}) {
    let formData = JSON.stringify(data);
    if (withFormData) {
      formData = this.convertToFormData(data);
    }
    // const formData = this.convertToFormData(data);
    return fetch(`${config.API_URL}${url}`, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      body: formData,
      headers: {
        ...additionalHeaders,
        "Content-Type": "application/json",
        "Accept-Language": this.getUserPrefLocale()
      }
    }).then(this.updateLastCallTime);
  }

  async get(url, params = {}, isText) {
    const response = await this.getGetFetch(url, params);

    return this._handleResponse(response, isText);
  }

  getGetFetch(url, params, additionalHeaders = {}) {
    const path = new URL(url, config.API_URL);

    Object.entries(params).forEach(([name, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          path.searchParams.append(name, v);
        });
      } else {
        path.searchParams.append(name, value);
      }
    });

    return fetch(path, {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        ...additionalHeaders,
        "Accept-Language": this.getUserPrefLocale()
      }
    }).then(this.updateLastCallTime);
  }

  getPath(url, params = {}) {
    const path = new URL(url, config.API_URL);

    Object.entries(params).forEach(([name, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          path.searchParams.append(name, v);
        });
      } else {
        path.searchParams.append(name, value);
      }
    });
    return path;
  }

  async delete(url, data) {
    const formData = this.convertToFormData(data);
    const response = await fetch(`${config.API_URL}${url}`, {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
      body: formData,
      headers: {
        "Accept-Language": this.getUserPrefLocale()
      }
    }).then(this.updateLastCallTime);

    if (response.ok) {
      return Promise.resolve(response);
    } else if (response.status === HttpStatusCodes.UNAUTHORIZED) {
      this.notifyCrendentialsLost();
    }

    return Promise.reject({ status: response.status });
  }

  async _handleResponse(response, isText) {
    try {
      const contentType = response.headers.get("content-type");
      if (contentType?.startsWith("text/")) {
        isText = true;
      }
      const text = isText ? response.text() : response.json();
      if (response.ok && response.status !== HttpStatusCodes.NO_CONTENT) {
        return Promise.resolve(text);
      }
      return Promise.reject({
        httpHeaders: response.headers,
        httpResponseCode: response.status,
        content: Promise.resolve(text)
      });
    } catch (error) {
      return Promise.reject({ httpHeaders: response.headers, httpResponseCode: response.status });
    }
  }

  async showFile(urlPath) {
    const url = new URL(`${config.API_URL}${urlPath}`);
    window.open(url, "_blank");
  }

  async downloadFile(urlPath) {
    const url = new URL(`${config.API_URL}${urlPath}`);
    const link = document.createElement("a");
    link.href = url;
    link.type = "application/octet-stream";
    link.download = "export.csv";
    link.click();
  }

  notifyCrendentialsLost(applicationName) {
    this.setAuthenticateduser(new User());
    localStorage.removeItem(LOCAL_STORAGE_AUTHENTICATED_USER);
    if (applicationName) {
      app.$router.push("/login/" + applicationName).catch(() => {
      });
    } else {
      app.$router.push("/login").catch(() => {
      });
    }
  }

  convertToFormData(body) {
    let formData = new FormData();
    if (body) {
      for (const [key, value] of Object.entries(body)) {
        formData.append(key.toString(), value);
      }
    }
    return formData;
  }

  getUserPrefLocale() {
    const browserLocale = window.navigator.language.substring(0, 2);

    return (
      localStorage.getItem(LOCAL_STORAGE_LANG) ||
      (Object.values(Locales).includes(browserLocale) && browserLocale) ||
      Locales.FRENCH
    );
  }
}
