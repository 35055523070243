<template>
  <b-collapse
    :open="open"
    animation="slide"
    class="card"
    style="margin: 0.625rem 0 0.625rem 0"
    tabindex="0"
    @keyup.enter="open"
    @keyup.space="open"
  >
    <template #trigger="props">
      <div class="card-header">
        <p class="card-header-title" style="text-transform: capitalize">
          <b-icon icon="sliders-h"></b-icon>
          <span>{{ $t("applications.advancedFilter") }}</span>
        </p>
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
        </a>
      </div>
    </template>
    <div
      id="filtreScroll"
      class="card-content"
      style="padding: 0.625em 0 0.625em 0; overflow-x: auto; overflow-y: hidden; height: 19em"
    >
      <div class="content columns" style="margin-bottom: 0.625em">
        <div
          v-for="(columns, key) in columnsToBeShown"
          :key="key"
          class="column"
          style="width: max-content; display: contents"
        >
          <b-field
            v-if="
              columns.checker?.type === 'ReferenceChecker' &&
              (isRefLinkTo[columns.id] || columns.id !== '#')
            "
            @click.native="getListeReferenceValues(columns.id, columns.refLinkedTo)"
          >
            <template #label>
              <span class="label inputStyle">{{ columns.getHeader(application, dataId) }}</span>
            </template>
            <b-taginput
              :id="columns.componentKey"
              v-model="autocompleted[columns.componentKey]"
              :data="searchValueReference"
              :placeholder="$t('dataTypeAuthorizations.search')"
              :triggers="['focus']"
              :value="autocompleted[columns.componentKey]"
              autocomplete
              icon="search"
              icon-right="angle-down"
              open-on-focus
              type="search"
              @focus="getListeReferenceValues(columns.id, columns.refLinkedTo)"
              @select="
                (option) =>
                  updateValue(
                    columns.id,
                    {
                      componentKey: columns.parentComponentKey
                        ? columns.parentComponentKey + '::' + columns.id
                        : columns.id,
                      filters: [],
                      columns: columns,
                    },
                    option
                  )
              "
              @typing="getNewListReferenceValuesWhenFiltered"
            >
              <template #selected="props">
                <b-tag
                  v-for="(tag, index) in props.tags"
                  :id="columns.componentKey + '-_-' + tag"
                  :key="index"
                  :tabstop="false"
                  aria-close-label="delete tag in input for update filter"
                  closable
                  close-type="is-danger"
                  ellipsis
                  rounded
                  tabindex="0"
                  type="is-primary"
                  @close="removeTag(tag, columns.componentKey)"
                >
                  {{ getDisplayValueTagForNaturalKey(tag, columns.componentKey) }}
                </b-tag>
              </template>
            </b-taginput>
          </b-field>
          <b-field
            v-else-if="
              columns.id !== '#' &&
              columns.checker !== null &&
              (columns.checker?.type === 'FloatChecker' ||
                columns.checker?.type === 'IntegerChecker' ||
                columns.checker?.type === 'DateChecker')
            "
          >
            <template #label>
              <span class="label inputStyle">{{ columns.getHeader(application, dataId) }}</span>
            </template>
            <b-field>
              <FilterNumberOrDate
                :filters="filters"
                :is-interval="columns.isInterval"
                :model-value="columns"
                @sizeHeight="changeSizeHeight(columns.isInterval, $event)"
                @update:modelValue="updateValue(columns.id, $event, $event.isInterval)"
                @clear:modelValue="clear()"
              ></FilterNumberOrDate>
            </b-field>
          </b-field>
          <b-field v-else-if="columns.id !== '#'">
            <template #label>
              <span class="label titleInput" style="padding: 0 0.625em 0 0.625rem">{{
                columns.getHeader(application, dataId)
              }}</span>
            </template>
            <b-taginput
              :id="columns.componentKey"
              v-model="filters[columns.componentKey]"
              :placeholder="$t('dataTypeAuthorizations.search')"
              :value="filters[columns.componentKey]"
              class="is-primary inputStyle"
              icon="search"
              type="search"
              @blur="
                updateValue(
                  columns.id,
                  {
                    componentKey: columns.id,
                    filters: filters[columns.componentKey],
                    columns: columns,
                  },
                  null
                )
              "
              @keyup.native.enter="
                updateValue(
                  columns.id,
                  {
                    componentKey: columns.id,
                    filters: filters[columns.componentKey],
                    columns: columns,
                  },
                  null
                )
              "
            >
              <template #selected="props">
                <b-tag
                  v-for="(tag, index) in props.tags"
                  :id="columns.componentKey + '-_-' + tag"
                  :key="index"
                  closable
                  close-type="is-danger"
                  rounded
                  type="is-primary"
                  @close="removeTag(tag, columns.componentKey)"
                >
                  {{ tag }}
                </b-tag>
              </template>
            </b-taginput>
          </b-field>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="card-footer-item">
        <b-button
          :disabled="!filters.length"
          expanded
          icon-left="redo"
          outlined
          type="is-danger"
          @click="clear()"
          >{{ $t("dataTypesManagement.réinitialiser") }}
          {{ $t("dataTypesManagement.filtre") }}
        </b-button>
      </div>
      <div class="card-footer-item">
        <b-button
          expanded
          icon-left="download"
          type="is-primary"
          @click="$emit('download-search', { filters })"
        >
          {{
            filters.length
              ? $t("dataTable.donwload-result-filter")
              : $t("dataTable.donwload-result")
          }}
        </b-button>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import useArray from "@/composable/components/array";
import useBoolean from "@/composable/components/boolean";
import services from "@/composable/services";
import { dataLoader } from "@/composable/data/dataLoader";
import { provide, ref } from "vue";
import FilterNumberOrDate from "@/components/common/provider/FilterNumberOrDate.vue";

export default {
  components: { FilterNumberOrDate },
  emits: ["view-search", "clear-search", "download-search"],
  name: "FiltersDataCollapse",
  props: {
    application: {
      type: Object,
    },
    applicationName: {
      type: String,
    },
    dataId: {
      type: String,
    },
    isRefLinkTo: {
      type: Object,
      defaults: [],
    },
    columnsToBeShown: {
      type: Array,
      defaults: [],
    },
    columns: {
      type: Array,
      defaults: [],
    },
  },
  setup(props) {
    const { shallowRefArray: filters, doChangeArray: changeFilters } = useArray();
    const { shallowRefArray: autocompleted, doChangeArray: changeAutocompleted } = useArray();
    const { shallowRefArray: listReferenceValueForAllReferences } = useArray();
    const { refBoolean: open } = useBoolean(false);
    const { shallowRefArray: searchValueReference, doChangeArray: changeSearchValueReference } =
      useArray();
    const {
      shallowRefArray: originalListValuesForReferance,
      doChangeArray: changeOriginalListValuesForReferance,
    } = useArray();
    const loader = dataLoader(services);
    provide("reference:dataLoader", loader);
    const { getValueDisplay } = loader;
    const reference = ref();

    function getNewListReferenceValuesWhenFiltered(text) {
      changeSearchValueReference(
        originalListValuesForReferance.value.filter((option) => {
          return option.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0;
        })
      );
    }

    async function getListeReferenceValues(columnsId, refLinkedTo) {
      let valueReference = [];
      if (columnsId || refLinkedTo) {
        changeSearchValueReference([]);
        if (props.isRefLinkTo[columnsId] !== undefined) {
          reference.value = await services.dataService.getData(
            props.applicationName,
            props.isRefLinkTo[columnsId]
          );
        } else if (refLinkedTo !== undefined) {
          reference.value = await services.dataService.getData(props.applicationName, refLinkedTo);
        }
        if (reference.value && reference.value.rows) {
          for (let row of reference.value.rows) {
            valueReference.push(getValueDisplay(row, props.application));
          }
        }
      }
      changeSearchValueReference(valueReference);
      changeOriginalListValuesForReferance(valueReference);
      listReferenceValueForAllReferences.value[columnsId] = reference.value;
    }

    function updateValue(columnsId, event, option) {
      const localFilter = filters.value.filter(
        (filter) => (event.componentKey || columnsId) !== filter?.componentKey
      );
      event.filters = filters.value[columnsId] || autocompleted.value[columnsId];
      if (event.isInterval) {
        updateIntervalFilter(event, localFilter);
      } else if (event.simpleValue !== undefined) {
        updateSimpleValueFilter(event, localFilter);
      } else if (event.filters) {
        updateComplexFilter(event, option, columnsId, localFilter);
      }

      if (event.filters && event.filters.length !== 0) {
        event.filters = event.filters.map((filter) => getNaturalKey(filter, columnsId) || filter);
        event = { componentKey: event.componentKey, filters: [...new Set(event.filters)] };
      }

      changeFilters(localFilter);
    }

    function updateIntervalFilter(event, localFilter) {
      event = {
        componentKey: event.componentKey,
        intervalsValues: [{ from: event.intervalValues.from, to: event.intervalValues.to }],
      };
      localFilter.push(event);
    }

    function updateSimpleValueFilter(event, localFilter) {
      event.filters = [event.simpleValue];
      localFilter.push(event);
    }

    function updateComplexFilter(event, option, columnsId, localFilter) {
      if (option !== null && option.key !== "Enter") {
        if (typeof option !== "object") {
          event.filters = checkedValueIsInListFilter(option, columnsId, event);
          autocompleted.value = checkedValueIsInListFilter(
            option,
            columnsId,
            null,
            autocompleted.value
          );
          autocompleted.value[columnsId] = [...new Set(autocompleted.value[columnsId])];
        } else {
          event.filters = checkedValueIsInListFilter(option, columnsId, event);
          filters.value = checkedValueIsInListFilter(option, columnsId, null, filters.value);
          filters.value[columnsId] = [...new Set(filters.value[columnsId])];
        }
        localFilter.push(event);
      } else if (option === null) {
        localFilter.push(event);
      }
    }

    function removeTag(tagValue, componentKey) {
      if (!tagValue || !componentKey) return;

      let localFilter = filters.value.filter((filter) => componentKey !== filter?.componentKey);

      updateAutocompleted(tagValue, componentKey, localFilter);
      updateFilters(tagValue, componentKey);

      changeFilters(localFilter);
    }

    function updateAutocompleted(tagValue, componentKey, localFilter) {
      if (!autocompleted.value[componentKey]?.length) return;

      const index = autocompleted.value[componentKey].indexOf(tagValue);
      if (index > -1) {
        autocompleted.value[componentKey].splice(index, 1);
      }

      const listNaturalKeyValue = autocompleted.value[componentKey].map(
        (value) => getNaturalKey(value, componentKey) || value
      );

      autocompleted.value[componentKey] = [...new Set(autocompleted.value[componentKey])];

      localFilter.push({
        componentKey: componentKey,
        filters: listNaturalKeyValue,
      });

      changeAutocompleted(autocompleted.value);
    }

    function updateFilters(tagValue, componentKey) {
      if (!filters.value || Object.keys(filters.value).length === 0) return;

      if (filters.value[componentKey]?.length) {
        const index = filters.value[componentKey].indexOf(tagValue);
        if (index > -1) {
          filters.value[componentKey].splice(index, 1);
        }
      }

      filters.value = filters.value
        .map((filter) => {
          if (filter?.componentKey === componentKey && filter.filters.length !== 0) {
            const index = filter.filters.indexOf(tagValue);
            if (index > -1) {
              filter.filters.splice(index, 1);
              filter.filters = [...new Set(filter.filters)];
            }
            return filter;
          }
          return filter?.filters?.length ? filter : null;
        })
        .filter(Boolean);

      if (filters.value.length === 0) {
        filters.value = [];
      } else {
        filters.value = [...new Set(filters.value)];
      }

      changeFilters(filters.value);
    }

    function checkedValueIsInListFilter(option, columnsId, event, objectFilterOrAutocomplted) {
      if (event !== null && !event.filters.includes(option)) {
        return event.filters.push(option);
      } else if (event !== null && event.filters.includes(option)) {
        return event.filters;
      }
      if (
        objectFilterOrAutocomplted &&
        (Object.keys(objectFilterOrAutocomplted).length === 0 ||
          (Object.keys(objectFilterOrAutocomplted).includes(columnsId) &&
            !objectFilterOrAutocomplted[columnsId].includes(option)))
      ) {
        objectFilterOrAutocomplted[columnsId].push(option);
      }
      return objectFilterOrAutocomplted;
    }

    function changeSizeHeight(collapside, event) {
      let height = document.getElementById("filtreScroll").style.height;
      height = height.substring(0, height.length - 2);
      if (event) {
        document.getElementById("filtreScroll").style.height =
          parseInt(height) + parseInt(event.value) + "rem";
      } else if (collapside) {
        document.getElementById("filtreScroll").style.height = parseInt(height) * 2 + "rem";
      } else {
        document.getElementById("filtreScroll").style.height = 19 + "em";
      }
    }

    function clear() {
      let listInput = document.getElementsByClassName("tag");
      for (let input of listInput) {
        if (input.querySelector(".delete")) {
          input.querySelector(".delete").click();
        }
        input.value = "";
      }
      listInput = document.getElementsByClassName("input");
      for (let input of listInput) {
        input.value = "";
      }
      changeFilters([]);
      changeAutocompleted([]);
      return this.$emit("clear-search", { filters });
    }

    function getDisplayValueTagForNaturalKey(value, columnsId) {
      const referenceValues = listReferenceValueForAllReferences.value[columnsId]?.rows;
      if (!referenceValues) return value;

      const matchingRow = referenceValues.find((row) => row.naturalKey === value);
      if (!matchingRow) return value;

      const locale = services.internationalisationService.getLocale();
      return (
        matchingRow.values[`__display_${locale}`] ||
        matchingRow.values["__display_default"] ||
        matchingRow.naturalKey
      );
    }

    function getNaturalKey(value, columnsId) {
      const references = listReferenceValueForAllReferences.value[columnsId]?.rows;

      if (references) {
        const locale = services.internationalisationService.getLocale();

        const foundRow = references.find(
          (row) =>
            row.values["__display_default"] === value || row.values["__display_" + locale] === value
        );

        return foundRow?.naturalKey;
      }
    }

    return {
      changeSizeHeight,
      clear,
      getListeReferenceValues,
      updateValue,
      filters,
      autocompleted,
      open,
      searchValueReference,
      getNewListReferenceValuesWhenFiltered,
      getValueDisplay,
      removeTag,
      getDisplayValueTagForNaturalKey,
    };
  },
};
</script>

<style lang="scss" scoped>
.inputStyle {
  padding: 0 0.625em 0 0.625rem;
}
</style>
