var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageView',{staticClass:"with-submenu"},[_c('SubMenu',{attrs:{"aria-label":_vm.$t('menu.aria-sub-menu'),"paths":_vm.subMenuPaths,"root":_vm.application.localName || _vm.application.title,"role":"navigation"}}),_c('TitleAndDescription',{attrs:{"application":_vm.application,"localDescription":_vm.currentAuthorization.description,"localName":_vm.application.localName || _vm.application.title,"local-title":_vm.authorizationId === 'new'
        ? _vm.$t('titles.data-type-new-authorization')
        : _vm.currentAuthorization.name}}),_c('ValidationObserver',{ref:"observer"},[_c('div',{staticClass:"columns"},[_c('ValidationProvider',{staticClass:"column is-5",attrs:{"name":"name","rules":"required","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{staticClass:"column mb-4",attrs:{"label":_vm.$t('dataTypeAuthorizations.name'),"message":errors[0],"type":{
            'is-danger': errors && errors.length > 0,
            'is-success': valid,
          }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('dataTypeAuthorizations.name-authorization-placeholder')},model:{value:(_vm.currentAuthorization.name),callback:function ($$v) {_vm.$set(_vm.currentAuthorization, "name", $$v)},expression:"currentAuthorization.name"}})],1)]}}])}),_c('ValidationProvider',{staticClass:"column is-5",attrs:{"name":"name","rules":"required","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-field',{staticClass:"column mb-4",attrs:{"label":_vm.$t('applications.comment'),"message":errors[0],"type":{
            'is-danger': errors && errors.length > 0,
            'is-success': valid,
          }}},[_c('b-input',{attrs:{"placeholder":_vm.$t('dataTypeAuthorizations.comment-authorization-placeholder'),"maxlength":"200","type":"textarea"},model:{value:(_vm.currentAuthorization.description),callback:function ($$v) {_vm.$set(_vm.currentAuthorization, "description", $$v)},expression:"currentAuthorization.description"}})],1)]}}])})],1),(false)?_c('LoadingAnimate',{attrs:{"size":'is-large'}}):_vm._e()],1),_c('AuthorizationTableForDatatype',{attrs:{"application":_vm.application,"current-authorization":_vm.currentAuthorization,"list-column-name":_vm.listColumnName,"authorization-id":_vm.authorizationId,"authorizations":_vm.authorizations,"datatypes":_vm.datatypes,"references":_vm.references,"has-dependencies":_vm.hasDependencies,"initialized":_vm.initialized,"application-name":_vm.applicationName,"references-scopes":_vm.referencesScopes},on:{"update:authorization":_vm.updateAuthorization}}),_c('div',{staticClass:"buttons"},[(_vm.currentAuthorization.name && _vm.currentAuthorization.description)?_c('b-button',{staticStyle:{"margin-bottom":"0.625em","margin-top":"1.25em"},attrs:{"icon-left":"plus","type":"is-dark"},on:{"click":_vm.createOrUpdateAuthorization}},[_vm._v(" "+_vm._s(_vm.authorizations.uuid ? _vm.$t("dataTypeAuthorizations.modify") : _vm.$t("dataTypeAuthorizations.create"))+" ")]):_c('b-notification',{attrs:{"aria-close-label":"Close notification","type":"is-info is-light"}},[_vm._v(" "+_vm._s(_vm.$t("dataTypeAuthorizations.notification-missing-info"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }