export class Application {
  id;
  localName;
  creationDate;
  updateDate;
  name;
  comment;
  version;
  references = [];
  dataTypes = [];
  data = [];
  additionalFile = [];
  configuration = {};
  configFile;
  currentApplicationUserRolesResult = {};
  hasSignedCharte = false;
  hasSignedLastCharte = false;
  dataSyntheses = {};
  canWriteReference = false;
  canReadReference = false;
  canWriteDatatype = false;
  canReadDatatype = false;

  static of(result) {
    const application = new Application(result.application);
    application.currentApplicationUserRolesResult = result.currentApplicationUserRolesResult;
    application.hasSignedCharte = result.hasSignedCharte;
    application.hasSignedLastCharte = result.hasSignedLastCharte;
    application.dataSyntheses = (result.dataSyntheses || []).reduce((acc, synthesis) => {
      acc[synthesis.referenceType] = synthesis.lineCount;
      return acc;
    }, {});

    application.canWriteReference = application.hasReferenceToWrite();
    application.canReadReference = application.hasReferenceToRead();
    application.canWriteDatatype = application.hasDatatypeToWrite();
    application.canReadDatatype = application.hasDatatypeToRead();
    return application;
  }

  hasReferenceToRead() {
    let applicationRoles = this.currentApplicationUserRolesResult.applicationRoles;
    let datasyntheses = this.dataSyntheses;
    return this.references
      .map((reference) => {
        let reader = applicationRoles?.includes("reader") && datasyntheses[reference] > 0;
        let writer = applicationRoles?.includes("writer");
        return reader || writer;
      })
      .reduce((acc, reader) => acc || reader, false);
  }

  hasReferenceToWrite() {
    let applicationRoles = this.currentApplicationUserRolesResult.applicationRoles;
    return applicationRoles?.includes("writer");
  }

  hasDatatypeToRead() {
    let applicationRoles = this.currentApplicationUserRolesResult.applicationRoles;
    let datasyntheses = this.dataSyntheses;
    return this.dataTypes
      .map((reference) => {
        let reader = applicationRoles?.includes("reader") && datasyntheses[reference] > 0;
        let writer = applicationRoles?.includes("writer");
        return reader || writer;
      })
      .reduce((acc, reader) => acc || reader, false);
  }

  hasDatatypeToWrite() {
    let applicationRoles = this.currentApplicationUserRolesResult.applicationRoles;
    return applicationRoles?.includes("writer");
  }

  isApplicationUser() {
    return (
      this.currentApplicationUserRolesResult?.applicationRoles?.includes("applicationManager") ||
      this.currentApplicationUserRolesResult?.applicationRoles?.includes("userManager") ||
      this.hasSignedCharte
    );
  }

  mustSignCharte() {
    return !(
      this.currentApplicationUserRolesResult?.applicationRoles?.includes("applicationManager") ||
      this.currentApplicationUserRolesResult?.applicationRoles?.includes("userManager") ||
      this.hasSignedLastCharte
    );
  }

  constructor(application) {
    if (!application) {
      return;
    }
    this.id = application.id;
    this.creationDate = application.creationDate;
    this.updateDate = application.updateDate;
    this.name = application.name;
    this.comment = application.comment;
    this.version = application.version;
    this.references = [];
    this.dataTypes = [];
    this.data = [];
    this.additionalFile = application.additionalFiles;
    this.configuration = application.configuration;
    this.configFile = application.configFile;
    this.canReadReference = application.canReadReference;
    this.canWriteReference = application.canWriteReference;
    this.canReadDatatype = application.canReadDatatype;
    this.canWriteDatatype = application.canWriteDatatype;
    this.localName = application.localName;
    this.currentApplicationUserRolesResult = application.currentApplicationUserRolesResult || {};
    this.hasSignedCharte = application.hasSignedCharte || false;
    this.hasSignedLastCharte = application.hasSignedLastCharte || false;
    this.dataSyntheses = application.dataSyntheses || {};

    const configuration = application.configuration;
    for (const dataName in configuration?.dataDescription || {}) {
      const data = configuration.dataDescription[dataName];
      if (data?.tags?.find((tag) => tag.tagDefinition === "DATA_TAG")) {
        this.dataTypes.push(dataName);
      } else {
        this.references.push(dataName);
      }
      this.data.push(dataName);
    }
  }
}
