<template>
  <div class="inputStyle">
    <b-tooltip :label="$t('dataTypeAuthorizations.interval')" position="is-right">
      <b-field style="margin-bottom: 1rem">
        <b-radio-button
          v-model="isInterval"
          :native-value="false"
          size="is-small"
          type="is-primary is-outlined"
        >
          <span>{{ $t("rules.for-value") }}</span>
        </b-radio-button>
        <b-radio-button
          v-model="isInterval"
          :native-value="true"
          size="is-small"
          type="is-primary is-outlined"
        >
          <span>{{ $t("rules.for-interval") }}</span>
        </b-radio-button>
      </b-field>
    </b-tooltip>
    <div v-if="isInterval" class="rows">
      <InputDateInterval
        v-if="type === 'DateChecker'"
        :format="format"
        :from="fromDate"
        :input-type="inputType"
        :to="toDate"
        @update:sizeHeight="changeValueSizeHeight(isInterval, $event)"
        @update:date-value-interval="submit($event)"
      >
      </InputDateInterval>
      <InputNumberInterval
        v-if="type === 'FloatChecker' || type === 'IntegerChecker'"
        :format="type === 'FloatChecker' ? 'float' : 'integer'"
        :from="fromNumber"
        :input-type="type === 'FloatChecker' ? 'float' : 'integer'"
        :max="max"
        :min="min"
        :to="toNumber"
        @update:numberValueInterval="submit($event)"
      >
      </InputNumberInterval>
    </div>
    <div v-else>
      <InputNumber
        v-if="type === 'FloatChecker' || type === 'IntegerChecker'"
        :format="type === 'FloatChecker' ? 'float' : 'integer'"
        :from="fromNumber"
        :input-type="type === 'FloatChecker' ? 'float' : 'integer'"
        :is-simple-value="true"
        :max="max"
        :min="min"
        @update:numberValue="submit($event)"
      >
      </InputNumber>
      <InputDate
        v-if="type === 'DateChecker'"
        :format="format"
        :from="fromDate"
        :input-type="inputType"
        :is-simple-value="true"
        @update:sizeHeight="changeValueSizeHeight(isInterval, $event)"
        @update:dateValue="submit($event)"
      >
      </InputDate>
    </div>
  </div>
</template>

<script>
import { onMounted, watch } from "vue";
import useObject from "@/composable/components/object";
import useRegex from "@/composable/components/regex";
import useText from "@/composable/components/text";
import useNumber from "@/composable/components/number";
import useDate from "@/composable/components/date";
import useBoolean from "@/composable/components/boolean";
import InputDate from "@/components/common/provider/InputDate.vue";
import InputDateInterval from "@/components/common/provider/InputDateInterval.vue";
import InputNumberInterval from "@/components/common/provider/InputNumberInterval.vue";
import InputNumber from "@/components/common/provider/InputNumber.vue";

export default {
  name: "FilterNumberOrDate",
  components: { InputNumber, InputNumberInterval, InputDateInterval, InputDate },
  emits: ["update:modelValue", "sizeHeight"],
  props: {
    modelValue: Object,
  },
  setup(props, ctx) {
    watch(
      () => props.modelValue,
      (value) => {
        value.isInterval = true;
        changeIsInterval(value.isInterval);
        if (type.value === "DateChecker") {
          fromDate.value =
            (value.isInterval ? value?.value?.intervalValues?.from : value?.value?.simpleValue) ||
            null;
          toDate.value =
            (value.isInterval ? value?.value?.intervalValues?.to : value?.value?.simpleValue) ||
            null;
        } else {
          fromNumber.value =
            (value.isInterval ? value?.value?.intervalValues?.from : value?.value?.simpleValue) ||
            0;
          toNumber.value =
            (value.isInterval ? value?.value?.intervalValues?.to : value?.value?.simpleValue) || 0;
        }
      }
    );
    const { refText: type, doChangeText: changeType } = useText();
    const { refText: format, doChangeText: changeFormat } = useText();
    const { doChangeText: changeComponent } = useText();
    const { refBoolean: isInterval, doChangeBoolean: changeIsInterval } = useBoolean(true);
    const { doChangeText: changeKey } = useText();
    const { refText: from } = useText();
    const { refText: to } = useText();
    const { refDate: fromDate } = useDate(null);
    const { refDate: toDate } = useDate(null);
    const { refNumber: fromNumber } = useNumber();
    const { refNumber: toNumber } = useNumber();
    const { refNumber: min } = useNumber(
      props.modelValue.checker.min && props.modelValue.checker.min !== "-Infinity"
        ? props.modelValue.checker.min
        : Number(-Infinity)
    );
    const { refNumber: max } = useNumber(
      props.modelValue.checker.max && props.modelValue.checker.max !== "Infinity"
        ? props.modelValue.checker.max
        : Number(Infinity)
    );

    const { reactiveObject: dateTimeFormat } = useObject({
      d: { pattern: "\\d", type: "date" },
      h: { pattern: "\\d", type: "time" },
      m: { pattern: "\\d", type: "time" },
      s: { pattern: "\\d", type: "time" },
      n: { pattern: "\\d", type: "time" },
      a: { pattern: "[AP]M]", type: "time" },
      y: { pattern: "\\d", type: "date" },
      M: { pattern: "\\d", type: "date" },
      Z: { pattern: "[+-]\\d{4}", type: "date" },
      G: { pattern: "[AB]D", type: "date" },
    });
    const { refText: inputType, doChangeText: changeInputType } = useText("text");
    const { refRegex: pattern, doChangeRegex: changePattern } = useRegex();

    onMounted(() => {
      if (props.modelValue) {
        changeType(props.modelValue.checker.type);
        changeFormat(props.modelValue.checker.pattern);
        changeComponent(props.modelValue.component);
        changeIsInterval(props.modelValue.isInterval ? props.modelValue.isInterval : true);
        changeKey(props.modelValue.key);
      }
      let p = format.value;
      let t = { date: false, time: false, isNumeric: false };
      if (type.value === "DateChecker") {
        Object.keys(dateTimeFormat).forEach((search) => {
          if (p.match(search)) {
            t[dateTimeFormat[search].type] = true;
            p = p.replaceAll(search, dateTimeFormat[search].pattern);
          }
        });
        changePattern("^" + p + "$");
      }
      if (type.value === "FloatChecker" || type.value === "IntegerChecker") {
        if (format.value === "integer") {
          t.isNumeric = true;
          changePattern(/(?<=\s|^)[-+]?\d+(?=\s|$)/);
        }
        if (format.value === "float") {
          t.isNumeric = true;
          changePattern(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/);
        }
      }
      if (t.date && t.time) {
        changeInputType("datetime");
      } else if (t.date) {
        changeInputType("date");
      } else if (t.time) {
        changeInputType("time");
      } else if (t.isNumeric) {
        changeInputType("number");
      }
    });

    function submit(event) {
      if (event && event.isSimpleValue) {
        from.value = event.filter;
      } else if (event && event.interval && event.locale) {
        from.value = new Intl.DateTimeFormat(event.locale).format(event.interval.from);
        to.value = new Intl.DateTimeFormat(event.locale).format(event.interval.to);
      } else if (event && event.interval) {
        from.value = event.interval.from;
        to.value = event.interval.to;
      }
      let value = isInterval.value
        ? {
            intervalValues: {
              from: from.value,
              to: to.value,
            },
          }
        : {
            simpleValue: from.value,
          };
      ctx.emit("update:modelValue", {
        ...props.modelValue,
        type: type.value,
        inputType: inputType.value,
        format: format.value,
        intervalValues: value.intervalValues,
        simpleValue: value.simpleValue,
        isInterval: isInterval.value,
      });
    }

    function changeValueSizeHeight(collapside, event) {
      isInterval.value = collapside;
      if (event) {
        ctx.emit("sizeHeight", {
          value: event,
        });
      }
      return isInterval.value;
    }

    return {
      submit,
      changeValueSizeHeight,
      inputType,
      format,
      pattern,
      to,
      from,
      type,
      fromNumber,
      toNumber,
      toDate,
      fromDate,
      min,
      max,
      isInterval,
    };
  },
};
</script>

<style lang="scss" scoped>
.label {
  margin: 3px;
}

.inputStyle {
  padding: 0 0.625em 0 0.625rem;
}

.card {
  margin: 1em;
}

.column {
  padding: 1.5em;
}
</style>
