<script>
import services from "../../../composable/services";
import { computed } from "vue";

export default {
  name: "SummaryTableOfAuthorizationByData",
  computed: {
    services() {
      return services;
    },
  },
  emits: ["show-more"],
  props: {
    authorization: {
      type: Object,
      required: true,
    },
    application: {
      type: Object,
      required: true,
    },
    listColumnName: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    let authorizationName = computed(() => {
      return props.authorization?.name || "";
    });
    let authorizationDescription = computed(() => {
      return props.authorization?.description || "";
    });
    let authorizationListAuthorizations = computed(() => {
      return props.authorization?.authorizations || [];
    });
    let authorizationId = computed(() => {
      return props.authorization?.uuid || [];
    });
    return {
      authorizationName,
      authorizationDescription,
      authorizationListAuthorizations,
      authorizationId,
    };
  },
};
</script>

<template>
  <table class="column is-12" style="display: inline-table; margin-bottom: 0.25em">
    <thead class="rows">
      <tr>
        <th class="row">{{ $t("dataTypeAuthorizations.name-data") }}</th>
        <th class="row">{{ $t("dataTypeAuthorizations.privileges") }}</th>
      </tr>
    </thead>
    <tbody class="rows">
      <tr v-for="(dataName, index) in Object.keys(authorization)" :key="index">
        <td class="row">
          {{ services.internationalisationService.localeReferenceNames(dataName, application) }}
        </td>
        <td
          v-if="application.configuration.dataDescription[dataName].authorization === null"
          class="row"
        >
          <p
            v-for="(configuration, privilegeName) in authorization[dataName].operationTypes"
            :key="privilegeName"
            style="margin-bottom: 0.25em"
          >
            <span>
              {{
                listColumnName[authorization[dataName].operationTypes[privilegeName]]
                  .internationalizationName[lang]
                  ? listColumnName[authorization[dataName].operationTypes[privilegeName]]
                      .internationalizationName[lang]
                  : authorization[dataName].operationTypes[privilegeName]
              }}
            </span>
          </p>
        </td>
        <td v-else class="row">
          <a @click="$emit('show-more')">
            {{ $t("dataTypeAuthorizations.showMore") }}
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>
